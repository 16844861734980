import React, { useState, useEffect, useRef } from 'react';
import { Alert, Button } from 'react-bootstrap';
import './Video360Component.scss';
import HelperMetiers360 from '../../../../services/HelpersMetiers360';

const Kaleidoscope = require('kaleidoscopejs');

/**
 * 
 * @param {Object} video object containing infos for the videos to be played (source url...) 
 * @example
 * <Video360Component video={video}> 
 */

const Video360Component = (props) => {

    const {video} = props;

    const viewer = useRef(null);
    const [play,setPlay] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
	const [isError, setIsError] = useState(false);

    const playerContainerId = 'player-360-container';

	const PLAYER_ROOT = 'player-360';

	const onPlay=() => {
		if (play) {
			viewer.current.pause();
		}
		else {
			viewer.current.play()
		}
		setPlay(!play);
	};

    const handleFullScreen = () => {

    	viewer.current && viewer.current.destroy();
    	setPlay(false);
    	setIsFullScreen(true);

	    const playerContainerDiv = document.getElementById(playerContainerId);

	    if (playerContainerDiv.requestFullscreen) {
		    playerContainerDiv.requestFullscreen().then( () => {
			    init360Player();
		    });
	    }

    }

	const handleStopFullScreen = () => {

		viewer.current && viewer.current.destroy();
		setPlay(false);
		setIsFullScreen(false);

		if (document.fullscreenElement) {
			document.exitFullscreen().then( () => {
				init360Player();
			})
		}
	}

	const init360Player = () => {

		const playerContainerDiv = document.getElementById(playerContainerId);

		// set player size with full parent space
		// with ratio height = width / 2
		const widthVideoPlayer = playerContainerDiv.clientWidth;
		const heightVideoPlayer = widthVideoPlayer / 2;

		const formatVideoSource = video.videoSource.split('.').pop();
		const videoSource = (!HelperMetiers360.checkVideoFormatSupport(formatVideoSource) && video.altVideoSource) ?
		video.altVideoSource
		:video.videoSource;

		const videoOptions = {
			source: videoSource,
			containerId: '#' + PLAYER_ROOT,
			height: heightVideoPlayer,
			width: widthVideoPlayer,
			autoPlay: play,
			onError: (error) => {viewer.current = null; setIsError(true)},
		};

		try {
			viewer.current = new Kaleidoscope.Video(videoOptions);
		} catch(e) {
			setIsError(true);
		}

		if (viewer.current) {
			viewer.current.render();
			viewer.current.pause();
		}
	}

	const clean360Viewer = () => {
		viewer.current.destroy()
		viewer.current = null;
	}

    useEffect(() => {
	    init360Player();
	    return () => {
				(viewer.current && !isError) && clean360Viewer();
	    }

    },[]);

	return(
		<div id="player-360-container" className="player-360-container">

			<div className='player-wrapper'>

				<div id={PLAYER_ROOT}/>
				{
					isError
					? <Alert className="text-center" variant="danger">Impossible de lire cette video</Alert>
					: <div className="actions-button">
						<Button onClick={onPlay}>
							{play ? <i className="fas fa-pause"/>:<i className="fas fa-play"/>}
						</Button>
						<Button onClick={isFullScreen ? handleStopFullScreen : handleFullScreen} className="defr" >
							{isFullScreen ? <i className="fas fa-compress"/> : <i className="fas fa-expand-arrows-alt"/>}
						</Button>
					</div>
				}

			</div>

		</div>
	)

}

export default Video360Component;