import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

import { useParams } from "react-router-dom";

import { Row, Col, Container, Card } from "react-bootstrap";

import Loader from "../../components/widgets/Loader";
import SequenceDetailsComponent from "../../components/sequences/sequenceDetails/SequenceDetailsComponent";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import useBackButton from "../../hooks/useBackButton";

import "./SequenceDetailPage.scss";

const SequenceDetailPage = (props) => {
    const {uniqueId} = useParams();

    // Sequence state variables + actions
    const { sequenceById } = useStoreState(state => state.sequences);
    const {fetchSequenceById} = useStoreActions(actions => actions.sequences);

	const { allStories, isUpdateNecessary } = useCustomGetStoreState("stories");

    const [sequenceStories, setSequenceStories] = useState([]);

    const sequenceSlugs = useStoreState(actions=>actions.actionSlugs.actionSlugsDispatcher('sequences'));

    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    useEffect(() => {
        setIsMounted(true);

        if (!isLoading) {
            setIsLoading(true);
            fetchSequenceById(uniqueId)
                .finally(() => setIsLoading(false));
        }

        return () => setIsMounted(false);
	}, []);

    useEffect(() => {
        if(sequenceById) {
            setSequenceStories(allStories
                .filter((story => sequenceById.stories.includes(story.uniqueId) ))
                .sort((a, b)=> sequenceById.stories.indexOf(a.uniqueId) - sequenceById.stories.indexOf(b.uniqueId)));
        }
    },[allStories, sequenceById])

    const {backButtonComponent} = useBackButton({
        mediaType: "sequences"
    })


    return (
        <Container fluid>
            <Row>
                <Col className="thumbnail_header_item">
                {backButtonComponent}
                    {
                        (sequenceById && sequenceById.links && sequenceById.links.thumbnail) &&
                            <Card.Img src={sequenceById.links.thumbnail[512]} /> 
                    }
                </Col>
            </Row>
            <Row className="d-flex flex-column">
            { 
                (!sequenceById || isLoading || !isMounted) ? 
                    <Loader />
                    :  <>
                        <SequenceDetailsComponent
                            sequence={sequenceById}
                            sequenceStories={sequenceStories}
                            isFetchingStories={isUpdateNecessary}
                        />
                        </>
            }
            </Row>
        </Container>

    )
}

export default SequenceDetailPage;