import React, { useState } from 'react';
import './SelectMultipleItemsComponent.scss'
import PropTypes from 'prop-types';

const SelectMultipleItemsComponent = (props) => {
    const { itemsSelected, setItemsSelected, items, name, itemKey, itemValue, hasError = false, labelNone = null } = props;
    const [isOpenSelect, setIsOpenSelect] = useState(false);

    const checkboxChange = (event, item) => {
        const newItemsSelected = event.target.checked
            ? item[itemKey] == 0 ? [item, ...itemsSelected] : [...itemsSelected, item]
            : itemsSelected.filter(itemSelect => itemSelect[itemKey] !== item[itemKey]);
        setItemsSelected(newItemsSelected);
    }

    const displaySelectedItems = () => {
        const listItemsName = itemsSelected.map(item => item[itemValue]).join(', ');
        return (listItemsName.length > 40) ? listItemsName.slice(0, 40) + '...' : listItemsName;
    }

    const resetSelection = () => {
        setItemsSelected([]);
        document.getElementsByName(name)[0].querySelectorAll('input[type=checkbox]')
            .forEach(el => el.checked = false);
    }

    const selectAll = () => {
        setItemsSelected(items);
        document.getElementsByName(name)[0].querySelectorAll('input[type=checkbox]')
            .forEach(el => el.checked = true);
    }

    const isDefaultChecked = (currentItemKey) => {
        return itemsSelected?.findIndex(itemSelected => itemSelected[itemKey] === currentItemKey) !== -1;
    }

    const itemsOptions = items?.length > 0 && 
        items.sort((a, b) => a[itemValue]?.localeCompare(b[itemValue]))
            .map(item => {
                return <div key={'select-'+name+'-'+item[itemKey]} className='select-option'>
                    <input type="checkbox" id={name+'-'+item[itemKey]} onChange={(e) => checkboxChange(e, item)}
                        checked={isDefaultChecked(item[itemKey])}/>
                    <label className='label-option' htmlFor={name+'-'+item[itemKey]}>{item[itemValue]}</label>
                </div>
            });

    return items?.length > 0 && 
        <div className='w-100'>
            <div className={isOpenSelect ? 'close-select-outside' : 'visually-hidden'}
                onClick={() => setIsOpenSelect(false)}></div>
            <div className={'select-multiple ' + (hasError && 'error-input')}
                 onClick={() => setIsOpenSelect(!isOpenSelect)}>
                <span>{ itemsSelected.length > 0
                    ? displaySelectedItems()
                    : "--"}</span>
                <i className="fas fa-chevron-down"></i>
            </div>
            <div className={isOpenSelect ? 'container-options' : 'visually-hidden'}>
                <div className='select-options-multiple' name={name}>
                    { itemsSelected.length > 0
                        ? <div className='select-option d-flex align-items-center' onClick={() => resetSelection()}>
                            <i className="fas fa-minus-square m-0 clickable" ></i>
                            <label className='label-option uncheck-all'>-- Tout décocher --</label>
                        </div>
                        : <div className='select-option d-flex align-items-center' onClick={() => selectAll()}>
                            <i className="fas fa-check-square m-0 clickable" ></i>
                            <label className='label-option uncheck-all'>-- Tout cocher --</label>
                        </div>}
                    {labelNone && <div key='select-none' className='select-option'>
                        <input type="checkbox" id={name+'-none'} onChange={(e) => checkboxChange(e, {[itemKey]: 0, [itemValue]: "-- Sans "+labelNone+" --"})}
                            checked={isDefaultChecked(0)}/>
                        <label className='label-option' htmlFor={name+'-none'}>-- Sans {labelNone} --</label>
                    </div>}
                    { itemsOptions }
                </div>
            </div>
        </div>;
}

SelectMultipleItemsComponent.propTypes = {
    itemsSelected: PropTypes.array.isRequired,
    setItemsSelected: PropTypes.any.isRequired,
    items: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    itemKey: PropTypes.string.isRequired,
    itemValue: PropTypes.string.isRequired,
    hasError: PropTypes.bool,
    labelNone: PropTypes.string
};

export default SelectMultipleItemsComponent;