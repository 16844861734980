import React from "react";
import DynamicTable from "../widgets/dynamicTable/DynamicTable";
import { useNavigate } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import AuthorizationChecker from "../../services/AuthorizationChecker";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import PropTypes from 'prop-types';


const WelcomeMessageTableComponent = (props) => {
    const { messages, enableToControlVisibleColumns = false, withParams } = props;
    const navigate = useNavigate();
    const messagesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('welcomeMessage'));

    const handleClick = (uniqueId) => {
        navigate(messagesSlugs.readOne.replace(':uniqueId', uniqueId));
    }

    const statusColors = { draft: 'danger', validated: 'success', expired: 'secondary', active: 'info' };
    const statusLabels = { draft: 'Brouillon', validated: 'Validé', expired: 'Expiré', active: 'Actif' }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const getStatus = (message) => {

        const startDateString = message?.startDate?.date;
        const startDate = startDateString ? new Date(startDateString) : null;

        const endDateString = message?.expirationDate?.date;
        const endDate = endDateString ? new Date(endDateString) : null;

        if (!message.isValidated) {
            return 'draft';
        } else if (!message.hasBeenDeactivated && (startDate && startDate.toISOString() <= today.toISOString()) && (endDate && endDate.toISOString() >= today.toISOString())) {
            return 'active';
        } else if (message.hasBeenDeactivated || (endDate && endDate.toISOString() < today.toISOString())) {
            return 'expired';
        } else {
            return 'validated';
        }
    }

    const iconifyStatus = (status) => <div className="statusdiv"> <i className={`fas fa-solid fa-circle fa-xs text-${statusColors[status]}`} />{statusLabels[status]}
    </div>;

    const sortType = messages?.length > 0
        && [
            {
                value: 'title', label: 'Titre',
                test: messages[0]?.title !== undefined,
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.title, b.title),
                display: (message) => message.title ?? '-'
            },
            {
                value: 'createdAt', label: 'Créé le',
                test: messages[0]?.createdAt?.date !== undefined,
                method: (a, b) => new Date(b.createdAt?.date) - new Date(a.createdAt?.date),
                display: (message) => message?.createdAt?.date ? HelperMetiers360.getdisplayDateType(message.createdAt?.date, 'day') : '-'
            },
            {
                value: 'updatedAt', label: 'Modifié le',
                test: messages[0]?.updatedAt?.date !== undefined,
                method: (a, b) => new Date(b.updatedAt?.date) - new Date(a.updatedAt?.date),
                display: (message) => message?.updatedAt?.date ? HelperMetiers360.getdisplayDateType(message.updatedAt?.date, 'day') : '-'
            },
            {
                value: 'startDate', label: 'Début',
                test: messages[0]?.startDate?.date !== undefined,
                method: (a, b) => new Date(b.startDate?.date) - new Date(a.startDate?.date),
                display: (message) => message?.startDate?.date ? HelperMetiers360.getdisplayDateType(message.startDate?.date, 'day') : '-'
            },
            {
                value: 'expirationDate', label: 'Fin',
                test: messages[0]?.expirationDate?.date !== undefined,
                method: (a, b) => new Date(b.expirationDate?.date) - new Date(a.expirationDate?.date),
                display: (message) => message?.expirationDate?.date ? HelperMetiers360.getdisplayDateType(message.expirationDate?.date, 'day') : '-'
            },
            {
                value: 'viewcount', label: 'Vues',
                test: messages?.some(m => m.viewcount),
                method: (a, b) => b.viewcount - a.viewcount,
                display: (message) => message.viewcount
            },
            {
                value: 'status', label: 'Statut',
                test: !!messages?.length,
                method: (a, b) => HelperMetiers360.localeCompareWithNullable(getStatus(a), getStatus(b)),
                display: (message) => iconifyStatus(getStatus(message)),
                flatDisplay: (message) => statusLabels[getStatus(message)]
            }
        ];

    return sortType
        &&
        <DynamicTable
            contentTable={messages}
            contentSort={sortType}
            valueInitSort="expirationDate"
            index='uniqueId'
            handleClick={handleClick}
            withParams={withParams}
            enableToControlVisibleColumns={enableToControlVisibleColumns && AuthorizationChecker.isAdmin()}
            tableName={"welcomemessagetable"} />;
};

WelcomeMessageTableComponent.propTypes = {
    messages: PropTypes.array.isRequired,
    enableToControlVisibleColumns: PropTypes.bool,
    withParams: PropTypes.bool,
};

export default WelcomeMessageTableComponent;