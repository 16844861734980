import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

import { useParams, useNavigate } from "react-router-dom";

import { Row, Col, Button, Container } from "react-bootstrap";

import Loader from "../../components/widgets/Loader";
import { useModal } from "../../hooks/useModal";

import PlaylistDetailsComponent from "../../components/playlists/PlaylistDetailsComponent";
import useBackButton from "../../hooks/useBackButton";

import "./PlaylistDetailPage.scss";
import DownloadPlaylistContentListPDF from "../../components/widgets/generatePDFs/playlistContentList";

const PlaylistDetailPage = () => {
    const {uniqueId} = useParams();

    const navigate = useNavigate();

    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    const { playlistById } = useStoreState(state => state.playlists);
    const {fetchPlaylistById, deletePlaylist} = useStoreActions(actions => actions.playlists);
    const {ACTIONS} = useStoreState(state => state.actionSlugs.apiData);

    const playlistSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('playlists'));

    const [isLoading, setIsLoading] = useState(false);
    const [generatePlaylistPDF, setGeneratePlaylistPDF] = useState(false);

    useEffect(() => {
        if (!playlistById || playlistById.uniqueId !== uniqueId) {
            setIsLoading(true);
            fetchPlaylistById(uniqueId)
                .finally(() => setIsLoading(false));
        }
    }, [uniqueId]);

    const onDelete = () => {
        setModalData({
            ...modalData,
            header:"Suppression de la playlist",
            content:<h5>Êtes-vous sûr⋅e de vouloir supprimer cette playlist ?</h5>,
            resetButton: 'Supprimer',
            cancelButton: 'Annuler',
            onReset: () => {
                deletePlaylist({uniqueId})
                    .then(() => {
                        navigate(playlistSlugs.readAll);
                    })
                }
        });
        setModalShow(true);
    }

    const {backButtonComponent} = useBackButton({className:"ml-auto", mediaType:"playlists"})

    return (
        <Container fluid>
            <Row className="playlist_header">
                <Col className="playlist_header_details">
                    <h2>
                        Détails d'une playlist
                    </h2>
                    <div>
                        {backButtonComponent}
                    </div>

                </Col>
                <Col className="d-flex justify-content-between mb-4">
                    {generatePlaylistPDF ?
                        <DownloadPlaylistContentListPDF playlist={playlistById} />
                        : <Button variant="primary" onClick={() => {setGeneratePlaylistPDF(true)}}
                        disabled={!playlistById}>
                        <i className="fas fa-download mr-1" />Contenu de la playlist
                    </Button>}
                   
                    {playlistById?.isEditable && ACTIONS.playlists.delete
                        && <Button variant="danger" onClick={onDelete}>
                            <i className="fas fa-trash-alt"></i> &nbsp;Supprimer</Button>}
                </Col>
            </Row>
            <Row className="d-flex flex-column">
                {
                    (isLoading || !playlistById) 
                        ? <Loader />
                        : <PlaylistDetailsComponent { ...playlistSlugs } playlist={playlistById} />
                }
            </Row>
            {modalComponent}
        </Container>
    );
}


export default PlaylistDetailPage ;