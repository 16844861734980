import React, {useState, useEffect} from "react";
import { Container, Row, Col, ToggleButton, ToggleButtonGroup, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useStoreState, useStoreActions } from 'easy-peasy';


import FormationTableComponent from "../../components/formations/formationList/formationTable/FormationTableComponent.jsx";
import FormationsBoardComponent from "../../components/formations/formationList/formationBoard/FormationBoardComponent.jsx";


import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import Loader from "../../components/widgets/Loader.jsx";
import SelectTypeWidget from "../../components/widgets/SelectType/SelectTypeWidget.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";



import "./FormationsPage.scss";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";

const FormationTableWithParams = withURLParamsTableComponent(FormationTableComponent);

const FormationsPage = () => {

    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;

    const { allFormations, isFetchingAllFormations} = useCustomGetStoreState("formations")

    const formationSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("formations"));

    const viewStylePreference = useStoreState(state => state.preferences.formationListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setFormationListStyle);

    const formationBoardSortType = useStoreState(state => state.preferences.formationBoardSortType);
    const setFormationBoardSortType = useStoreActions(actions => actions.preferences.setFormationBoardSortType);

    const [filteredFormations, setFilteredFormations] = useState(allFormations);
    const [presentCategories, setPresentCategories] = useState([]);
    const [presentTools, setPresentTools] = useState([]);
    const [presentLevels, setPresentLevels] = useState([]);
    const [init, setInit] = useState(false);
    
    const viewStyleType=[
        {value : 'table', label:<><i className="fas fa-table"></i></>, view : <FormationTableWithParams formations = {filteredFormations} {...formationSlugs} />},
        {value : 'cards', label:<><i className="fas fa-address-card"></i></>, view : <FormationsBoardComponent formations = {filteredFormations}  sortTypePreferenceTuple={[formationBoardSortType, setFormationBoardSortType]} {...formationSlugs}/>},
    ];

    const [viewStyle, setViewStyle] = useState(viewStylePreference);


    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

   

    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés (Nom, Description ...)', delay: 400, width: "30em"})

    const {typeSelector:levelSelector, selectedValue:selectedLevel } = SelectTypeWidget({
            allTypes:presentLevels, 
            label:'Tout contexte'
        });
    const {typeSelector:toolSelector, selectedValue:selectedTool } = SelectTypeWidget({
            allTypes:presentTools, 
            label:'Tout outil'
        });
    const {typeSelector:categorySelector, selectedValue:selectedCategory } = SelectTypeWidget({
            allTypes:presentCategories, 
            label:'Toutes categories'
        });

    useEffect(() => {
        setFilteredFormations(allFormations);

        if(allFormations.length>1 && init == false)
        {
        
            for (let i = 0; i < allCategories.length; i++) {

                let findCategory = allFormations.find((formation)=>{
                    return formation.categories.includes(allCategories[i].value)
                });

                if(findCategory){
                    presentCategories.push(allCategories[i]);
                }
                        
            }

            for (let i = 0; i < allLevels.length; i++) {

                let findLevels = allFormations.find((formation)=>{
                    return formation.levels.includes(allLevels[i].value)
                });

                if(findLevels){
                    presentLevels.push(allLevels[i]);
                }
                        
            }

            for (let i = 0; i < allTools.length; i++) {

                let findTools = allFormations.find((formation)=>{
                    return formation.tools.includes(allTools[i].value)
                });

                if(findTools){
                    presentTools.push(allTools[i]);
                }
                        
            }

            setPresentCategories([...presentCategories]);
            setPresentTools([...presentTools]);
            setPresentLevels([...presentLevels]);

            setInit(true);
        }

    }, [allFormations]);

    useEffect(() => {
        setFilteredFormations(
            [...allFormations.filter(
                formation => {
                   return (
                        HelperMetiers360.isSearchInText(search, formation.name)
                        || HelperMetiers360.isSearchInText(search, formation.description)
                        || HelperMetiers360.isSearchInText(search, 
                            allCategories.find(category => formation.categories?.join().includes(category.value))?.label)
                        || HelperMetiers360.isSearchInText(search,
                            allTools.find(tool => formation.tools?.join().includes(tool.value))?.label)
                        || HelperMetiers360.isSearchInText(search,
                            allLevels.find(level => formation.levels?.join().includes(level.value))?.label)
                    )
                    && (!selectedLevel || selectedLevel=='all' || formation?.levels?.includes(selectedLevel))
                    && (!selectedTool || selectedTool=='all' || formation?.tools?.includes(selectedTool))
                    && (!selectedCategory || selectedCategory=='all' || formation?.categories?.includes(selectedCategory))
                })]
        );
	}, [search, selectedLevel,selectedTool,selectedCategory]);

	

    const createFormationButton = formationSlugs.create
    ? <Button variant="success" as={Link} to={formationSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
    : null;

    const isNoFormations = !isFetchingAllFormations && !allFormations.length
    const isNoResult = !isFetchingAllFormations && allFormations.length && !filteredFormations.length;

    const levelDescription = selectedLevel && allLevels.find(level => level.value === selectedLevel)?.description ?
        <Alert variant="info">{allLevels.find(level => level.value === selectedLevel).description}</Alert>
        : null;

      return <>
        <Container fluid>
            <Row>
                <Col>
                    <h2>Nos webinaires</h2>
                    <Alert variant="info">Découvrez nos webinaires, des formations en visio adaptées à vos objectifs (prise en main technique, pédagogique…) et à votre contexte d’exploitation (orientation, insertion…). <br/>Des moments privilégiés pour se familiariser avec Métiers360, échanger autour de ses différentes utilisations et trouver l’inspiration !</Alert>
                </Col>
            </Row>
            <Row className="item-page">
                {(allFormations.length > 1 || AuthorizationChecker.isAdmin())  &&
                <Col className="m360-col3-start">
                   <div> {searchInput}</div>
                </Col>
                }
                <Col className="m360-col3-center">
                {AuthorizationChecker.isAdmin() &&
                   <ToggleButtonGroup type="radio" name="options" value={viewStyle} onChange={handleChange}>
                        {checkViewStyle}
                    </ToggleButtonGroup>
                }
                </Col>
                <Col className="m360-col3-end">
                    {createFormationButton}
                </Col>
            </Row>
            <Row className="module-page-selector align-items-start justify-content-center mt-3">
               {(allFormations.length > 1 || AuthorizationChecker.isAdmin()) &&
               <>
                { presentCategories.length > 1 &&
                <Col>
                    <Form.Group>
                        <Form.Label>Choisissez une categorie</Form.Label>
                        {categorySelector}
                    </Form.Group>
                </Col>
                }
                { presentLevels.length > 1 &&
                <Col>
                 <>
                    <Form.Group>
                        <Form.Label>Choisissez un contexte d'exploitation</Form.Label>
                        {levelSelector}
                    </Form.Group>
                    {levelDescription}
                    </>
                </Col>
                }
                { presentTools.length > 1 &&
                <Col>
                    <Form.Group>
                        <Form.Label>Choisissez un outil</Form.Label>
                        {toolSelector}
                    </Form.Group>
                </Col>
                }
                </>
                }
            </Row>

            {(isFetchingAllFormations) && <Loader />}
            {(filteredFormations.length > 0 )? viewStyleType.filter(type => type.value===viewStyle)[0].view : null}
            {isNoResult ? <Alert className="mt-3" variant="warning">Aucun webinaire ne correspond à votre recherche</Alert> : null}
            {isNoFormations && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun webinaire</Alert>} 
        </Container>
    </>;

}

    export default FormationsPage;
    