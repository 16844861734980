import React from 'react';
import { Link } from 'react-router-dom';
import {Col, Card, ListGroupItem} from 'react-bootstrap';
import { useStoreState } from 'easy-peasy';
import "./MiniCardMediaComponent.scss";
import HelperMetiers360 from '../../../services/HelpersMetiers360';

const CardWrapperLink = ({isLink, readOneSlug, children, ...rest}) => {
    return (
        <>
            {
                isLink && readOneSlug
                    ?
                    <Card as={Link} to={readOneSlug} {...rest}>
                        {children}
                    </Card>
                    : <Card {...rest}>
                        {children}
                    </Card>
            }
        </>
    )
}

/**
 * 
 * @param {Object} media object with information from a single media [story | video]
 * @param {Boolean} isAdmin optionnal boolean specifying if user is admin
 * @param {String} readOneSlug slug to the details page pre filled with the story uniqueId
 * @param {Boolean} isLink determine if the card is to be treated as a react router link to perform a redirect on click
 * @param {Boolean} border
 * @param {function} handleClickDnd In case the Card is used in drag and drop select item, this callback is called on click to handle selection
 *
  
 * @example
 * 
 * <MiniMediaCardComponent story={story} isAdmin={isAdmin} readOneSlug={readOneSlug}>
 */

const MiniCardMediaComponent = (props) => {
    const {media, mediaType, isAdmin, readOneSlug, isLink = true, border = false, onClickItem = false } = props;

    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;


    // const truncatedTextLength = 50;

    const viewCount = isAdmin && media.viewCount ? 
        <><i className="far fa-eye"></i> : {media.viewCount}</>
        : null;

    const getMediaReleaseDate = (media) => {
        return media.nextSession ? 
                 HelperMetiers360.getdisplayDateType(media.nextSession, 'day') 
                    : media.releaseDate ? 
                         HelperMetiers360.getdisplayDateType(media.releaseDate, 'day') 
                           : media.createdAt ? 
                                HelperMetiers360.getdisplayDateType(media.createdAt, 'day') 
                                  : "";
    }
    const releaseDate = getMediaReleaseDate(media) ? 
        <><i className="far fa-calendar-alt"></i> : {getMediaReleaseDate(media)}</>
        :  null;

    const duration = media.duration ? 
    <><i className="far fa-clock"></i> : {HelperMetiers360.formatDuration(media.duration)}</>
    : null;

    const registered = media.isRegisteredToSession && mediaType === "formation" ? 
    <>Inscrit</>
    : null;

    const getMediaThumbnail = (media) => {
        if (!media)
            return
        if ('thumbnail' in media && typeof media.thumbnail === 'string') {
            return media.thumbnail;
        }
        if ('links' in media) {
            return  (media.links.thumbnail && (media.links.thumbnail['512'] || media.links.thumbnail['128'])) || media.links.thumbnail || media.links.image
        }
        if(!media.thumbnail && !media.links) {
            return
        }
    }


    const infoNextReleaseDate = (media) => {
        if (mediaType === 'pedagogicModule'){
            if(media?.categories?.includes(allCategories[2].value)){
                return <><i className="fas fa-cube category-logo" ></i></>
            }
            if(media?.categories?.includes(allCategories[3].value)){
                return <><i className="fas fa-cubes category-logo" ></i></>
            }
        }
        return duration ? duration : viewCount
    }
    
    const producersName = (producers) => producers?.length ? producers.map((producer) => producer.clientName ).map(String)?.join(', '):null;

    return (
        <Col>
        {mediaType !== 'headset' &&
            <CardWrapperLink 
                className="media-card"
                border={border}
                isLink={isLink}
                readOneSlug={readOneSlug}
                onClick={() => { 
                if (onClickItem)
                onClickItem(media.uniqueId)
            }} 

            >
                <Card.Body className="card_body">
                    <Card.Img src={getMediaThumbnail(media) } />
                    
                    <Card.Text className="card_date">
                        {releaseDate}<span className='align-right'>{infoNextReleaseDate(media)}</span>  
                        {mediaType === 'formation' &&
                        <span className='align-right  text-danger'>{registered}</span> 
                        }
                    </Card.Text>
                    
                    <Card.Title className="card_title">{media.name || media.videoName}</Card.Title>
                    <Card.Text className="card_description">
                    {/* <span dangerouslySetInnerHTML={{ __html: HelperMetiers360.textTruncate(video.description,truncatedTextLength) }}/> */}
                        { media.subHeading ? <>{media.subHeading} <br/></> : null }
                        {(media.producers?.length > 0 && isAdmin) && producersName(media.producers)}
                    </Card.Text>
                </Card.Body>
            </CardWrapperLink>
        }
         {mediaType === 'headset' &&
            <Card 
            className="media-list"
            border={border}
            onClick={() => { 
            if (onClickItem)
            onClickItem(media.uniqueId)
        }} 

            >
                <ListGroupItem className='media-list-item'>
                    {media.name}
                </ListGroupItem>
            </Card>
        }
        </Col>
    );
}

export default MiniCardMediaComponent;