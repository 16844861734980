import { action, actionOn, thunk } from "easy-peasy";
import axios from '../services/axios';

const romesInitialState = {
    allRomes: [],
    isFetchingAllRomes: false,
    isUpdatingTransversalPropThumbnail: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    romeById: null,
    isFecthingOneRome: false,
    isFetchingOneTransversalProp:false,
    oneTransversalProp: null,
    allAreasOfInterest: [],
    allWorkingConditions: [],
    allSkills: [],
    allJobAppellations: [],
    isFetchingAllAreasOfInterest: false,
    isFetchingAllWorkingConditions: false,
    isFetchingAllSkills: false,
    isFetchingAllJobAppellations: false,
}

const romes = {
    ...romesInitialState,

    onGlobalResetCalled: actionOn(
        // target resolver: we store in an array a list of action we will be listening for
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        // handler: if we are listening for multiple type of actions, we can execute logic depending on the type
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allRomes: state.allRomes,
                    isFetchingAllRomes: state.isFetchingAllRomes,
                    isUpdatingTransversalPropThumbnail: state.isUpdatingTransversalPropThumbnail,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    romeById: state.romeById,
                    isFecthingOneRome: state.isFecthingOneRome,
                    isFetchingOneTransversalProp: state.isFetchingOneTransversalProp,
                    oneTransversalProp: state.oneTransversalProp,
                    allAreasOfInterest: state.allAreasOfInterest,
                    allWorkingConditions: state.allWorkingConditions,
                    allSkills: state.allSkills,
                    allJobAppellations: state.allJobAppellations,
                    isFetchingAllAreaOfInterest: state.isFetchingAllAreaOfInterest,
                    isFetchingAllWorkingCondition: state.isFetchingAllWorkingCondition,
                    isFetchingAllSkill: state.isFetchingAllSkill,
                    isFetchingAllJobAppellations: state.isFetchingAllJobAppellations,
                } = romesInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setAllRomes: action((state, payload) => {
        state.allRomes = payload;
    }),

    setIsFetchingAllRomes: action((state, payload) => {
        state.isFetchingAllRomes = payload;
    }),
    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),


    fetchAllRomes: thunk((actions, payload, helpers) => {
        const { isFetchingAllRomes, isUpdateNecessary } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.romes?.readAll?.url;

        if (!isFetchingAllRomes && isUpdateNecessary) {

            actions.setIsFetchingAllRomes(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllRomes(data);
                    actions.setIsFetchingAllRomes(false);
                    actions.setIsUpdateNecessary(romes.isUpdateNecessary);
                    return data
                }))
                .catch((error) => Promise.reject(error))
        }
        else {
            Promise.resolve();
        }
    }),

    updateAllRomes: action((state, payload) => {
        const index = state.allRomes.findIndex((rome) => rome.codeRome === payload.code);

        state.allRomes[index] = {
            ...state.allRomes[index],
            riasecMajeur: payload.riasecMajeur,
            riasecMineur: payload.riasecMineur,
        };
    }),

    setRomeById: action((state, payload) => {
        state.romeById = payload;
    }),

    setIsFetchingOneRome: action((state, payload) => {
        state.isFecthingOneRome = payload;
    }),

    fetchRomeById: thunk((actions, payload, helpers) => {
        const { isFecthingOneRome } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getOneUrl = ACTIONS?.romes?.readOne?.url?.replace('romeCode', payload);
        if (!isFecthingOneRome) {
            actions.setIsFetchingOneRome(true);
            actions.setRomeById(romesInitialState.romeById);
            return axios.get(getOneUrl)
                .then((data => {
                    actions.setRomeById(data);
                    actions.setIsFetchingOneRome(romesInitialState.isFecthingOneRome);
                    actions.updateAllRomes(data);
                }))
                .catch((error) =>  Promise.reject(error))
        }
        else {
            return new Promise.resolve();
        }
    }),

    fetchOneRomeById: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getOneUrl = ACTIONS?.romes?.readOne?.url?.replace('romeCode', payload);
        return axios.get(getOneUrl)
            .then((data => {
                return data;
            }))
            .catch((error) => Promise.reject(error))
    }),

    setIsFetchingOneTransversalProp: action((state, payload) => {
        state.isFetchingOneTransversalProp = payload;
    }),

    setOneTransversalProp: action((state, payload) => {
        state.oneTransversalProp = payload;
    }),

    fetchOneTransversalProp: thunk((actions, payload, helpers) => {
        const { isFetchingOneTransversalProp } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        if (payload?.transversalProp && ACTIONS?.hasOwnProperty(payload.transversalProp) && !isFetchingOneTransversalProp) {
            actions.setOneTransversalProp(null);
            const getOneUrl = ACTIONS[payload.transversalProp].readOne?.url?.replace('code', payload.code);
            actions.setIsFetchingOneTransversalProp(true);
            return axios.get(getOneUrl)
                .then((data => {
                    actions.setOneTransversalProp(data);
                    actions.setIsFetchingOneTransversalProp(false);
                    return data;
                }))
                .catch((error) => Promise.reject(error))
                .finally(() => actions.setIsFetchingOneTransversalProp(false));
        }
        else {
            return new Promise.resolve();
        }

    }),

    setIsFetchingAllAreasOfInterest: action((state, payload) => {
        state.isFetchingAllAreasOfInterest = payload;
    }),
    setIsFetchingAllWorkingConditions: action((state, payload) => {
        state.isFetchingAllWorkingConditions = payload;
    }),
    setIsFetchingAllSkills: action((state, payload) => {
        state.isFetchingAllSkills = payload;
    }),
    setIsFetchingAllJobAppellations: action((state, payload) => {
        state.isFetchingAllJobAppellations = payload;
    }),

    setAllAreasOfInterest: action((state, payload) => {
        state.allAreasOfInterest = payload;
    }),
    setAllWorkingConditions: action((state, payload) => {
        state.allWorkingConditions = payload;
    }),
    setAllSkills: action((state, payload) => {
        state.allSkills = payload;
    }),
    setAllJobAppellations: action((state, payload) => {
        state.allJobAppellations = payload;
    }),

    updateAllAreasOfInterest: action((state, payload) => {
        const index = state.allAreasOfInterest.findIndex((areaOfInterest) => areaOfInterest.code === payload.code);
        const oldAreaOfInterest = state.allAreasOfInterest[index];
        state.allAreasOfInterest[index] = { ...oldAreaOfInterest, ...payload };
    }),

    fetchAllAreasOfInterest: thunk((actions, payload, helpers) => {
        const { isFetchingAllAreasOfInterest } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.areasOfInterest?.readAll?.url;

        if (!isFetchingAllAreasOfInterest) {
            actions.setIsFetchingAllAreasOfInterest(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllAreasOfInterest(data);
                    actions.setIsFetchingAllAreasOfInterest(false);
                    return data
                }))
                .catch((error) => Promise.reject(error))
        }
        else {
            return Promise.resolve();
        }
    }),
   
    fetchAllWorkingConditions: thunk((actions, payload, helpers) => {
        const { isFetchingAllWorkingConditions } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.workingConditions?.readAll?.url;

        if (!isFetchingAllWorkingConditions) {
            actions.setIsFetchingAllWorkingConditions(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllWorkingConditions(data);
                    actions.setIsFetchingAllWorkingConditions(false);
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),

    fetchAllSkills: thunk((actions, payload, helpers) => {
        const { isFetchingAllSkills } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.skills?.readAll?.url;

        if (!isFetchingAllSkills) {
            actions.setIsFetchingAllSkills(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllSkills(data);
                    actions.setIsFetchingAllSkills(false);
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),

    fetchAllJobAppellations: thunk((actions, payload, helpers) => {
        const { isFetchingAllJobAppellations } = helpers.getState();
        const { apiData } = helpers.getStoreState().actionSlugs;

        const getAllUrl = apiData.ACTIONS?.jobAppellations?.readAll?.url;

        if (!isFetchingAllJobAppellations) {
            actions.setIsFetchingAllJobAppellations(true);
            return axios.get(getAllUrl)
                .then((data => {
                    actions.setAllJobAppellations(data);
                    actions.setIsFetchingAllJobAppellations(false);
                    return data
                }))
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
        else {
            return Promise.resolve();
        }
    }),



    setIsUpdatingTransversalPropThumbnail: action((state, payload) => {
        state.isUpdatingAreaOfInterestThumbnail = payload;
    }),

    updateOneTransversalPropThumbnail: thunk((actions, payload, helpers) => {
        const { isUpdatingTransversalPropThumbnail } = helpers.getState();
        const { uniqueId, formData, entityName } = payload;
        const { apiData } = helpers.getStoreState().actionSlugs;
        const updateUrl = apiData.ACTIONS[entityName]?.updateThumbnail?.url.replace('code', uniqueId);

        if (!isUpdatingTransversalPropThumbnail) {
            actions.setIsUpdatingTransversalPropThumbnail(true);
            return axios.post(updateUrl, formData)
                .then((data => {
                    actions.setOneTransversalProp(data.oneTransversalPropFull);
                    switch(entityName) {
                        case 'areasOfInterest':
                            actions.updateAllAreasOfInterest(data.oneTransversalPropSimple)
                            break;
                        case 'workingConditions':
                           
                            break;
                        case 'skills':

                            break;
                        default :   

                        break;
                    }
                    actions.setIsUpdatingTransversalPropThumbnail(false);
                    return data
                }))
                .catch((error) => Promise.reject(error))
        }
        else {
            return Promise.resolve();
        }
    }),
}

export default romes;