import React, { useState, useEffect } from "react";
import { Form, Container,  Row } from 'react-bootstrap';
import CardGroupMediaComponent from "../../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx";

const QuizBoardComponent = ({quizzes, readOne: readOneSlug, sortTypePreferenceTuple }) => {
    const [ quizBoardSortType, setQuizBoardSortType ] = sortTypePreferenceTuple ? sortTypePreferenceTuple : [null, null];

    const sortType = [
        { value: 'date', label: "Publication (plus récente d'abord)", method:  (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate)},
        { value: 'name', label: 'Nom', method: (a, b) => a.name.localeCompare(b.name) },
    ];

    const [sort, setSort] = useState(quizBoardSortType ? sortType.find(type => type.value === quizBoardSortType) : sortType[0]);

    const [sortedQuizzes, setSortedQuizzes] = useState([]);

    useEffect(() => {
        setSortedQuizzes([...quizzes.sort(sort.method)]);
    }, [sort, quizzes]);

    const sortChoice = (e) => {
        const sortSelected = sortType.filter(type => type.value === e.target.value)[0];
        setSort(sortSelected);
        // eslint-disable-next-line no-unused-expressions
        setQuizBoardSortType ? setQuizBoardSortType(sortSelected.value) : null;
    };

    const checkSort = sortType.map(
        type => <Form.Check inline name="sort" label={type.label} type='radio' value={type.value} key={type.value} id={type.value} onChange={sortChoice} checked={sort.value == type.value} />
    );

    return (
        <Container fluid>
            <Row className="mb-4"><div className="d-flex justify-content-around">{checkSort}</div></Row>
            <Row>
                <CardGroupMediaComponent  mediaType={'quiz'} mediaList={sortedQuizzes} readOne={readOneSlug} />
            </Row>
        </Container>
    );

}

export default QuizBoardComponent;