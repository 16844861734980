import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

/**
 * hook that create and returns a previous button. If path is not specified, it will default to -1 (previous history behaviour)
 * it will check if a state from previous page was passed (case when previous page is and edit/create page). 
 * 
 * @param {string} path specifies the path of the link. if none specified, it will default to -1 (history return)
 * @param {string} className for bootstrap or style
 * @param {bool} shouldReplaceHistory if true, will not keep current page in history stack. It means we came from an edit/create page
 * @returns 
 */

const useBackButton = (props) => {
    const defaultLabel = <><i className="fas fa-arrow-left"></i> &nbsp;Retour</>;
    const {path = -1, className, shouldReplaceHistory = false, label = defaultLabel, mediaType, ...other } = props;
    const location = useLocation();
    const fromEditState = location.state?.fromEdit ? location.state.fromEdit : false;

    const to = fromEditState ? -2 : path;


    const backButtonComponent =      <Button 
                    as={Link}
                    className={className}
                    to={to}
                    replace={(shouldReplaceHistory)}
                    state={{fromEdit: shouldReplaceHistory}} 
                    {...other}
                    >
                        {label}
                </Button>

    return {backButtonComponent}
}

export default useBackButton;