import React, { useMemo, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, usePDF } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import ButtonLoaderComponent from '../ButtonLoaderComponent.jsx';
import PropTypes from 'prop-types';
import { useStoreState } from 'easy-peasy';

import logo from '../../../images/logo140.png';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontWeight: 'light',
        fontSize: 11,
        padding: 20,
    },
    sectionlogo: {
        width: '100%',
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: '20%',
        borderRadius: '50%',
    },
    table: {
        width: '100%',
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0,
        borderBottom: '1px solid black',
    },
    header: {
        backgroundColor: '#45ABFF',
        color: 'white',
        borderTop: '1px solid black',
    },
    headerCol: {
        fontFamily: 'Helvetica-Bold',
    },
    headerCol0: {
        backgroundColor: '#5b5b5b'
    },
    col: {
        borderRight: '1px solid black',
        margin: 0,
        padding: 5,
        textAlign: 'center',
    },
    col0: {
        width: '5%',
        borderLeft: '1px solid black',
    },
    col1: {
        width: '25%',
    },
    col2: {
        width: '30%',
    },
    col3: {
        width: '10%',
    },
    col4: {
        width: '30%'
    },
    contentCol: {
        color: '#021f3f',
    },
    contentColVideo: {
        color: '#2784D1',
    },
    videoNames: {
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        width: '100%'
    },
    videoSubHeading: {
        fontSize: 8.5,
        marginTop: 4,
    },
    videoCodeAccess: {
        fontSize: 9,
        color: '#5b5b5b',
    },
    centerVertical: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    pageCounter: {
        textAlign: 'right',
    }
})

const dateString = new Date().toLocaleDateString('fr-FR');

const RomesVideosDocument = (props) => {

    const { romes, videos, setIsReady, groupsPrefix } = props;

    const romeVideos = useMemo(() => {
        if (videos?.length && romes?.length) {
            return romes
                ?.filter(r => videos?.some(video => video.romes?.includes(r.codeRome)))
                ?.map(r => ({
                    ...r,
                    videos: videos?.filter(v => v.romes?.includes(r.codeRome))
                }));
        } else {
            return [];
        }
    }, [romes, videos]);

    useEffect(() => {
        setIsReady(true);
    }, [romeVideos]);

    const groupsList = (videos) => {
        const videosGroups = videos?.reduce((accumulator, currentValue) =>
            accumulator.concat(currentValue.groups), []);

        const videosGroupsFitPrefix = videosGroups.filter(group => group?.uniqueId.includes(groupsPrefix));

        let videosGroupsFitPrefixWithoutDuplicate = [];

        videosGroupsFitPrefix?.forEach(group => {
            if (!videosGroupsFitPrefixWithoutDuplicate.some(vg => vg.uniqueId === group.uniqueId)) {
                videosGroupsFitPrefixWithoutDuplicate.push(group)
            }
        });

        return videosGroupsFitPrefixWithoutDuplicate;
    }

    const groupName = (groupNameOriginal) => {
        if (groupNameOriginal.slice(0, 21) === "secteur professionnel") {
            return groupNameOriginal.slice(22,);
        }
        return groupNameOriginal;
    }

    return (<Document title='liste-experiences-par-code-rome' author='Métiers360'>
        <Page size="A4" style={styles.page}>
            <View>
                <Text>A jour du {dateString}</Text>
            </View>
            <View style={styles.sectionlogo}>
                <Image source={logo} style={styles.logo} />
            </View>
            <View style={styles.table}>
                <View style={[styles.row, styles.header]}>
                    <Text style={[styles.col, styles.col0, styles.headerCol, styles.headerCol0]}>N°</Text>
                    <Text style={[styles.col, styles.col3, styles.headerCol]}>Code ROME</Text>
                    <Text style={[styles.col, styles.col1, styles.headerCol]}>Dénomination ROME</Text>
                    <View style={[styles.col, styles.col2, styles.headerCol]}>
                        <Text>Expériences immersives</Text>
                        <Text style={styles.videoCodeAccess}>Code d'accès direct</Text>
                    </View>
                    <View style={[styles.col, styles.col4, styles.headerCol]}>
                        <Text>Domaines professionnels</Text>
                        <Text>des expériences</Text>
                    </View>
                </View>
                {romeVideos?.sort((a, b) => a.codeRome.localeCompare(b.codeRome)).map((r, i) => {
                    return (
                        <View key={r.id} style={styles.row} wrap={false}>
                            <View style={[styles.col, styles.col0, styles.contentCol, styles.centerVertical]}>
                                <Text>{i + 1}</Text>
                            </View>
                            <View style={[styles.col, styles.col3, styles.contentCol, styles.centerVertical]}>
                                <Text>{r.codeRome}</Text>
                            </View>
                            <View style={[styles.col, styles.col1, styles.contentCol, styles.centerVertical]}>
                                <Text>{r.label}</Text>
                            </View>
                            <View style={[styles.col, styles.col2, styles.contentColVideo, styles.centerVertical]}>
                                {r.videos.map(video => <View key={video.videoName} style={styles.videoNames}>
                                    <Text>{video.videoName}</Text>
                                    <Text style={styles.videoSubHeading}>{video.subHeading}</Text>
                                    <Text style={styles.videoCodeAccess}>{video.directAccessCode}</Text>
                                </View>)}
                            </View>
                            <View style={[styles.col, styles.col4, styles.contentCol, styles.centerVertical]}>
                                {groupsList(r.videos).map(({ uniqueId, name }) => <Text key={uniqueId}>- {groupName(name)}</Text>)}
                            </View>
                        </View>)
                })}
            </View>

            <Text style={styles.pageCounter} fixed
                render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`} />
        </Page>
    </Document>);

};

const DownloadRomeVideosPDF = (props) => {

    const { romes, videos } = props;
    const [isReady, setIsReady] = useState(false);
    const [downloadDone, setDownloadDone] = useState(false);
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);

    const [instance] = usePDF({
        document:
            <RomesVideosDocument
                romes={romes}
                videos={videos}
                setIsReady={setIsReady}
                groupsPrefix={BACKOFFICE_DEFAULT_VALUES?.prefixRomeGroup} />
    });

    useEffect(() => {
        if (!instance.loading && romes?.length && videos?.length && isReady && !downloadDone) {
            setDownloadDone(true);
            const link = document.createElement('a');
            link.href = instance.url;
            link.download = `liste-experiences-par-code-rome-${dateString}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [instance.loading, romes, videos, isReady, downloadDone]);

    const isLoading = (instance.loading && !instance.url) || !romes?.length || !videos?.length || !isReady;

    return instance.error
        ? <Button variant="danger" ><i className="fas fa-exclamation-triangle mr-1" />Par code ROME</Button>
        : <ButtonLoaderComponent variant="primary" isSending={isLoading} disabled={isLoading}
            href={instance.url} download={`liste-par-code-rome-${dateString}.pdf`}
            iconClassName="fas fa-download" msg="Par code ROME" />

};

RomesVideosDocument.propTypes = {
    romes: PropTypes.array.isRequired,
    videos: PropTypes.array.isRequired,
    setIsReady: PropTypes.any.isRequired,
    groupsPrefix: PropTypes.string
};
DownloadRomeVideosPDF.propTypes = {
    romes: PropTypes.array.isRequired,
    videos: PropTypes.array.isRequired
};

export default DownloadRomeVideosPDF;
