import React from "react";
import DynamicTable from "../../widgets/dynamicTable/DynamicTable.jsx";
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import PropTypes from 'prop-types';

import '../preparatoryOrders/PreparatoryOrderTableComponent.scss';
import { useStoreActions, useStoreState } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../../hooks/useModal.jsx";
import { useToast } from "../../../hooks/useToast.jsx";


const ReturnOrdersTableComponent = ({ orders, enableToControlVisibleColumns = false, withParams }) => {
    const returnOrdersSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('returnOrders'));
    const {deleteReturnOrder} = useStoreActions(actions => actions.orders);
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();
    const navigate = useNavigate();
    
    const statusColors = {draft: 'danger', validated: 'success', processed: 'secondary', closed: 'info'};
    const iconifyStatus = (order) => <div className="statusdiv">
        <i className={`fas fa-solid fa-circle fa-xs text-${statusColors[order?.returnOrderStatus?.value]}`}/>
            {order.returnOrderStatus?.label}
    </div>;

    const displayDelete = (order) => order.isDeletable
        ? <Button variant="danger" size="sm" className="m-0 p-2" 
            onClick={(event) => { event.stopPropagation(); deleteOrderModal(order); }}>
            <i className="fas fa-trash-alt"></i>
        </Button>
        : <OverlayTrigger placement="left" overlay={
            <Tooltip>
                <p>Ce bon ne peut pas être supprimé car ce n'est pas un brouillon.</p>
            </Tooltip>}>
            <span>
                <Button disabled variant="danger" size="sm" className="m-0 p-2">
                    <i className="fas fa-trash-alt"></i>
                </Button>
            </span>
        </OverlayTrigger>;

    const displayDueDate = (order) => order?.returnDueDate 
        ? <span className={(new Date(order?.returnDueDate) < new Date().setHours(0,0,0,0) 
            && (order?.returnOrderStatus?.value === 'draft' || order?.returnOrderStatus?.value === 'validated' )) 
                ? "text-danger fw-bold" 
                : ""}>
            {HelperMetiers360.getdisplayDateType(order?.returnDueDate, 'day')}
        </span> 
        : <>-</>;

    const displayNbHeadsets = (order) => order.productsToReturnCount 
        ? <span className={(order.returnedProductsCount > order.productsToReturnCount) ? "text-danger fw-bold" : ""}>
            {(order.returnedProductsCount ?? 0) + "/" + order.productsToReturnCount}</span>
        : <>-</>;

    const sortType = orders?.length > 0
        && [
            {
                value : 'clientName', label : 'Établissement',
                test : HelperMetiers360.isArrayContainsValue(orders, "clientName"),
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
                display: (order) => order.clientName ? order.clientName : '-'
            },
            {
                value : 'tagOperations', label : 'Opé M360',
                test : HelperMetiers360.isArrayContainsValue(orders, "tagOperations"),
                method : (a, b) => HelperMetiers360.sortStringArray(
                    a.tagOperations?.map(tag => tag.name),
                    b.tagOperations?.map(tag => tag.name)),
                display: (order) => order.tagOperations?.length > 0 ? order.tagOperations?.map(tag => tag.name).join(", ") : '-'
            },
            {
                value : 'contactM360', label : 'Contacts M360',
                test : HelperMetiers360.isArrayContainsValue(orders, "contactM360Name"),
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.contactM360Name, b.contactM360Name),
                display: (order) => order.contactM360Name ? order.contactM360Name : '-'
            },
            {
                value : 'contactFirstLastName', label : 'Contact',
                test : HelperMetiers360.isArrayContainsValue(orders, "contactFirstLastName"),
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.contactFirstLastName, b.contactFirstLastName),
                display: (order) => order.contactFirstLastName !== " " ? order.contactFirstLastName : '-'
            },
            {
                value : 'id', label : 'Id',
                test : HelperMetiers360.isArrayContainsValue(orders, "id"),
                method : (a, b) =>  b.id - a.id,
                display: (order) => order.id
            },
            {
                value : 'returnDueDate', label : 'Retour prévu',
                test : HelperMetiers360.isArrayContainsValue(orders, "returnDueDate"),
                method: (a, b) => new Date(b.returnDueDate) - new Date(a.returnDueDate),
                display: (order) => displayDueDate(order),
                flatDisplay: (order) => order?.returnDueDate ? HelperMetiers360.getdisplayDateType(order.returnDueDate, 'day') : '-'
            },
            {
                value : 'providerName', label : 'Prestataire',
                test : HelperMetiers360.isArrayContainsValue(orders, "provider"),
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.provider.name, b.provider.name),
                display: (order) => order.provider.name ? order.provider.name : '-'
            },
            {
                value : 'updatedDate', label : 'Modifié le',
                test : HelperMetiers360.isArrayContainsValue(orders, "updatedDate"),
                method: (a, b) => new Date(b.updatedDate.date) - new Date(a.updatedDate.date),
                display: (order) => order?.updatedDate.date ? HelperMetiers360.getdisplayDateType(order.updatedDate.date, 'day') : '-'
            },
            {
                value : 'productsToReturnCount', label : 'Nb casques retournés',
                test : HelperMetiers360.isArrayContainsValue(orders, "productsToReturnCount"),
                method : (a, b) => b.productsToReturnCount - a.productsToReturnCount,
                display: (order) => displayNbHeadsets(order),
                flatDisplay: (order) => `${order.returnedProductsCount ?? "0"}/${order.productsToReturnCount}`
            },
            {
                value : 'returnOrderStatus', label : 'Statut',
                test : HelperMetiers360.isArrayContainsValue(orders, "returnOrderStatus"),
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.returnOrderStatus, b.returnOrderStatus),
                display: (order) => iconifyStatus(order),
                flatDisplay: (order) => order.returnOrderStatus?.label ?? '-'
            },
            {
                value: 'delete', label: '',
                test: AuthorizationChecker.isAdmin(),
                display: (order) => displayDelete(order),
                flatDisplay: () => null
            }
        ];


    const deleteOrderModal = (order) => {
        setModalData({
            ...modalData,
            header: <>Suppression d'un bon de retour</>,
            content: <h5>Etes-vous sûr⋅e de vouloir supprimer ce bon de retour ?</h5>,
            resetButton: 'Supprimer',
            cancelButton:'Annuler',
            onReset: () => {
                setIsSending(true);
                deleteReturnOrder({ uniqueId: order.uniqueId })
                    .then(() => {
                        toast.success()
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            },
        });
        setModalShow(true);
    };

    const handleClick = (uniqueId) => {
        returnOrdersSlugs && navigate(returnOrdersSlugs.readOne.replace(':uniqueId', uniqueId));
    }

    return sortType 
        && <>
        <DynamicTable 
                contentTable = {orders}
                contentSort = {sortType}
                valueInitSort = "id"
                index = 'uniqueId'
                handleClick={handleClick}
                enableToControlVisibleColumns={enableToControlVisibleColumns && AuthorizationChecker.isAdmin()}
                withParams={withParams}
                tableName="returnOrdersTable"
                filename="bons_retour" />
            {modalComponent}
        </>;
};

ReturnOrdersTableComponent.propTypes = {
    orders: PropTypes.array.isRequired,
    enableToControlVisibleColumns: PropTypes.bool,
    withParams: PropTypes.bool
};

export default ReturnOrdersTableComponent;