import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button, Alert, ToggleButton, ToggleButtonGroup, Form } from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';
import Loader from '../../components/widgets/Loader'
import { Link } from "react-router-dom";

import GroupsTableComponent from "../../components/groups/GroupsTableComponent.jsx";
import GroupsTreeComponent from "../../components/groups/GroupsTreeComponent.jsx";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import useSearchWithParams from "../../hooks/useSearchWithParams";
import HelperMetiers360 from "../../services/HelpersMetiers360";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

import "./GroupsDisplayComponent.scss";

const GroupsTableWithParams = withURLParamsTableComponent(GroupsTableComponent);

const GroupsDisplayComponent = ({hasWLR = false, ...props}) => {

    const {groups, allVideos, allQuizzes, enableToControlVisibleColumns=false} = props;

    const { isFetchingAllGroups } = useCustomGetStoreState("groups");
    const viewStylePreference = useStoreState(state => state.preferences.groupListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setGroupListStyle);

    const groupsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('groups'));

    const [filteredGroups, setFilteredGroups] = useState([]);

    const [viewStyle, setViewStyle] = useState(viewStylePreference);

    const [searchInContent, setSearchInContent] = useState(false);
    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés (Nom, Producteur ...)', delay: 400, width: "30em"});

    // return true if exist file with lua extension or file named 'background.mp4'
    const isAnimatedGroup = (files) => {
        let res = false;
        if(files.length > 0) {
            res = files.some(file => file.localName.includes('lua') || file.localName.includes('background.mp4'));
        }
        return res;
    }

    const getVideosContained = (videos, groupUniqueId) => videos
            .filter(video => video.groups?.some(groupInVideo => groupInVideo.uniqueId === groupUniqueId) || video.groupsByDefault?.some(groupInVideo => groupInVideo.uniqueId === groupUniqueId))
            .map(video => {return {
                description: video.description,
                name: video.videoName,
                thumbnail: video.thumbnail,
                type: 'video',
                uniqueId: video.uniqueId
            }});

    const getQuizzesContained = (quizzes,groupUniqueId) => quizzes
            .filter(quiz => quiz.groups?.some(groupInquiz => groupInquiz.uniqueId === groupUniqueId) || quiz.groupsByDefault?.some(groupInquiz => groupInquiz.uniqueId === groupUniqueId))
            .map(quiz => {return {
                description: quiz.description,
                name: quiz.name,
                thumbnail: quiz.thumbnail,
                type: 'quiz',
                uniqueId: quiz.uniqueId
            }});

    const getGroupsContained = (groups, groupUniqueId, menuGroupId = null) => groups
            .filter(group => group.groups?.some(groupInGroup => groupInGroup.uniqueId === groupUniqueId) || group.groupsByDefault?.some(groupInGroup => groupInGroup.uniqueId === groupUniqueId))
            .map(group => {return {
                name: group.name,
                uniqueId: group.uniqueId,
                private: group.private,
                thumbnail: group.links.thumbnail,
                producers: group.producersName,
                releaseDate: group.releaseDate,
                hasFiles: isAnimatedGroup(group.files),
                type: 'group',
                displayOrder: group.groups?.find(g=>g.uniqueId === groupUniqueId)?.displayOrder,
                position: group.position,
            }})
            .sort(function(a, b) {
                if (menuGroupId && menuGroupId === groupUniqueId) {
                    return a.position - b.position;
                } else {
                return a.displayOrder - b.displayOrder;
                }
            });

    const producersName = (producers) => producers?.length ? producers.map((producer) => producer.clientName ).map(String)?.join(', '):null;

    const enrichedGroups = useMemo(() => {
        const menuGroup = groups?.length ? groups.find(group => group.idHeadset == 0) : null;

        const tmpEnrichedGroups = menuGroup ?
            groups.map(group => {
                if (group.idHeadset !== 0 && (!group.groups?.length || (hasWLR && !group.groups.some((g)=> groups.some((g2)=> g2.uniqueId === g.uniqueId))))) {
                    group.groupsByDefault = [menuGroup];
                } else if (group.groupsByDefault?.length) {
                    group.groupsByDefault.splice(0,group.groupsByDefault.length); // reset the groupsByDefault for instant updates
                }
                group.producersName = producersName(group.producers);
                return group; // ! this mapping changes AllGroups
            })
            : [];
        const enrichedVideos = menuGroup && allVideos?.length ?
            allVideos.map(video => {
                if (video.idHeadset !== 0 && (!video.groups || (video.groups && video.groups.length === 0))) {
                    video.groupsByDefault = [menuGroup];
                } else if (video.groupsByDefault?.length) {
                    video.groupsByDefault.splice(0,video.groupsByDefault.length); // reset the groupsByDefault for instant updates
                }
                return video;
            })

            : [];

        const enrichedQuizes = menuGroup && allQuizzes?.length ?
            allQuizzes.map(quiz => {
                if (quiz.idHeadset !== 0 && (!quiz.groups || (quiz.groups && quiz.groups.length === 0))) {
                    quiz.groupsByDefault = [menuGroup];
                } else if (quiz.groupsByDefault?.length) {
                    quiz.groupsByDefault.splice(0,quiz.groupsByDefault.length); // reset the groupsByDefault for instant updates
                }
                return quiz;
            }) :
            [];
        
        return tmpEnrichedGroups.map(group => {
            group.contents = [
                ...getGroupsContained(groups,group.uniqueId, menuGroup.uniqueId),
                ...getVideosContained(enrichedVideos, group.uniqueId),
                ...getQuizzesContained(enrichedQuizes, group.uniqueId)];
            return group;
            });
    }, [allQuizzes, allVideos, groups]);

  

    useEffect(() => {
        if(enrichedGroups?.length !==0) {
            setFilteredGroups(
                [...enrichedGroups.filter((group) => 
                        HelperMetiers360.isSearchInText(search, group.name)
                        || HelperMetiers360.isSearchInText(search, group.producersName)
                        || (searchInContent 
                                && HelperMetiers360.isSearchInText(search, 
                                    group.contents?.map(content => content.name)?.join(', ')))
                )]
            )
        }
    }, [search, enrichedGroups, searchInContent]);

    const isNoGroups = !isFetchingAllGroups && (!groups || !groups.length);
    const isNoResult = !isFetchingAllGroups && groups?.length && (filteredGroups && !filteredGroups.length);

    const viewStyleType=[
        {value : 'table', label:<><i className="fas fa-table"></i></>, view : <GroupsTableWithParams groups={filteredGroups} groupsSlugs={groupsSlugs} enableToControlVisibleColumns={enableToControlVisibleColumns} />},
        {value : 'tree', label:<><i className="fas fa-sitemap"></i></>, view :<GroupsTreeComponent groups={enrichedGroups} filteredGroups={filteredGroups} groupsSlugs={groupsSlugs}/>},
    ];

    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }
    
    const checkViewStyle = viewStyleType.map(
        type => <ToggleButton value={type.value} key={type.value} id={type.value} checked={type.value === viewStyle}>{type.label}</ToggleButton>
    );

    const groupSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("groups"));

    return <>
        <Row className="item-page">
            <Col className="m360-col3-start">
                {searchInput}
            </Col>
            <Col className="m360-col3-center">
                <ToggleButtonGroup type="radio" name="option" value={viewStyle} onChange={handleChange}>
                    {checkViewStyle}
                </ToggleButtonGroup>
            </Col>
            <Col className="m360-col3-end">
                { groupSlugs.create
                    && <Button variant="success" as={Link} to={groupSlugs.create}>
                        <i className="fas fa-plus"></i>&nbsp;Nouveau
                    </Button>
                }
            </Col>
        </Row>
        <Row>
            <Col className='mt-3'>
                <Form.Check
                    type="checkbox"
                    onChange={()=> setSearchInContent((prev)=>!prev)}
                    defaultChecked={false}
                    label = "Chercher dans le contenu des groupes"
                    id="searchContent"
                />
            </Col>
        </Row>

        <Row>
            {isFetchingAllGroups && <Loader /> }
            {!isNoGroups && viewStyleType.filter(type => type.value===viewStyle)[0].view  }
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucun groupe ne correspond à votre recherche</Alert>}
            {isNoGroups && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun groupe</Alert>}
        </Row>
    </>;
}

export default GroupsDisplayComponent;