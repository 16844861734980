import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useStoreState, useStoreActions } from 'easy-peasy';

import PlaylistsTableComponent from "../../components/playlists/PlaylistsTableComponent.jsx";

import { useModal } from "../../hooks/useModal.jsx";
import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";


import "./PlaylistsPage.scss";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

const PlaylistsTableWithParams = withURLParamsTableComponent(PlaylistsTableComponent);


const PlaylistsPage = (props) => {

    const navigate = useNavigate();

    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const nameRef = useRef(null);

    const { allPlaylists, isFetchingPlaylists  } = useCustomGetStoreState('playlists');
    const { postPlaylist } = useStoreActions((actions => actions.playlists));
    const playlistSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("playlists"));

    const [filteredPlaylists, setFilteredPlaylists] = useState(allPlaylists);

    useEffect(() => {
        setFilteredPlaylists(allPlaylists);
    }, [allPlaylists]);

    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés (Nom, Établissement, Créée par ...)', delay: 400})

    useEffect(() => {
        setFilteredPlaylists([... allPlaylists.filter(playlist => 
            HelperMetiers360.isSearchInText(search, playlist.name)
            || HelperMetiers360.isSearchInText(search, playlist.client.name)
            || HelperMetiers360.isSearchInText(search, playlist.owner.username)
        )]);
    }, [search, allPlaylists]);

    if(isFetchingPlaylists) {
        return <Loader />;
    }

    const onSendData = () => {
        const formData = new FormData();
        nameRef && nameRef.current && nameRef.current.value ? 
            formData.append('playlist_name', nameRef.current.value)
            :formData.append('playlist_name', 'Nouvelle playlist');
        postPlaylist({formData})
            .then((response) => {
                navigate(playlistSlugs.readOne.replace(':uniqueId', response.uniqueId));
            });
    }
    const NameForm = <Form.Control ref={nameRef} type="input" name='playlist_name' placeholder='Indiquer le nom de la playlist' onKeyUp={(e) =>{if(e.code === 'Enter') onSendData();}} />;
    const onCreatePlaylist = () => {
        setModalData({
            ...modalData,
            header:"Création d'une playlist",
            content:NameForm,
            cancelButton: 'Annuler',
            onValidate: () => onSendData()
        });
        setModalShow(true);
    }
    const createPlaylistsButton = <Button variant="success" onClick={onCreatePlaylist}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>

    const isNoResult = !isFetchingPlaylists && allPlaylists.length !=0 && filteredPlaylists.length ==0;
    const isNoPLaylist = !isFetchingPlaylists && !allPlaylists.length

    return <>
        <Container fluid>
            <Row>
                <Col>
                    <h2>Vos playlists</h2>
                    <Alert variant="info">Besoin de travailler sur un thème précis avec vos publics ou de mettre en avant un secteur particulier ? <br />
                    Faites votre sélection de vidéos et de quiz et affectez-la à vos casques. Seule la sélection s'affichera dans le casque.</Alert>
                </Col>
            </Row>
            <Row className="item-page">
                <Col>
                    {searchInput}
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    {createPlaylistsButton}
                </Col>
            </Row>
            {isNoResult && <Alert className="mt-3" variant="warning"> Aucune playlist ne correspond à votre recherche</Alert>}
            {isNoPLaylist && <Alert className="mt-3" variant="warning">Vous n'avez pas encore créé de playlists</Alert>}
            {
                filteredPlaylists.length > 0 && 
                    <PlaylistsTableWithParams playlists={filteredPlaylists} />
            }
            {(isFetchingPlaylists) && <Loader />}
            <>{modalComponent}</>
        </Container>
    </>;
}

export default PlaylistsPage;