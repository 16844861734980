import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

/**
 * 
 * @param {Array} jobAppellations array of all jobAppellations to be rendered
 * @param {bool} withParams  bool to handle params in url
 * 
 * @example
 *  <JobAppellationsTableComponent jobAppellations={jobAppellations}/>
 * 
 */

const JobAppellationsTableComponent = ({ jobAppellations = [], withParams }) => {
    const [jobAppellationsToDisplay, setJobAppellationsToDisplay] = useState([]);
    const [sortedjobAppellation, setSortedjobAppellation] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const romesSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('romes'));
    const videosSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));

    const navigate = useNavigate();

    useEffect(() => {
        setShouldSort(true);
        setSortedjobAppellation([...jobAppellations]);
    }, [jobAppellations]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedjobAppellation, jobAppellationsToDisplay, setJobAppellationsToDisplay, 100);

    if (jobAppellations.length === 0) {
        return <></>;
    }

    const handleClickROME = (codeRome) => {
        if (romesSlugs.readOne) {
            navigate(romesSlugs.readOne.replace(':uniqueId', codeRome));
        }
    }
    const displayROME = (codeROME) => <Button variant="light" size="sm"
        onClick={(e) => { e.stopPropagation(); handleClickROME(codeROME) }}>{codeROME}
    </Button>;

    const handleClickVideo = (uniqueIdVideo) => {
        if (videosSlugs.readOne) {
            navigate(videosSlugs.readOne.replace(':uniqueId', uniqueIdVideo));
        }
    }
    const displayVideo = (video) => <Button variant='light' size='sm' className="m-1"
        onClick={(e) => { e.stopPropagation(); handleClickVideo(video.uniqueId) }}>
        {`${video.videoName}${video.subHeading ? " - " + video.subHeading : ""}`}
    </Button>;


    const sortType = [
        {
            value: 'label', label: 'Métier',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'label'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (jobAppellation) => jobAppellation.label
        },
        {
            value: 'rome', label: 'ROME',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'rome'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.rome, b.rome),
            display: (jobAppellation) => displayROME(jobAppellation.rome),
            flatDisplay: (jobAppellation) => jobAppellation.rome
        },
        {
            value: 'videos', label: 'Vidéos',
            test: HelperMetiers360.isArrayContainsValue(jobAppellations, 'videos'),
            method: (a, b) => HelperMetiers360.compareWithNullable(a.videos.length, b.videos.length),
            display: (jobAppellation) => <>{
                jobAppellation.videos.map(video => <div key={jobAppellation.code + '_' + video.uniqueId}>{
                        displayVideo(video)
                }</div>)
            }</>,
            flatDisplay: (jobAppellation) => jobAppellation.videos.map((video) => `${video.videoName}${video.subHeading ? " - " + video.subHeading : ""}`).join("/")
        }
    ];



    return (
        <DynamicTableInfinite
            contentTable={jobAppellationsToDisplay}
            contentSort={sortType}
            valueInitSort="label"
            index='code'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedjobAppellation}
            sortedContent={sortedjobAppellation}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            handleClick={null}
            filename="metiers"
        />
    );
}

JobAppellationsTableComponent.propTypes = {
    jobAppellations: PropTypes.array,
    withParams: PropTypes.bool.isRequired,
};

export default JobAppellationsTableComponent;