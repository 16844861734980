import React, { useEffect, useMemo, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, usePDF, Image } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import logo from '../../../images/logo_changeons_point_vue.png';
import ButtonLoaderComponent from '../ButtonLoaderComponent';
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontWeight: 'light',
        fontSize: 12,
        padding: 40,
        height: '100%'
    },
    todayDate: {
        fontSize: 10,
        marginBottom: 5,
        marginTop: -15
    },
    sectionlogo: {
        width: '70%',
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end',
    },
    logo: {
        width: '25%',
        borderRadius: '50%',
    },
    title: {
        fontSize: 22,
        fontFamily: 'Helvetica-Bold',
        marginBottom: 20,
    },
    row: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10
    },
    ms: {
        marginLeft: 10,
    },
    me: {
        marginRight: 10,
    },
    header: {
        marginBottom: 20,
    },
    headsets: {
        marginBottom: 5,
    },
    mosaic: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        margin: 0,
        marginBottom: 40,
        padding: 0,
    },
    card: {
        width: 150,
        margin: 5,
        padding: 0,
        height: 84,
    },
    pageCounter: {
        fontSize: 16,
        textAlign: 'right',
        fontFamily: 'Helvetica-Bold',
    },
    spaceBetween: {
        justifyContent: 'space-between'
    },
    flexEnd: {
        alignItems: 'flex-end',
    },
    codeAccess: {
        width: 50,
        textAlign: 'center',
        color: '#5b5b5b',
        marginBottom: 5,
        border: '1px solid #0080f4',
        borderRadius: '30px',
        padding: 2,
        fontSize: 8,
        marginRight: 5
    },
    codeAccessMosaic: {
        marginLeft: 55
    },
    dFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    legend: {
        marginBottom: 10
    }
})

const dateString = new Date().toLocaleDateString('fr-FR');

const PlaylistContentListDocument = ({ playlist, headsets, videos, quizzes }) => {
    return <Document title='contenu-playlist' author='Métiers360'>
            <Page size="A4" style={styles.page}>
                <View style={[styles.row, styles.spaceBetween]}>
                    <View>
                        <View>
                            <Text style={styles.title}>{playlist.name}</Text>
                        </View>
                        <View style={[styles.header]}>
                            <Text style={styles.headsets}>Casques :</Text>
                            <View>{
                                headsets.map(headset => 
                                <View style={[styles.row, styles.ms]} key={headset.uniqueId}>
                                    <Text style={styles.me}>•</Text>
                                    <Text>{headset.headsetName ?? headset.deviceId}</Text>
                                </View>)
                            }</View>
                        </View>
                    </View>

                    <View style={[styles.sectionlogo, styles.flexEnd]}>
                        <Text style={styles.todayDate}>A jour du {dateString}</Text>
                        <Image source={logo} style={styles.logo} />
                    </View>
                </View>

            <View style={styles.dFlex}>
                <Text style={styles.codeAccess}>XXXXX</Text>
                <Text style={styles.legend}>  : code d'accès direct</Text>
            </View>

                <View style={[styles.mosaic]}>
                    { videos?.map((video) => 
                        <View key={video.uniqueId}>
                            <Image source={video.links?.thumbnail + "?"} style={styles.card} />
                            {video.directAccessCode
                                && <Text style={[styles.codeAccess, styles.codeAccessMosaic]}>{video.directAccessCode}</Text>}
                        </View>
                    )}
                </View>

                <View style={styles.mosaic}>
                    { quizzes?.map((quizz) => 
                        <View key={quizz?.uniqueId}>
                            <Image source={quizz?.links?.thumbnail + "?"} style={styles.card} />
                            {quizz.directAccessCode
                                && <Text style={[styles.codeAccess, styles.codeAccessMosaic]}>{quizz.directAccessCode}</Text>}
                        </View>
                    )}
                </View>

                <Text style={styles.pageCounter} fixed
                    render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`} />
            </Page>
        </Document>;

};

const DownloadPlaylistContentListPDF = ({ playlist }) => {

    const {allHeadsets} = useCustomGetStoreState("headsets");
    const {allVideos} = useCustomGetStoreState("videos");
    const {allQuizzes} = useCustomGetStoreState("quizzes");

    const [downloadDone, setDownloadDone] = useState(false);

    const headsetsPlaylist = useMemo(() => allHeadsets.filter((headset) => 
        playlist.headsets.includes(headset.uniqueId))
    , [allHeadsets, playlist]);

    const videosPlaylist = useMemo(() => allVideos.filter((video) => 
        playlist.videos.includes(video.uniqueId))
    , [allVideos, playlist]);

    const quizzesPlaylist = useMemo(() => allQuizzes.filter((quizz) => 
        playlist.quizzes.includes(quizz.uniqueId))
    , [allQuizzes, playlist]);

    const [instance, setInstance] = usePDF({document: 
        <PlaylistContentListDocument playlist={playlist} headsets={[]} videos={[]} />});

    useEffect(() => {
        if(playlist && headsetsPlaylist && videosPlaylist && quizzesPlaylist){
            setInstance(<PlaylistContentListDocument
                playlist={playlist}
                headsets={headsetsPlaylist}
                videos={videosPlaylist}
                quizzes={quizzesPlaylist} />);
        }
    }, [headsetsPlaylist, videosPlaylist, quizzesPlaylist])

    useEffect(() => {
        if (!instance.loading && playlist && !downloadDone) {
            setDownloadDone(true);
            const link = document.createElement('a');
            link.href = instance.url;
            link.download = `contenu-playlist-${dateString}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }, [instance.loading, playlist, downloadDone]);


    const isLoading = (instance.loading && !instance.url) || !playlist || !headsetsPlaylist;

    return instance.error
        ? <Button variant="danger" ><i className="fas fa-exclamation-triangle mr-1" />Contenu de la playlist</Button>
        : <ButtonLoaderComponent variant="primary" isSending={isLoading} disabled={isLoading}
            href={instance.url} download={`contenu-playlist-${dateString}.pdf`}
            iconClassName="fas fa-download" msg="Contenu de la playlist" />
};

PlaylistContentListDocument.propTypes = {
    playlist: PropTypes.object.isRequired,
    headsets: PropTypes.array.isRequired,
    videos: PropTypes.array.isRequired,
    quizzes: PropTypes.array.isRequired
};
DownloadPlaylistContentListPDF.propTypes = {
    playlist: PropTypes.object.isRequired
};

export default DownloadPlaylistContentListPDF;
