import React, {useEffect} from "react";
import { useStoreActions } from "easy-peasy";
import useCustomGetStoreState from "../../../../hooks/useCustomGetStoreState";

import Loader from "../../../widgets/Loader";
import GroupsDisplayComponent from "../../../groups/GroupsDisplayComponent";

const ClientGroupsTabComponent = (props) => {
    const {client} = props;

    const { accessibleGroupsUIdByClientId, isFetchingAccessibleGroupsUIdByClientId, 
        accessibleVideosUIdByClientId, isFetchingAccessibleVideosUIdByClientId,
        accessibleQuizzesUIdByClientId, isFetchingAccessibleQuizzesUIdByClientId } = useCustomGetStoreState('clients');
    const { fetchAccessibleGroupsUIdByClientId, fetchAccessibleVideosUIdByClientId, fetchAccessibleQuizzesUIdByClientId } = useStoreActions(actions => actions.clients);

    const { allGroups } = useCustomGetStoreState('groups');
    const { allVideos } = useCustomGetStoreState('videos');
    const { allQuizzes } = useCustomGetStoreState('quizzes');

    useEffect(() => {
        fetchAccessibleGroupsUIdByClientId(client?.uniqueId);
        fetchAccessibleVideosUIdByClientId(client?.uniqueId);
        fetchAccessibleQuizzesUIdByClientId(client?.uniqueId);
    }, [])

    const accessibleGroups = allGroups.filter(group => accessibleGroupsUIdByClientId.includes(group.uniqueId));
    const accessibleVideos = allVideos.filter(video => accessibleVideosUIdByClientId.includes(video.uniqueId));
    const accessibleQuizzes = allQuizzes.filter(quiz => accessibleQuizzesUIdByClientId.includes(quiz.uniqueId));
    
    return isFetchingAccessibleGroupsUIdByClientId 
        || isFetchingAccessibleVideosUIdByClientId 
        || isFetchingAccessibleQuizzesUIdByClientId
        ? <Loader />
        : <GroupsDisplayComponent 
            groups={accessibleGroups} 
            allVideos={accessibleVideos} 
            allQuizzes={accessibleQuizzes} 
            hasWLR={(client.GroupListIsRestrictive && client.groupList?.length)} />
}

export default ClientGroupsTabComponent;