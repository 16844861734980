import React, {useState, useEffect, useRef} from "react";
import { Container, Row, Col, Alert, Form, Button, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { useStoreActions } from 'easy-peasy';

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";

import Loader from "../../components/widgets/Loader";
import ClientsCRMTableComponent from "../../components/clients/ClientsList/ClientsCRMTableComponent";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams";
import AuthorizationChecker from "../../services/AuthorizationChecker";
import { useModal } from "../../hooks/useModal.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360";

import './ClientsCRMPage.scss';
import ClientFormComponent, { submitClientForm } from "../../components/clients/clientForm/ClientFormComponent.jsx";
import { useToast } from "../../hooks/useToast.jsx";
import MergeClientsComponent, {submitMergedClients} from "../../components/clients/mergeClients/MergeClientsComponent.jsx";

const ClientCRMTableWithParams = withURLParamsTableComponent(ClientsCRMTableComponent);

const ClientCRMPage = () => {

    const {allClientsCRM, isFetchingAllClientsCRM} = useCustomGetStoreState('clients');
    const { fetchAllPropsForAllClients, searchClientsCRMIdsByContact } = useStoreActions(actions => actions.clients);
    const toast = useToast();
    const { createClientCRM } = useStoreActions(actions => actions.clients);

    const [filteredClients, setFilteredClients] = useState([]);

    const [currentInvoiceOnly, setCurrentInvoiceOnly] = useState(false);
    const [subscribersOnly, setSubscribersOnly] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    const {searchInput, search} = useSearchWithParams({placeholder: 
        'Rechercher par mots-clés (Nom, C.P., Ville ...)', delay: 400});
    
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();

    const suscribersOnlyRef = useRef(false);
    const invoiceRef = useRef(false);
    const mergeClientsRef = useRef(null);

    useEffect(() => {
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, []);

	useEffect(() => {
        const newFilteredClients = allClientsCRM
            ?.filter(client => {
                return (!currentInvoiceOnly || client.invoices !== 0)
                    && (!subscribersOnly || client.isSubscriber)
                })
            .filter(
                client => HelperMetiers360.isSearchInText(search, client.name)
                    || HelperMetiers360.isSearchInText(search, client.addressZipCode)
                    || HelperMetiers360.isSearchInText(search, client.addressCity)
                    || HelperMetiers360.isSearchInText(search, client.status)
                    || HelperMetiers360.isSearchInText(search, client.categories?.join(", "))
                    || HelperMetiers360.isSearchInText(search, client.tagOperationsName?.join(", "))
                    || HelperMetiers360.isSearchInText(search, client.contactsM360?.map(contact => contact.name)?.join(", "))
                    || HelperMetiers360.isSearchInText(search, client.invoicesNumbers)
            );
        if(newFilteredClients !== filteredClients) {
            setFilteredClients(newFilteredClients);
        }
	}, [allClientsCRM, search, currentInvoiceOnly, subscribersOnly]);

    const isNoResult = !isFetchingAllClientsCRM && allClientsCRM.length && !filteredClients.length;
    
    const handleInvoiceCheckBox = (event) => {
        const checkBox = event.currentTarget;
        setCurrentInvoiceOnly(checkBox.checked);
    };
      
    const handleSubscribersCheckBox = (event) => {
        const checkBox = event.currentTarget;
        setSubscribersOnly(checkBox.checked);
    };

    const openCreateClientModal = () => {
        const handleSubmit = () => {
            setIsSending(true);
            const result = submitClientForm({action: 'create', createClientCRM});

            result
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }

        setModalData({
            ...modalData,
            header: <>Création d'un client</>,
            content: <ClientFormComponent action="create" />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            size: 'xl', 
        });
        setModalShow(true);
    };

    const mergeClientsModal = () => {
        const handleSubmit = () => {
            if(!mergeClientsRef?.current){
                toast.open({ message: "Veuillez sélectionner les clients à fusionner", variant: "danger" })
                return;
            }
            setIsSending(true);
            const result = submitMergedClients(mergeClientsRef);

            result
                .then(() => {
                    toast.success();
                    setModalShow(false);
                })
                .finally(() => setIsSending(false));
        }
        setModalData({
            ...modalData,
            header: <>Fusionner des clients</>,
            content: <MergeClientsComponent mergeClientsRef = {mergeClientsRef} />,
            cancelButton: 'Annuler',
            onValidate: handleSubmit,
            validateButton: 'Fusionner',
            size: 'xl'
        });
        setModalShow(true);
    }


    const searchByContact =  () => {
        if (search) {
            setIsSearching(true);
            searchClientsCRMIdsByContact(search)
                .then((res) => {
                    if (res[0]?.length) {
                        const matchingIds = new Set(res[0].map(id => id.uniqueId));
                        const clientsFound = allClientsCRM.filter(c => matchingIds.has(c.uniqueId));
                        suscribersOnlyRef.current.checked = false;
                        invoiceRef.current.checked = false;
                        setSubscribersOnly(false);
                        setCurrentInvoiceOnly(false)
                        setFilteredClients(clientsFound);
                    } 
                })
                .finally(() =>setIsSearching(false));
        }
    };

    const adminFilter = AuthorizationChecker.isAdmin() &&
        <Row className="mt-3">
            <Col>
                <Form.Check
                    type="checkbox"
                    onChange={handleInvoiceCheckBox}
                    defaultChecked={currentInvoiceOnly}
                    label = "Seulement les clients facturés"
                    id="currentInvoiceOnly"
                    ref={invoiceRef}
                />
            </Col>
            <Col>
                <Form.Check
                    type="checkbox"
                    onChange={handleSubscribersCheckBox}
                    defaultChecked={subscribersOnly}
                    label = "Seulement les clients abonnés"
                    id="subscribersOnly"
                    ref={suscribersOnlyRef}
                />
            </Col>
        </Row>;

    return <Container fluid>
        <Row>
            <Col>
                <h2>Tous nos clients</h2>
            </Col>
        </Row>
        <Row className='pe-0'>
            <Col>
                <Alert variant="info" className='mt-2'>Cette page rassemble tous les clients avec qui nous sommes en interaction.
                Lorsque ces clients ont un abonnement et des casques, il est possible d'accéder à leur page.</Alert>
            </Col>
            <Col className='d-flex justify-content-end align-items-center pe-0' xs={3}>
                <Button variant="success" onClick={mergeClientsModal}><i className="fas fa-object-group"/>&nbsp; Fusionner des clients</Button>
            </Col>
        </Row>
        <Row>
            <Col>
                {allClientsCRM.length > 1 && 
                <div className="searchBlock">
                    {searchInput}
                    {search && 
                    <OverlayTrigger
                    placement="left"
                    overlay={
                        <Tooltip>
                            Rechercher par contacts (email, téléphone)
                        </Tooltip>
                    }    
                    >
                        <span>
                            <Button className="contactSearch" onClick={searchByContact}>
                                { isSearching
                                    ? <Spinner as="span" animation="border" size="sm" aria-hidden="true" />
                                    : <i className="fas fa-address-book"/>
                                }
                            </Button>
                        </span>
                    </OverlayTrigger>}
                </div>}
            </Col>
        </Row>
            <Row>
                <Col>
                    {!isFetchingAllClientsCRM && adminFilter}
                </Col>
            </Row>
        <Row>
        
            { (isFetchingAllClientsCRM) && <Loader /> }
            { filteredClients.length > 0 && <ClientCRMTableWithParams clientsCRM={filteredClients} /> }
            {isNoResult && <Alert className="mt-3" variant="warning">
                          Aucun établissement ne correspond à votre recherche. Pour en créer un , <Button as="a" bsPrefix={'no-btn'} onClick={() => {openCreateClientModal()}}>cliquez ici</Button>
                </Alert>}
        </Row>
        {modalComponent}
    </Container>;
}

export default ClientCRMPage;