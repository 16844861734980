import React from "react";

import {useStoreActions, useStoreState} from 'easy-peasy';
import {  Col, Row } from "react-bootstrap";

import CreateGroupComponent from "../../components/groups/CreateGroupComponent";
import "./CreateGroupPage.scss";
import useBackButton from "../../hooks/useBackButton";

const CreateGroupPage = (props) => {
	const {postGroup, updateGroup} =  useStoreActions((actions) => actions.groups);
    const groupSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("groups"));

	const {backButtonComponent} = useBackButton({
        path: groupSlugs.readAll, 
        mediaType:"groups", 
        shouldReplaceHistory:true, 
    })

	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'un groupe</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <CreateGroupComponent 
					postGroup={postGroup} 
					updateGroup={updateGroup} 
					action="create"
					{...groupSlugs}
				/>
            }
		</div>
	);
};

export default CreateGroupPage;
