import { useStoreState } from 'easy-peasy';
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Alert } from 'react-bootstrap';

import axios from '../../../services/axios';
import { URLS } from '../../../services/axios';
import UserTableLogsComponent from '../usersList/UserTableLogsComponent ';
import useDebounceSearch from '../../../hooks/useDebounchSearch';
import Loader from "../../widgets/Loader";
import './UserDetailsLogs.scss';
import HelperMetiers360 from '../../../services/HelpersMetiers360';
import withURLParamsTableComponent from '../../../HOC/withURLParamsTableComponent';

const UserTableLogsComponentWithParams = withURLParamsTableComponent(UserTableLogsComponent);

const UserDetailsLogs = (props) => {
    const { user } = props;
    const [logs, setLogs] = useState([]);

    const { apiData } = useStoreState(state => state.actionSlugs);
    const getUrl = URLS.API_URL + apiData?.URLs?.getUserLogs?.url?.replace('id', user.id);

    const { searchInput, search } = useDebounceSearch({ placeholder: 'Rechercher par Appareil et Version', delay: 400 })
    const [isLoading, setIsLoading] = useState(true);
    const [filterLogs, setFilterLogs] = useState(null);

    useEffect(() => {
        axios.get(getUrl)
            .then(response => {
                setLogs(response);
                setFilterLogs(response);
            })
            .finally(() => setIsLoading(false));
    }, []);

    useEffect(() => {
        setFilterLogs(
            [...logs.filter(
                log => (!search && !log.device) 
                    || HelperMetiers360.isSearchInText(search, log.device)
                    || HelperMetiers360.isSearchInText(search, log.deviceVersion)
            )]
        );
    }, [search, logs]);

    return (
        <Container fluid className="user-details-logs">
            <Row>
                {isLoading && <Loader />}
                {!isLoading && logs.length > 0 && (
                    <>
                        <Col className="mx-3 my-3">
                            {logs.length > 1 && searchInput}
                        </Col>
                        {filterLogs.length === 0 && (
                            <Alert className="mt-3" variant="warning">
                                Aucun résultat pour la recherche "{search}"
                            </Alert>
                        )}
                        <UserTableLogsComponentWithParams users={filterLogs} />
                    </>
                )}
            </Row>
        </Container>
    );

}
export default UserDetailsLogs;