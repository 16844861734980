import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";

import { StoreProvider } from "easy-peasy";
import { store, WaitForStateRehydration } from "./store/index.js";

import App from './App';

import './index.scss';
import { ToastProvider } from './components/widgets/toast/ToastProvider.jsx';
import { useToast } from './hooks/useToast.jsx';

ReactDOM.render(
    <StoreProvider store={store}>
        <WaitForStateRehydration >
            <ToastProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ToastProvider>
        </WaitForStateRehydration>
    </StoreProvider>,
    document.getElementById('root')
);

export { ToastProvider, useToast };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
