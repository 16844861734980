import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import SelectTypeWidget from "../../widgets/SelectType/SelectTypeWidget.jsx";
import useCustomGetStoreState from '../../../hooks/useCustomGetStoreState.jsx';
import { useToast } from '../../../hooks/useToast.jsx';

const HeadsetFormCreationComponent = ({headset, subscription, setModalShow, setIsSending}) => {
    
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);   
    const {typeOfHeadset: allHeadsetsTypes } = BACKOFFICE_DEFAULT_VALUES;
	const {typeOfHeadsetOwnership: allHeadsetOwnershipsTypes } = BACKOFFICE_DEFAULT_VALUES;

	const {allHeadsets} = useCustomGetStoreState("headsets");
	const {createHeadset} = useStoreActions(actions => actions.headsets);
    const {csrfToken, formName} = ACTIONS['headsets']['create'];
    const toast = useToast();

    const { typeSelector: headsetTypeSelector } = SelectTypeWidget({
		elementType : "headsetTypeReference",
		id: "headset-type-select",
		allTypes: allHeadsetsTypes?.map((headset)=>{ return {...headset, value: headset.reference }}),
        label: 'Type de casques'
    });

	const { typeSelector: headsetOwnershipTypeSelector } = SelectTypeWidget({
		elementType : "headsetOwnershipType",
		id: "headset-ownership-select",
		allTypes: Object.entries(allHeadsetOwnershipsTypes).map((ownership)=>{ return {value: ownership[0], label: ownership[1] }}),
		defaultValue : null,
        label: 'Choisir le type de possession'
    });
 
	const submitHeadsetWithForce = () => {
		setIsSending(true);
		submitHeadsetForm({allHeadsets, createHeadset, forceToUpdate: true})
			.then(() => {
                toast.success();
				setModalShow(false);
			})
			.finally(() => setIsSending(false));
	}

	return <>
		<div >
			<Form method="post" encType="multipart/form-data" id="headset-form" className={formName}>
				
				<Row>
					<Form.Group as={Col}  >
						<Form.Label>Numéro de série du casque</Form.Label>
						<Form.Control 
							name={`headsetDeviceId`} 
							defaultValue={headset ? headset?.deviceId : ''}
						/>
						<Form.Control.Feedback type='invalid' name="feedback-name">Veuillez indiquer le numero de serie du casque</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
				<hr/>

                <Row>
					<Form.Group as={Col} >
						<Form.Label>Type de casque</Form.Label>
						{headsetTypeSelector}
						<Form.Control.Feedback type='invalid'>Veuillez indiquer le type de casquet</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<hr/>

                <Row>
					<Form.Group as={Col} >
						<Form.Label>Type de possession</Form.Label>
						{headsetOwnershipTypeSelector}
						<Form.Control.Feedback type='invalid'>Veuillez indiquer le type de possession de casquet</Form.Control.Feedback>
					</Form.Group>
				</Row>

				<Row>
				    <Form.Group >
					<Form.Control
						name={`headsetCsrfToken`}
						value={csrfToken}
						type="hidden"
					/>
				    </Form.Group>
				</Row>

                <Row>
	                <Form.Group >
						<Form.Control
							name={`headsetSubscriptionUniqueId`}
							value={subscription.uniqueId}
							type="hidden"
						/>
						<Form.Control.Feedback type='invalid'>Veuillez donner une catégorie au client</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
                <Row>
	                <Form.Group >
	                <Form.Control
						name={`subscriptionId`}
						value={subscription.uniqueId}
						type="hidden"
					/>
					</Form.Group>
				</Row>

				<div className="d-none" id="btn-force-update">
					<Button className='mt-3' variant='warning' onClick={submitHeadsetWithForce}>Forcer l'affectation à cet abonnement</Button>
				</div>
			</Form>
		</div>
	</>
}

export const submitHeadsetForm = ({allHeadsets, createHeadset, forceToUpdate}) => {
    let error = false;

	const listSelectedValues = (id) => {
		let valuesSelected = Array.prototype.slice.call(
			document.querySelectorAll(`#${id} option:checked`) ,0)
				.map(function(v,i,a) { 
					return v.value; 
				}
		);
		if(valuesSelected.length > 0 && valuesSelected.includes("") ){
			let emptyIndex = valuesSelected.findIndex((el)=>{
				return el === "";
			});
			valuesSelected.splice(emptyIndex, 1);
		}
		return valuesSelected;
	}

	const resetErrorDisplay = () => {
		document.getElementsByName("headsetDeviceId")[0]?.classList.remove("is-invalid");
		document.getElementById("headset-type-select")?.classList.remove("is-invalid");
		document.getElementById("headset-ownership-select").classList?.remove("is-invalid");
		document.getElementsByName("headsetDeviceId")[0].classList?.remove("is-invalid");
		document.getElementsByName(`feedback-name`)[0].innerHTML = "Veuillez indiquer le numero de serie du casque";
		document.getElementById("btn-force-update")?.classList.add("d-none");
		document.getElementById("btn-force-update")?.classList.remove("d-flex");
		document.getElementById("btn-force-update")?.classList.remove("justify-content-center");
	}

	let formData = new FormData(document.getElementById('headset-form'));
	let headsetDeviceId = formData.get(`headsetDeviceId`);
	const listHeadsetTypeReference = listSelectedValues('headset-type-select');
	const listHeadsetOwnershipType = listSelectedValues('headset-ownership-select');

	resetErrorDisplay();

	if (!headsetDeviceId || headsetDeviceId == "") {
		document.getElementsByName("headsetDeviceId")[0].classList.add("is-invalid");
		error = true;
	}
	if (listHeadsetTypeReference.length === 0 || listHeadsetTypeReference[0] == "all") {
		document.getElementById("headset-type-select").classList.add("is-invalid");
		error = true;
	}
	if (listHeadsetOwnershipType.length === 0 || listHeadsetOwnershipType[0] == "all") {
		document.getElementById("headset-ownership-select").classList.add("is-invalid");
		error = true;
	}

	if(!error) {
		formData.append(`headsetTypeReference`, listHeadsetTypeReference[0]);
		formData.append(`headsetOwnershipType`, listHeadsetOwnershipType[0]);

		if(!forceToUpdate) {
			const existingHeadset = allHeadsets?.find((headset)=>{
				return headset?.deviceId?.toUpperCase() == headsetDeviceId.toUpperCase() 
			});
		
			if(existingHeadset !== undefined){
				document.getElementsByName("headsetDeviceId")[0].classList.add("is-invalid");
				document.getElementsByName(`feedback-name`)[0].innerHTML = "Ce numero existe déjà dans l'abonnement"
					+ (existingHeadset.subscriptionName !== null ? existingHeadset.subscriptionName : "") 
					+ " du client " + existingHeadset.clientName
					+ ". Si vous voulez l'affecter à l'abonnement actuel, cliquez sur le bouton qui vient d'apparaître.";
				document.getElementById("btn-force-update").classList.remove("d-none");
				document.getElementById("btn-force-update").classList.add("d-flex");
				document.getElementById("btn-force-update").classList.add("justify-content-center");
				error = true;
			}
		}
	}

	if (error) {
		return Promise.reject(new Error());
	} else {
		return createHeadset(formData)
			.catch(() => {
				return new Error();
			});
	}
}

export default HeadsetFormCreationComponent;