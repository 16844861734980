import React, { useEffect, useState } from "react";
import { Alert, Container, Row } from "react-bootstrap";
import OrdersFilters from "../../../components/orders/OrdersFilters/OrdersFilters";
import Loader from "../../../components/widgets/Loader";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import ReturnOrdersTableComponent from "../../../components/orders/returnOrders/ReturnOrdersTableComponent";
import { useStoreActions, useStoreState } from "easy-peasy";
import AuthorizationChecker from "../../../services/AuthorizationChecker";
import withURLParamsTableComponent from "../../../HOC/withURLParamsTableComponent";

const ReturnOrdersTableComponentWithParams = withURLParamsTableComponent(ReturnOrdersTableComponent);

const ReturnOrdersList = () => {
    const { allReturnOrders, isFetchingAllReturnOrders } = useCustomGetStoreState('orders');
    const { fetchReturnOrders } = useStoreActions(actions => actions.orders);
    const { fetchAllPropsForAllClients } = useStoreActions(actions => actions.clients);
    const { allClients } = useCustomGetStoreState('clients');
    const { allClientsCRM } = useStoreState(state => state.clients);
    const { allReturnOrderStatus } = useStoreState(state => state.orders);
    const { fetchReturnOrderStatus } = useStoreActions(actions => actions.orders);

    const [enrichedOrders, setEnrichedOrders] = useState(allReturnOrders);
    const [filteredOrders, setFilteredOrders] = useState(allReturnOrders);

    useEffect(() => {
        fetchReturnOrders();

        // Get props of clients to display tagOpe, contactM360 on table
        fetchAllPropsForAllClients({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
        allReturnOrderStatus.length == 0 && fetchReturnOrderStatus();
    }, []);

    useEffect(() => {
        if (allClients?.length > 0) {
            setEnrichedOrders(allReturnOrders.map(order => {
                const client = allClients.find(client => client.uniqueId === order.clientUniqueId);
                const clientCRM = allClientsCRM.find(client => client.uniqueId === order.clientUniqueId);
                return {...order, 
                    clientName: client?.clientName, 
                    tagOperations: clientCRM?.tagOperations,
                    contactM360Name: client?.contactsM360
                        ?.filter(contactM360 => contactM360.relationValue === "sav")
                        ?.map(contactM360 => contactM360.name)
                        ?.join(", ")
                }}));
        }
    },[allClients, allReturnOrders])

    return <Container fluid>
        <Row className="mb-2 mt-4">
            <OrdersFilters allOrders={enrichedOrders} setFilteredOrders={setFilteredOrders}
                excludedStatus='clôturés' allStatus={allReturnOrderStatus} />
        </Row>
        <Row>
            {isFetchingAllReturnOrders 
                ? <Loader />
                : allReturnOrders.length > 0 
                    && filteredOrders.length === 0
                        ? <Alert variant="warning">Aucun bon de retour</Alert>
                        : <ReturnOrdersTableComponentWithParams orders={filteredOrders} enableToControlVisibleColumns={true} />
            }
        </Row>
    </Container>
}

export default ReturnOrdersList;