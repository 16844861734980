import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

import { Row, Col, Container, Alert, Button } from "react-bootstrap";

import { useNavigate, Link, useParams } from "react-router-dom";
import Loader from "../widgets/Loader";
import useBackButton from "../../hooks/useBackButton";
import HelperMetiers360 from "../../services/HelpersMetiers360";
import WelcomeBannerComponent from "./WelcomeBannerComponent";

import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast.jsx";

import "./WelcomeMessageDetailPage.scss";

const WelcomeMessageDetailPage = () => {

    const { uniqueId } = useParams();

    const { messageById } = useStoreState(state => state.welcomeMessage);
    const { fetchMessageById, deleteMessage, editValidated, deactivateMessage } = useStoreActions(actions => actions.welcomeMessage);

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();

    const messageSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("welcomeMessage"));

    const [isLoading, setIsLoading] = useState(false);
    const [isMounted, setIsMounted] = useState(false);

    const navigate = useNavigate();

    const { backButtonComponent } = useBackButton({
        path: messageSlugs.readAll,
        mediaType: "welcomeMessage",
        shouldReplaceHistory: true,
    });

    useEffect(() => {
        setIsMounted(true);
        if (!isLoading) {
            setIsLoading(true);
            fetchMessageById(uniqueId)
                .then(() => {
                    setIsLoading(false);
                })
        }
        return () => setIsMounted(false);
    }, []);


    const deleteMessageModal = () => {
        setModalData({
            ...modalData,
            header: <>Suppression d'un bon message d'accueil</>,
            content: <h5>Etes-vous sûr⋅e de vouloir supprimer le message {messageById.title} ?</h5>,
            resetButton: 'Supprimer',
            cancelButton: 'Annuler',
            onReset: () => {
                setIsSending(true);
                deleteMessage({ uniqueId: messageById.uniqueId })
                    .then(() => {
                        setIsSending(false);
                        setModalShow(false);
                        navigate(messageSlugs.readAll);
                    })
                    .catch(() => {
                        setIsSending(false);
                        setModalShow(false);
                    });
            }
        });
        setModalShow(true);
    };

    const validateMessageModal = () => {
        setModalData({
            ...modalData,
            header: <>Valider le message : {messageById.title}</>,
            content: <h5><Alert variant="danger">Attention, ce message sera visible pour tous les utilisateurs du {HelperMetiers360.getdisplayDateType(messageById?.startDate?.date, 'day')} au {HelperMetiers360.getdisplayDateType(messageById?.expirationDate?.date, 'day')} inclus</Alert></h5>,
            validateButton: 'Valider',
            cancelButton: 'Annuler',
            onValidate: () => {
                setIsSending(true);
                editValidated({ uniqueId: messageById.uniqueId, validated: true })
                    .then((data) => {
                        toast.open({message: "Message validé", variant: "success"});
                    })
                    .finally(() => {
                        setIsSending(false);
                        setModalShow(false);
                    });
            }
        });
        setModalShow(true);
    };

    const unvalidateMessageModal = () => {
        setModalData({
            ...modalData,
            header: <>Invalider le message : {messageById.title}</>,
            content: <h5><Alert variant="info">Attention, ce message repassera en brouillon et ne sera plus affiché aux dates prévues</Alert></h5>,
            resetButton: 'Invalider',
            cancelButton: 'Annuler',
            onReset: () => {
                setIsSending(true);
                editValidated({ uniqueId: messageById.uniqueId, validated: false })
                    .then((data) => {
                        toast.open({message: "Message invalidé", variant: "success"});
                    })
                    .finally(() => {
                        setIsSending(false);
                        setModalShow(false);
                    });
            }
        });
        setModalShow(true);
    };

    const deactivateMessageModal = () => {
        setModalData({
            ...modalData,
            header: <>Désactiver le message : {messageById.title}</>,
            content: <h5><Alert variant="danger">Attention, ce message deviendra immédiatement invisible pour tous les utilisateurs</Alert></h5>,
            resetButton: 'Désactiver',
            cancelButton: 'Annuler',
            onReset: () => {
                setIsSending(true);
                deactivateMessage({ uniqueId: messageById.uniqueId })
                    .then((data) => {
                        toast.open({message: "Message désactivé", variant: "success"});
                    })
                    .finally(() => {
                        setIsSending(false);
                        setModalShow(false);
                    });
            }
        });
        setModalShow(true);
    };

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const startDateString = messageById?.startDate?.date;
    const startDate = startDateString ? new Date(startDateString) : null;

    const endDateString = messageById?.expirationDate?.date;
    const endDate = endDateString ? new Date(endDateString) : null;

    const actionsButton = !messageById?.isValidated ?
        <>
            <Button variant="danger" onClick={deleteMessageModal}><i className="fas fa-trash-alt  me-2"></i>Supprimer</Button>
            <Button variant="secondary" as={Link} to={messageSlugs?.update?.replace(':uniqueId', messageById?.uniqueId)}><i className="fas fa-edit"></i> &nbsp;Éditer</Button>
            <Button variant="success" onClick={validateMessageModal}>Valider</Button>
        </>
        : (!messageById.hasBeenDeactivated && startDate && startDate.toISOString() <= today.toISOString()) && (endDate && endDate.toISOString() >= today.toISOString()) ?
            <Button variant="danger" onClick={deactivateMessageModal}>Désactiver</Button>
            : (messageById.hasBeenDeactivated || (endDate && endDate.toISOString() <= today.toISOString())) ?
                null
                : <Button variant="danger" onClick={unvalidateMessageModal}>Invalider</Button>;


    return (
        <Container fluid>
            <Row>
                <Col>
                    <h2 className="d-flex justify-content-between">
                        Détail du message : {messageById?.title}
                        {backButtonComponent}
                    </h2>
                </Col>
            </Row>
            <Row className="d-flex flex-column">
                {
                    (!messageById || isLoading || !isMounted) ?
                        <Loader />
                        : <>
                            <Alert variant="info">Message {!actionsButton ? "ayant été" : "pouvant être"} affiché du {HelperMetiers360.getdisplayDateType(messageById?.startDate?.date, 'day')} au {HelperMetiers360.getdisplayDateType(messageById?.expirationDate?.date, 'day')}</Alert>
                            <label>Aperçu:</label>
                            < WelcomeBannerComponent message={messageById} canClose={false} preview={true} />
                            <div className="actionsButtons">
                                {actionsButton}
                            </div>
                        </>
                }
            </Row>
            {modalComponent}
        </Container>

    )
}

export default WelcomeMessageDetailPage;