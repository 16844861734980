
import React from "react";


import HelperMetiers360 from "../../../../services/HelpersMetiers360";


import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";

import AuthorizationChecker from '../../../../services/AuthorizationChecker.js';


const FormationSessionsByUserTableComponent = (props) => {
    const {formationSessionsList, withParams} = props;
   
    const formationSessionsToDisplay = formationSessionsList ? formationSessionsList : [];


    if (formationSessionsToDisplay?.length === 0) { return <></>; }

    const sortType = formationSessionsToDisplay ?
    [   {
         value: 'formation', label: 'Formation',
         test: formationSessionsToDisplay[0]?.formation?.name !== undefined,
         method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.formation?.name, b.formation?.name),
         display: (formationSession) => formationSession?.formation?.name
        },
        {
        value: 'startsAt', label: 'Session',
        test: formationSessionsToDisplay[0]?.startsAt !== undefined,
        method: (a, b) => new Date(b?.startsAt) - new Date(a?.startsAt),
        display: (formationSession) => formationSession.startsAt == null ? 
        '-' 
        : HelperMetiers360.getdisplayDateType(formationSession.startsAt)
        },
        {
        value: 'participated', label: 'A participé',
        test: AuthorizationChecker.isAdmin() && formationSessionsToDisplay[0]?.participated !== undefined,
        method: (a, b) =>  a?.participated - b?.participated,
        display: (formationSession) => formationSession.participated ? "Oui" : "Non"
        }
      ]:null;

  return (
            <DynamicTable 
                contentTable = {formationSessionsToDisplay}
                contentSort = {sortType}
                valueInitSort = "formation"
                index = 'uniqueId'
            className="table-formation"
                withParams={withParams}
                filename="inscriptions_utilisateur_webinaire"
          />
            )
}

export default FormationSessionsByUserTableComponent