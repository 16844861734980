import { useStoreState } from 'easy-peasy';
import React, {useState, useRef, useEffect} from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Editor } from "@tinymce/tinymce-react";
import HelperMetiers360 from '../../../../services/HelpersMetiers360';



const FormationSessionFormComponent = ({formationSession, fileContraints, editorContentRefDescription}) => {

    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);

	const pdfFileInput = useRef(null);
    const [pdfFileInputName, setPdfFileInputName] = useState(formationSession?.pdfFile ? 'Changer le fichier' : 'Sélectionnez un fichier');

    const {csrfToken, formName} = ACTIONS['formations']['editProps']['formationSessions'][formationSession ? 'update': 'create'];

    const handleEditorChangeDescription = (content, editor) => {
        editorContentRefDescription.current = content;
    }

	return (
		<div >
			<Form method="post" encType="multipart/form-data" id="formation-session-form" className={formName}>
				<Row>
					<Form.Group as={Col}  >
						<Form.Label>Lien vers la visio</Form.Label>
						<Form.Control 
							name={`${formName}[link]`} 
							defaultValue={formationSession ? formationSession?.link : ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer un lien valide</Form.Control.Feedback>
					</Form.Group>
				</Row>
                
				<hr/>

				<Row>
                    <Col xs={12} md={4}></Col>
                    <Form.Group as={Col} xs={12} md={4}  >
					    <Form.Label>Nombre maximum de participants</Form.Label>
						<Form.Control 
							name={`${formName}[maxParticipants]`} 
							type="number" 
							defaultValue={formationSession?.maxParticipants ? formationSession.maxParticipants : ''}
						/>
					</Form.Group>
					<Col xs={12} md={4}></Col>
				</Row>

                <hr/>

                <Row>
					<Form.Group as={Col} sm={12} md={4} >
						<Form.Label>Date</Form.Label>
						<Form.Control
							name={`${formName}[date]`}
							type="date"
							defaultValue={formationSession?.startTime ? 
								HelperMetiers360.formatDateForFormControl(formationSession.startTime,'date') 
								: ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer  la date  de la session (ne peut pas être avant aujourd'hui)</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} sm={12} md={4} >
						<Form.Label>Heure de début</Form.Label>
						<Form.Control
							name={`${formName}[startTime]`}
							type="time"
							defaultValue={formationSession?.startTime ? 
								HelperMetiers360.formatDateForFormControl(formationSession.startTime,'time') 
								: ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer l'heure de debut de la session (ne peut pas être avant maintenant)</Form.Control.Feedback>
					</Form.Group>

					<Form.Group as={Col} sm={12} md={4} >
						<Form.Label>Heure de fin</Form.Label>
						<Form.Control
							name={`${formName}[endTime]`}
							type="time"
							defaultValue={formationSession?.endTime ? 
								HelperMetiers360.formatDateForFormControl(formationSession.endTime,'time') 
								: ''}
						/>
                        <Form.Control.Feedback type='invalid'>Veuillez indiquer l'heure de fin de la session (ne peut pas être avant l'heure de début</Form.Control.Feedback>
					</Form.Group>
				</Row>
               
                <hr/>
				
				<Row>
					<Row>
					   <Form.Group as={Col} sm={12} md={12}  >
				        <Form.Label>Description</Form.Label>
						<Editor
                            name={`${formName}[description]`}
                            apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
								'searchreplace', 'visualblocks', 'code', 'fullscreen',
								'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                                ],
                                toolbar:
                                'undo redo | fontsize blocks | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent | charmap emoticons | link | removeformat | help | media'
                            }}
                            onEditorChange={handleEditorChangeDescription}
                            initialValue={formationSession?.description ?? ""}
                        />
			            </Form.Group>
                    </Row>
				</Row>

				<hr/>

				<Row>
					<InputGroup>
					<InputGroup.Text>Fichier ({fileContraints.TEXT_FILE_MIME_TYPES_NAMES?.join(',')}) ({fileContraints.MAX_SIZE_TEXT_FILE_MO} Mo max.)</InputGroup.Text>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        name={`${formName}[pdfFile]`}
                                        className="custom-file-input"
                                        id="inputSessionFile01"
                                        accept={fileContraints.TEXT_FILE_MIME_TYPES?.join(',')}
                                        ref={pdfFileInput}
                                        onChange={() => setPdfFileInputName(pdfFileInput?.current?.files[0]?.name)} />

                                    <label className="custom-file-label" htmlFor="inputSessionFile01" data-browse="Parcourir">
                                        {pdfFileInputName}
                                    </label>
                                </div>
						<Form.Control type="hidden" name="pdfFile"/>   
                        <Form.Control.Feedback type='invalid' name='feedback-file'></Form.Control.Feedback>
						</InputGroup>
                    </Row>

                <hr/>

				<Form.Group >
					<Form.Control
						name="formationSessionUniqueId"
						defaultValue={formationSession ? formationSession?.uniqueId : ''}
						type="hidden"
					/>
				</Form.Group>

				<Form.Group >
					<Form.Control
						name={`${formName}[_token]`}
						value={csrfToken}
						type="hidden"
					/>
				</Form.Group>
			</Form>
		</div>
	);
}

export const submitSessionForm = ({formName, formation, formationSession, fileContraints, formationSessionPersistAction,
	editorContentRefDescription}) => {
    let error = false;

	const resetErrorDisplay = () => {		
		document.getElementsByName(`${formName}[date]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName(`${formName}[startTime]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName(`${formName}[endTime]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName(`${formName}[link]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName(`${formName}[pdfFile]`)[0]?.classList.remove("is-invalid");
		document.getElementsByName("pdfFile")[0]?.classList.remove("is-invalid");
	}

	const submitForm = (formData) => {
        formData.append(`${formName}[description]`, editorContentRefDescription?.current ?? "");
		let startsAt = formData.get(`${formName}[date]`) + " " + formData.get(`${formName}[startTime]`);
		let endsAt = formData.get(`${formName}[date]`) + " " + formData.get(`${formName}[endTime]`);
		formData.delete(`${formName}[date]`);
		formData.delete(`${formName}[startTime]`);
		formData.delete(`${formName}[endTime]`);
		formData.append(`${formName}[startsAt]`, new Date(startsAt).getTime()/1000);
		formData.append(`${formName}[endsAt]`, new Date(endsAt).getTime()/1000);
        let options;

		if (formationSession)  {
            options = {
				formationSessionUniqueId: formationSession?.uniqueId,
				formData,
			}
        } else {
			options = {
				formationUniqueId: formation.uniqueId,
				formData,
			}
		}
        
		return formationSessionPersistAction(options)
			.catch(() => {
				return 'Il y a eu un probleme lors de l\'enregistrement de la session';
			})
	}

	let formData = new FormData(document.getElementById('formation-session-form'));
	let pdfFile = formData.get(`${formName}[pdfFile]`);
	let isBeforeToday = false;
	let isBeforeNow = false;
	let isInversedTime = false;
	let startDate = new Date(formData.get(`${formName}[date]`));
	let startDateTime = new Date(formData.get(`${formName}[date]`) + " " + formData.get(`${formName}[startTime]`));
	let endDateTime = new Date(formData.get(`${formName}[date]`) + " " + formData.get(`${formName}[endTime]`));

	resetErrorDisplay();

	if(startDate <  new Date().setHours(0,0,0,0)){
		isBeforeToday = true
	}
	if(startDateTime < new Date()){
		isBeforeNow = true
	}
	if(startDateTime > endDateTime ){
		isInversedTime = true;
	}
	
	if (!formData.get(`${formName}[date]`) || isBeforeToday) {
		document.getElementsByName(`${formName}[date]`)[0].classList.add("is-invalid");
		error = true;
	}
	if ((!formData.get(`${formName}[startTime]`) || isBeforeNow) && !isBeforeToday) {
		document.getElementsByName(`${formName}[startTime]`)[0].classList.add("is-invalid");
		error = true;
	}
	if (!formData.get(`${formName}[endTime]`) || isInversedTime) {
		document.getElementsByName(`${formName}[endTime]`)[0].classList.add("is-invalid");
		error = true;
	}
	if ((!formData.get(`${formName}[link]`)?.match(/\b(https?:\/\/.*?\.[a-z]{2,4}\/[^\s]*\b)/g))) {
		document.getElementsByName(`${formName}[link]`)[0].classList.add("is-invalid");
		error = true;
	}

	if (pdfFile?.size) {
		if (pdfFile.size > fileContraints.MAX_SIZE_TEXT_FILE_BYTES ) {
			document.getElementsByName(`${formName}[pdfFile]`)[0].classList.add("is-invalid");
			document.getElementsByName("pdfFile")[0].classList.add("is-invalid");
			document.getElementsByName(`feedback-file`)[0].innerHTML = "Fichier trop volumineux";
			error = true;
		}
		if (!fileContraints.TEXT_FILE_MIME_TYPES.includes(pdfFile.type) ) {
			document.getElementsByName(`${formName}[pdfFile]`)[0].classList.add("is-invalid");
			document.getElementsByName("pdfFile")[0].classList.add("is-invalid");
			document.getElementsByName(`feedback-file`)[0].innerHTML = "Format du fichier non autorisé";
			error = true;
		}
	}

	if (error) {
		return Promise.reject(new Error());
	} else {
		return submitForm(formData)
	}
}

export default FormationSessionFormComponent;