import { action, actionOn, thunk } from "easy-peasy";

import axios from "../services/axios";

const authInitialState = {
    isLogged: false,
    username: null,
    userUniqueId: false,
    userClientName: null,
    roles: [],
    client: null,
    shouldCheckAuth: true,
    isLogging: false,
    sessionExpired: false,
    isTester:null,
}

const auth = {
    ...authInitialState,

    // listener that will listen for an action to execute something (change state for example)
    // here listening to the performReset called in resetStore, if so, we put our store back in default value
    onGlobalResetCalled: actionOn(
        // target resolver: we store in an array a list of action we will be listening for
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        // handler: if we are listening for multiple type of actions, we can execute logic depending on the type
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    isLogged: state.isLogged,
                    username: state.username,
                    userUniqueId: state.userUniqueId,
                    userClientName: state.userClientName,
                    roles: state.roles,
                    client: state.client,
                    shouldCheckAuth: state.shouldCheckAuth,
                    isLogging: state.isLogging,
                    sessionExpired: state.sessionExpired
                } = authInitialState);
            }
        }
    ),

    setIsLogging: action((state, payload) => {
        state.isLogging = payload;
    }),

    setShouldCheckAuth: action((state, payload) => {
        state.shouldCheckAuth = payload;
    }),

    setSessionExpired: action((state, payload) => {
        state.sessionExpired = payload;
    }),

    setUsername: action((state, payload) => {
        state.username = payload;
    }),

    setUserUniqueId: action((state, payload) => {
        state.userUniqueId = payload;
    }),

    setRoles: action((state, payload) => {
        state.roles = payload;
    }),

    setClient: action((state, payload) => {
        state.client = payload;
    }),

    setUserClientName: action((state, payload) => {
        state.userClientName = payload;
    }),

    setIsLogged: action((state, payload) => {
        state.isLogged = payload;
    }),

    setIsTester: action((state, payload) => {
        state.isTester = payload;
    }),

    setUserInfos: thunk((actions, payload, helpers) => {
        actions.setUsername(payload.username);
        actions.setUserUniqueId(payload.userUniqueId);
        actions.setRoles(payload.roles);
        actions.setClient(payload.client);
        actions.setUserClientName(payload.clientName);
    }),

    login: thunk((actions, payload, helpers) => {
        const API_LOGIN = helpers.getStoreState().params.apiRoutes.login;
        return axios.post(API_LOGIN, payload)
            .then(({ username, userUniqueId, roles, client, clientName }) => {
                actions.setUserInfos({ username, userUniqueId, roles, client, clientName });
                actions.setIsLogged(true);
            })
    }),

    logout: thunk((actions, payload, helpers) => {
        const API_LOGOUT = helpers.getStoreState().params.apiRoutes.logout;

        return axios.post(API_LOGOUT).catch(e => { })
            .finally(() => {
                helpers.getStoreActions().resetStore.performReset();
            })
    }),

    changePassword: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const changePwdUrl = ACTIONS?.password?.update?.url;

        return axios.post(changePwdUrl, payload);
    }),

    getAuthStatus: thunk((actions, payload, helpers) => {
        const API_IS_AUTH = helpers.getStoreState().params.apiRoutes.isAuth;
        if (!payload?.checkAtInterval) {
            actions.setIsLogging(true);
        }
        return axios.get(API_IS_AUTH)
            .then(({ username, userUniqueId, roles, client, clientName }) => {
                actions.setUserInfos({ username, userUniqueId, roles, client, clientName });
                actions.setIsLogged(true);
            })
            .catch((err) => {
                if (helpers.getState().isLogged) {
                    helpers.getStoreActions().resetStore.performReset();
                    actions.setSessionExpired(true);
                }
                return Promise.reject(err)
            })
            .finally(() => {
                if (!payload?.checkAtInterval) {
                    actions.setIsLogging(authInitialState.isLogging)
                    actions.setShouldCheckAuth(false)
                }
            })
    }),
    getIsTester: thunk((actions, payload, helpers) => {
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;
        const clientUniqueId = helpers.getState().client;

        const isTesterUrl = ACTIONS?.clients?.getProps?.getIsTester?.url?.replace('uniqueId', clientUniqueId);

        if(isTesterUrl) {
            return axios.get(isTesterUrl)
                .then((response) => {
                    actions.setIsTester(response?.isTester)});
        }
        return Promise.reject(new Error('tester url unknown'));
    }),

}

export default auth;