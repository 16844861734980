import React from 'react';

import {Card} from 'react-bootstrap';

import './MemberCardComponent.scss';


const MemberCard = ({memberProp}) => {

    return (
        <div className="member-card d-flex">
            <Card className="align-self-stretch">
                    <Card.Img src={memberProp.imgLink} />
                <Card.Body>
                    <Card.Title className="card-content">{memberProp.name}</Card.Title>
                    
                    <Card.Text className="card-content">
                        {memberProp.role}
                    </Card.Text>

                    <Card.Text className="card-content">
                        {memberProp.mail}
                    </Card.Text>

                    <Card.Text className="card-content">
                        {memberProp.phone}
                    </Card.Text>
                    
                </Card.Body>
            </Card>
        </div>
    );
}

export default MemberCard;