import React from 'react';

import { useNavigate } from 'react-router';

import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import DynamicTable from '../widgets/dynamicTable/DynamicTable'
import HelperMetiers360 from '../../services/HelpersMetiers360';
import TooltipListComponent from '../widgets/tooltipList/TooltipListComponent';
import AuthorizationChecker from '../../services/AuthorizationChecker';

const GroupsTableComponent = ({groups, withParams, groupsSlugs, enableToControlVisibleColumns=false}) => {
    
    const navigate = useNavigate();

    const groupsToDisplay = groups ? groups : [];  

    const getVideosContained = (group) => {
        if (group.contents?.length) {
            return group.contents.filter((c)=>c.type === 'video');
        } else {
            return [];
        }
    }

    const getQuizzesContained = (group) => {
        if (group.contents?.length) {
            return group.contents.filter((c)=>c.type === 'quiz');
        } else {
            return [];
        }
    }

    const getGroupsContained = (group) => {
        if (group.contents?.length) {
            return group.contents.filter((c)=>c.type === 'group');
        } else {
            return [];
        }
    }

    if (groups.length === 0 )
    {return <></>;}

    const sortType = [
        {
            value: 'thumbnail', label: 'Vignette',
            test: groupsToDisplay[0].links.thumbnail !== undefined,
            method: null,
            display: (group) => (
                <OverlayTrigger
                    placement="right"
                    overlay={
                        <Tooltip>
                            <Image src={group.links.thumbnail}/>
                        </Tooltip>
                    }    
                >
                    <Image src={group.links.thumbnail} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay : (group) => group.links.thumbnail
        },
        {
            value: 'name', label: "Nom",
            test : groupsToDisplay[0].name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.name, b.name),
            display: (group) => group.name
        },
        {
            value: 'idHeadset', label: "idHeadset",
            test : groupsToDisplay[0].idHeadset !== undefined,
            method: (a, b) => a.idHeadset - b.idHeadset,
            display: (group) => group.idHeadset
        },
        {
            value: 'videoContent', label: "Vidéos",
            test : groupsToDisplay[0].name !== undefined,
            method: (a, b) => {
                const aLength = getVideosContained(a).length || 0;
                const bLength = getVideosContained(b).length || 0;

                return aLength - bLength;
            }, 
            display: (group) => {
                const videoList = getVideosContained(group);
                if (!videoList.length) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `group-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={videoList}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />

            },
            flatDisplay: (group) => getVideosContained(group).length
        },
        {
            value: 'quizContent', label: "Quiz",
            test : groupsToDisplay[0].name !== undefined,
            method: (a, b) => {
                const aLength = getQuizzesContained(a).length || 0;
                const bLength = getQuizzesContained(b).length || 0;

                return aLength - bLength;
            }, 
            display: (group) => {
                const quizList = getQuizzesContained(group);
                if (!quizList.length) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `quiz-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={quizList}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (group) => getQuizzesContained(group).length
        },
        {
            value: 'GroupContent', label: "Groupes",
            test : groupsToDisplay[0].name !== undefined,
            method: (a, b) => {
                const aLength = getGroupsContained(a).length || 0;
                const bLength = getGroupsContained(b).length || 0;

                return aLength - bLength;
            }, 
            display: (group) => {
                const groupList = getGroupsContained(group);
                if (!groupList.length) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `group-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={groupList}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (group) => getGroupsContained(group).length
        },
        {
            value: 'parentGroups', label: "Parents",
            test : groupsToDisplay[0].name !== undefined,
            method: (a, b) => {
                const aLength = a.groups ? a.groups.length : 0;
                const bLength = b.groups ? b.groups.length : 0;

                return aLength - bLength;
            }, 
            display: (group) => {
                
                if (!group.groups || !group.groups.length) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `parent-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={group.groups}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (group) => group?.groups?.length
        },
        {
            value: 'pedagogicModule', label: "Modules",
            test : groupsToDisplay[0].pedagogicModules !== undefined,
            method: (a, b) => {
                const aLength = a.pedagogicModules ? a.pedagogicModules.length : 0;
                const bLength = b.pedagogicModules ? b.pedagogicModules.length : 0;

                return aLength - bLength;
            }, 
            display: (group) => {
                
                if (!group.pedagogicModules || !group.pedagogicModules.length) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `pedagogicModule-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={group.pedagogicModules}
                    keyToDisplay='name'
                    generateIdCallback={generateIdCallback}
                 />
            },
            flatDisplay: (group) => group?.pedagogicModules?.length
        },
        {
            value: 'fileContent', label: "Fichiers",
            test : groupsToDisplay[0].files !== undefined,
            method: (a, b) => {
                const aLength = a.files.length || 0;
                const bLength = b.files.length || 0;

                return aLength - bLength;
            }, 
            display: (group) => {
                if (!group.files ||!group.files.length) {
                    return 0
                }

                const generateIdCallback = (uniqueId) => {
                    return `file-${uniqueId}`;
                }

                return <TooltipListComponent
                    inputList={group.files}
                    keyToDisplay='localName'
                    generateIdCallback={generateIdCallback}
                 />

            },
            flatDisplay: (group) => group.files.length
        },
        {
            value: 'position', label: "Position",
            test : groups.some(g => g.position),
            method: (a,b) => {
                const posA = a.position ?? 0;
                const posB = b.position ?? 0;
                return posA - posB;
            },
            display: (group) => group.position ? group.position : '-'
        },
        {
            value: 'private', label: "Privée",
            test : groupsToDisplay[0].private !== undefined,
            method: (a, b) => {const aPrivate = a.private ? 'X':'-'; return aPrivate.localeCompare(b.private ? 'X':'-');},
            display: (group) => group.private ? 'X' : '-'
        },
        {
            value: 'producers', label: "Producteur",
            test : !!groupsToDisplay[0].producers,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.producersName, b.producersName),
            display: (group) => group.producersName ? group.producersName : '-'
        },
        {
            value: 'date', label: "Publication",
            test : groupsToDisplay[0].releaseDate !== undefined,
            method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate),
            display: (group) => HelperMetiers360.getdisplayDateType(group.releaseDate, 'day')
        },
        {
            value: 'updatedAt', label: "Mise à jour",
            test : true,//groupsToDisplay[0].lastUpdated != undefined,
            method: (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
            display: (group) => HelperMetiers360.getdisplayDateType(group.updatedAt, 'day')
        },
        {
            value: 'directAccessCode', label: "Code d'accès",
            test : HelperMetiers360.isArrayContainsValue(groupsToDisplay, "directAccessCode"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.isDirectlyAccessible ? a.directAccessCode : "", b.isDirectlyAccessible ? b.directAccessCode : ""),
            display: (group) => group.isDirectlyAccessible && group.directAccessCode
        },
    ];

    const onSelectGroup = (uniqueID) => {
         // eslint-disable-next-line no-unused-expressions
         groupsSlugs.readOne
            ? navigate(groupsSlugs.readOne.replace(':uniqueId', uniqueID))
            : null;
    }

    return <DynamicTable 
        contentTable = {groupsToDisplay}
        contentSort = {sortType}
        valueInitSort = "idHeadset"
        index = 'uniqueId'
        handleClick = {onSelectGroup}
        withParams={withParams}
        enableToControlVisibleColumns={enableToControlVisibleColumns && AuthorizationChecker.isAdmin()}
        tableName="groupsTable"
        filename="groupes"
    />;
}

export default GroupsTableComponent;