import React, {useState} from "react";
import { Row, Col, Form } from 'react-bootstrap';
import { useStoreState } from "easy-peasy";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import SelectMultipleItemsWithSearchBar from "../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar";
import FormProfessionnalComponent from "../professionnals/formProfessionnal/FormProfessionnalComponent";
import AuthorizationChecker from "../../services/AuthorizationChecker";
import PropTypes from 'prop-types';
import { Editor } from "@tinymce/tinymce-react";

const VideoRomeComponent = (props) => {
    const {video, editorContentRef} = props;
    const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);
    const { allRomes } = useCustomGetStoreState('romes');
    const [selectedRomes, setSelectedRomes] = useState(video?.romes ? video.romes.map(String) : []);
    const [secondaryRomes, setSecondaryRomes] = useState([]);
    const [visible,setVisible] = useState(false);

    const token = ACTIONS['videos']['editRome'].csrfToken;

    if(!AuthorizationChecker.hasUpdateRights('videos')) return null;

    return <Form id="video_rome_form"> 
    <Form.Control name="csrf_token" defaultValue={token} type="hidden" />
    <Row>
        <Col>
            <Form.Label>Rappel de la description vidéo</Form.Label>
            <Editor
                name="description"
                initialValue={video?.description ?? ''}
                apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                init={{
                height: 500,
                menubar: false,
                plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                    'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                ],
                toolbar:
                    'undo redo | fontsize blocks | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | charmap emoticons | link | removeformat | help'
                }}
                onEditorChange = {(content, editor) => editorContentRef.current = content}
            />
        </Col>
        <Col>
            <Form.Group className="formSection">	
                <SelectMultipleItemsWithSearchBar idSelector="video_rome[]" label="Codes ROME"
                    allItems={allRomes} 
                    selectedItemKeys={selectedRomes} setSelectedItemKeys={setSelectedRomes}
                    itemKey="codeRome" itemValue="codeRome" itemValueDescription="label" />
            </Form.Group>
            <div className="d-flex justify-content-center me-3 clickable" onClick={() => setVisible(!visible)}>
                <u className="mb-2"> Ajouter des codes ROME complémentaires pour les autres métiers</u>
            </div>
            <div className={!visible ? "d-none" : null}>
             <SelectMultipleItemsWithSearchBar idSelector="secondary_rome" label=""
                    allItems={allRomes} 
                    selectedItemKeys={secondaryRomes} setSelectedItemKeys={setSecondaryRomes}
                    itemKey="codeRome" itemValue="codeRome" itemValueDescription="label" />
            </div>
        </Col>
    </Row>
    <FormProfessionnalComponent selectedRomes = {selectedRomes} secondaryRomes = {secondaryRomes} pros = {video.pros} />
    </Form>
    ;
}

export const submitROMEVideoForm = (props) => {
    const {uniqueId, action, editorContentRef} = props;
    const formData = new FormData(document.getElementById('video_rome_form'));
    formData.append("video_description", editorContentRef.current ?? '');
    formData.delete("secondary_rome");
    const payload = { uniqueId: uniqueId, formData:formData };
    return action(payload);
}
VideoRomeComponent.propTypes = {
    video: PropTypes.object,
    editorContentRef: PropTypes.any
}
export default VideoRomeComponent