import React, {useState, useEffect} from "react";
import { Container, Row, Col, Alert, Form  } from "react-bootstrap";


import FormationSessionsTableComponent from "../../components/formations/upload/formationSession/FormationSessionsTableComponent.jsx";

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

const FormationSessionsTableComponentWithParams = withURLParamsTableComponent(FormationSessionsTableComponent);

const FormationSessionsPage = () => {

    const {allFormationSessions, isFetchingAllFormationSessions} = useCustomGetStoreState("formationSessions")
    const [filteredFormationSessions, setFilteredFormationSessions] = useState(allFormationSessions);
    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par nom de webinaire', delay: 400})
    const [incomingSessionsOnly, setIncomingSessionsOnly] = useState(true);
  
    useEffect(() => {
        setFilteredFormationSessions(allFormationSessions)
    }, [allFormationSessions]);

    useEffect(() => {
        setFilteredFormationSessions(
            [...allFormationSessions
                .filter(formationSession => {
                    if (!incomingSessionsOnly)  {return true;}
                    const now = new Date();
                    const formationSessionStartsAt = new Date(formationSession.startsAt);
                    return formationSessionStartsAt >= now;
                    })
                .filter(formationSession => HelperMetiers360.isSearchInText(search, formationSession.formation.name))]
        );
	}, [search, allFormationSessions,incomingSessionsOnly]);


    const handleCheckBox = (event) => {
        const checkBox = event.currentTarget;
        setIncomingSessionsOnly(checkBox.checked);
    };


    const isNoFormationSessions = !isFetchingAllFormationSessions && !allFormationSessions.length
    const isNoResult = !isFetchingAllFormationSessions && allFormationSessions.length && !filteredFormationSessions.length;


    const startDateFilter =
    <Row>
        <Col className="mt-3">
            <Form.Check
                type="checkbox"
                onChange={handleCheckBox}
                defaultChecked={incomingSessionsOnly}
                label = "Ne voir que les sessions à venir"
                id="incomingSessionsOnly"
            />
        </Col>
    </Row>;

 

      return <Container fluid>
            <Row className="item-page">
                <Col>
                   <div> {searchInput}</div>
                </Col>
              
            </Row>
            {startDateFilter}
            
            {(isFetchingAllFormationSessions) && <Loader />}
            {(filteredFormationSessions.length > 0 )
                ? <FormationSessionsTableComponentWithParams formationSessionsList={filteredFormationSessions} fromSessionPage={true} enableToControlVisibleColumns={true} /> 
                : null}
            {isNoResult ? <Alert className="mt-3" variant="warning"> Aucune session ne correspond à votre recherche</Alert> : null}
            {isNoFormationSessions && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucune session</Alert>} 
        </Container>;
}

export default FormationSessionsPage;