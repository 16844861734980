import React from "react";
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { useStoreState } from "easy-peasy";

import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";

/**
 * 
 * @param {Array} romeList list of rome codes to display
 * @param {Boolean} isClickable callback to specify the action to do when clicking on the rome
 * @example <RomeComponent romeList={romeList} onClickItem={onClickItem} />
 * 
 */

const RomeComponent = (props) => {

    const { romeList, isClickable } = props;

    const { allRomes, isFetchingAllRomes } = useCustomGetStoreState('romes');
    const { readOne: readOneRome } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('romes'));

    if (allRomes.length == 0 || isFetchingAllRomes) {
        return <Spinner animation="border" size="sm" />;
    }

    const romeListWithOverlay = romeList
        .map(romeCode => { 
            const rome =  allRomes.find(rome => rome.codeRome == romeCode);
            
            return rome 
                && <span className="me-2" key={rome.id}>
                    {isClickable
                        ? <Link to={readOneRome.replace(':uniqueId', rome.codeRome)}>
                            <Button variant='light' className="ms-2">
                                {rome.label + " (" + rome.codeRome + ")"}
                            </Button>
                        </Link>
                        : <Button variant='light' className="ms-2 pe-none">
                            {rome.label + " (" + rome.codeRome + ")"}
                        </Button>
                    }
                </span>;
        });

    return <div className="d-flex">{romeListWithOverlay}</div>;
}

export default RomeComponent;