import React, { useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, usePDF, Image } from '@react-pdf/renderer';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import logo from '../../../images/logo_changeons_point_vue.png';
import ButtonLoaderComponent from '../ButtonLoaderComponent';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontWeight: 'light',
    fontSize: 10,
    padding: 40,
    backgroundColor: '#021f3f',
    color: '#eda2fd',
    height: '100%'
  },
  sectionlogo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '25%',
    borderRadius: '50%',
  },
  title: {
    fontSize: 22,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 5,
  },
  sectionAlph: {
    width: '100%',
    marginTop: 30,
  },
  mosaic: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 0,
    padding: 0,
  },
  card: {
    width: 150,
    margin: 5,
    padding: 0,
    height: 84,
  },
  pageCounter: {
    fontSize: 16,
    textAlign: 'right',
    fontFamily: 'Helvetica-Bold',
    },
    codeAccess: {
        width: 50,
        textAlign: 'center',
        color: '#ced4da',
        marginBottom: 5,
        border: '1px solid #0080f4',
        borderRadius: '30px',
        padding: 2,
        fontSize: 8,
        marginRight: 5
    },
    codeAccessMosaic: {
        marginLeft: 55
    },
    dFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    legend: {
        color: '#ced4da',
        marginBottom: 6
    }
})

const dateString = new Date().toLocaleDateString('fr-FR');

const VideosListDocument = ({videos}) => {

  const alphOrder = ['A - C', 'D - J', 'K - L', 'M - P', 'Q - S', 'T - Z', '1 - 9'];

    return (<Document title='catalogue' author='Métiers360'>
    <Page size="A4" style={styles.page}>
      <View>
        <Text>A jour du {dateString}</Text>
      </View>
      <View style={styles.sectionlogo}>
        <Image source={logo} style={styles.logo} />
      </View>

            <View style={styles.dFlex}>
                <Text style={styles.codeAccess}>XXXXX</Text>
                <Text style={styles.legend}>  : code d'accès direct</Text>
            </View>
      {alphOrder.map((sec, i) => {
        const regex = sec === "1 - 9" ? /^\d/i : new RegExp("^[" + sec + "]", "i");

        return <View style={styles.sectionAlph} key={sec} >
          <Text style={styles.title}>{sec}</Text>
          <View style={styles.mosaic}>
            { videos.filter((video) => regex.test(video.videoName) 
              && video.links?.thumbnail).map((v) => 
                  <View key={v.uniqueId}>
                      <Image key={v.videoName} source={v.links.thumbnail + "?"} style={styles.card} />
                      {v.directAccessCode
                          && <Text style={[styles.codeAccess, styles.codeAccessMosaic]}>{v.directAccessCode}</Text>}
                  </View>
            )}
          </View>
        </View>
      })}

      <Text style={styles.pageCounter} fixed
        render={({ pageNumber, totalPages }) => 
        `${pageNumber} / ${totalPages}`} />
    </Page>
  </Document>);

};

const DownloadVideosListPDF = (props) => {

  const { videos } = props;
  const [downloadDone, setDownloadDone] = useState(false);
  const [instance] = usePDF({ document: <VideosListDocument videos={videos} /> });

  useEffect(() => {
    if (!instance.loading && videos?.length && !downloadDone) {
      setDownloadDone(true);
      const link = document.createElement('a');
      link.href = instance.url;
        link.download = `catalogue-${dateString}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [instance.loading, videos, downloadDone]);

  const isLoading = (instance.loading && !instance.url) || !videos?.length;

  return instance.error
      ? <Button variant="danger" ><i className="fas fa-exclamation-triangle mr-1" />Catalogue</Button>
      : <ButtonLoaderComponent variant="primary" isSending={isLoading} disabled={isLoading}
          href={instance.url} download={`catalogue-${dateString}.pdf`}
          iconClassName="fas fa-download" msg="Catalogue" />
};

VideosListDocument.propTypes = {
  videos: PropTypes.array.isRequired
};
DownloadVideosListPDF.propTypes = {
  videos: PropTypes.array.isRequired
};

export default DownloadVideosListPDF;
