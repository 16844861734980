import React from "react";

import { CardGroup, Container } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from "../Loader.jsx";

import MiniCardMediaComponent from "../miniCardMedia/MiniCardMediaComponent.jsx";
import ScrollToTopButton from "../scrollToTopButton/ScrollToTopButton.jsx";
/**
 * 
 * @param {array} listOfMedia array of items to be displayed 
 * @param {string} mediaType  type of media to be displayed ["video" | "story" |...]
 * @param {string} readOne slug to page for media details
 * @param {bool} isLink  if you want the card to be a link to readOne
 * @param {callback} fetchData needed by InfiniteScroll logic to fetch next set of data
 * @param {bool} hasMore needed by InfiniteScroll logic to specify if the list is fully displayed
 * @returns 
 */
const InfiniteBoardComponent = (props) => {
    const { listOfMedia, mediaType, readOne: readOneSlug, isLink=true, fetchData, hasMore, ...other } = props;
    const cards = listOfMedia
        .map(media => {
            return (
                <div className={`playlist-${mediaType}-card`} key={media.uniqueId}>
                    <MiniCardMediaComponent 
                        media={media} 
                        mediaType={mediaType} 
                        isLink={isLink} 
                        readOneSlug={readOneSlug ? readOneSlug.replace(':uniqueId', media.uniqueId): null}
                        {...other}
                        />
                </div>
            )
        });

    return (
        <Container>
            <InfiniteScroll
                dataLength={listOfMedia.length} //This is important field to render the next data
                next={fetchData}
                hasMore={hasMore}
                loader={<Loader />}
                scrollThreshold={0.99}    
            >
                <CardGroup className="justify-content-around">
                    {cards}
                </CardGroup>
            </InfiniteScroll>
            <ScrollToTopButton />
        </Container>
    );
}
export default InfiniteBoardComponent;