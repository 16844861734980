import React, {useState, useEffect} from "react";
import { Container, Row, Col, Alert, Button} from "react-bootstrap";
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Link } from "react-router-dom";
import PedagogicModuleTableComponent from "../../components/pedagogicModules/moduleList/moduleTable/PedagogicModuleTableComponent.jsx";
import PedagogicModulesBoardComponent from "../../components/pedagogicModules/moduleList/moduleBoard/PedagogicModuleBoardComponent.jsx";

import PedagogicModuleSearchViewComponent  from "../../components/pedagogicModules/moduleList/moduleSearch/PeadgogicModuleSearchViewComponent.jsx";
import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";

import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import RadioTypeWidget from "../../components/widgets/radioType/RadioTypeWidget.jsx";

import "./PedagogicModulesPage.scss";

const PedagogicModuleTableWithParams = withURLParamsTableComponent(PedagogicModuleTableComponent);

const PedagogicModulesPage = () => {

    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfStage: allStages } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;

    const { allPedagogicModules, isFetchingAllPedagogicModules} = useCustomGetStoreState("pedagogicModules")

    const viewStylePreference = useStoreState(state => state.preferences.pedagogicModuleListStyle);
    const setViewStylePreference = useStoreActions(actions => actions.preferences.setPedagogicModuleListStyle);

    const pedagogicModuleBoardSortType = useStoreState(state => state.preferences.pedagogicModuleBoardSortType);
    const setPedagogicModuleBoardSortType = useStoreActions(actions => actions.preferences.setPedagogicModuleBoardSortType);

    const [filteredPedagogicModules, setFilteredPedagogicModules] = useState([]);


    const [init, setInit] = useState(false);
    const [presentCategories, setPresentCategories] = useState([]);
    const [presentTools, setPresentTools] = useState([]);
    const [presentLevels, setPresentLevels] = useState([]);
    const [presentStages, setPresentStages] = useState([]);
    const [presentTypeElements, setPresentTypeElements] = useState({});
    const [levelLabelAsTitle, setLevelLabelAsTitle] = useState("");
    const [viewStyle, setViewStyle] = useState(viewStylePreference);


    const sortType = [
        { value: 'date', label: "Publication (plus récente d'abord)" , icon:<><i className="fas fa-clock"></i></>, method: (a, b) => new Date(b.createdAt) - new Date(a.createdAt) },
        { value: 'name', label: 'Nom', icon:<><i class="fas fa-sort-alpha-down"></i></>  , method: (a, b) => a.name.localeCompare(b.name) },
    ];

    const [sort, setSort] = useState(pedagogicModuleBoardSortType ? sortType.find(type => type.value === pedagogicModuleBoardSortType) : sortType[0]);
    const [sortedPedagogicModules, setSortedPedagogicModules] = useState(allPedagogicModules.sort(sort.method));

    const pedagogicModuleSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("pedagogicModules"));


    const viewStyleType=[
        {value : 'table', icon:"fas fa-table", label : "En tableau"  , view : <PedagogicModuleTableWithParams pedagogicModules = {filteredPedagogicModules} {...pedagogicModuleSlugs} />},
        {value : 'cards', icon:"fas fa-address-card", label : "En vignettes" , view : <PedagogicModulesBoardComponent  pedagogicModules={sortedPedagogicModules} {...pedagogicModuleSlugs}/>},
    ];

    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés (Nom, Description, Objectif ...)', delay: 400});

    const createPedagogicModuleButton = pedagogicModuleSlugs.create
        ? <Button variant="success" as={Link} to={pedagogicModuleSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
        : null;


    const {typeCheck:typeCheckSort, checkedValue:checkedSort, handleChangeCheckedValue:handleChangeCheckedValueSort} = RadioTypeWidget({
        typesName: 'sort',
        defaultType : sort.value,
        allTypes:sortType
    }); 
    
    const {typeCheck:typeCheckViewType, checkedValue:checkedViewType, handleChangeCheckedValue:handleChangeCheckedValueViewType} = RadioTypeWidget({
        typesName: 'viewType',
        defaultType : viewStyle,
        allTypes:viewStyleType.reverse()
    }); 
           
    
    const isNoModules = !isFetchingAllPedagogicModules && !allPedagogicModules.length
    const isNoResult = !isFetchingAllPedagogicModules && !!allPedagogicModules.length && !filteredPedagogicModules.length;
   


    const main =  <>{(isFetchingAllPedagogicModules) && <Loader />}
    { filteredPedagogicModules.length > 0 && viewStyleType.filter(type => type.value===viewStyle)[0].view}
    {isNoResult && <Alert className="mt-3" variant="warning"> Aucun module pédagogique ne correspond à votre recherche</Alert>}
    {isNoModules && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun module pédagogique</Alert>}
    </>


    const getPresentTypes = (allTypes, types, presentTypes) =>{
        for (let i = 0; i < allTypes.length; i++) {
            let findTypes = allPedagogicModules.find((module)=>{
                return module[types]?.includes(allTypes[i].value)
            });

            if(findTypes){
                presentTypes.push(allTypes[i]);
            }
        }
        return presentTypes;
    }


    const handleChange = (val) => {
        setViewStyle(val);
        setViewStylePreference(val);
    }

    const sortChoice = (value) => {
        const sortSelected = sortType.filter(type => type?.value === value)[0];
        setSort(sortSelected);
        // eslint-disable-next-line no-unused-expressions
        setPedagogicModuleBoardSortType ? setPedagogicModuleBoardSortType(sortSelected.value) : null;
    };


   
    useEffect(() => {
        setSortedPedagogicModules([...filteredPedagogicModules.sort(sort.method)]);
    }, [sort, filteredPedagogicModules])

   
    useEffect(() => {
        if(checkedSort){
            sortChoice(checkedSort);
        }
	}, [checkedSort]);


    useEffect(() => {
        if(checkedViewType){
            handleChange(checkedViewType)
        }
	}, [checkedViewType]);



    useEffect(() => {
        if(allPedagogicModules.length>1 && !init){
            const presentCategoriesArr = getPresentTypes(allCategories, "categories", presentCategories);
            const presentLevelsArr = getPresentTypes(allLevels, "levels", presentLevels );
            const presentToolsArr = getPresentTypes(allTools, "tools", presentTools);
            const presentStagesArr = getPresentTypes(allStages, "stages", presentStages);
            setPresentCategories([... presentCategoriesArr]);
            setPresentTools([...presentToolsArr]);
            setPresentLevels([...presentLevelsArr]);
            setPresentStages([... presentStagesArr]);
            presentTypeElements.categories = presentCategories;
            presentTypeElements.tools = presentTools;
            presentTypeElements.levels = presentLevels;
            presentTypeElements.stages = presentStages;
            setPresentTypeElements({...presentTypeElements});
            setInit(true);
        }
    }, [allPedagogicModules]);



    return <>
        <Container fluid>
            <Row>
                <Col>
                    <h2>Nos activités pédagogiques</h2>
                    <Alert variant="info">Des supports et activités pédagogiques adaptés à vos objectifs et à vos différents publics, à réaliser avec vos casques et la webapp. <br/> 
                    De quoi trouver l'inspiration !</Alert>
                </Col>
            </Row>

            <Row className="mb-2">
               <h3>{levelLabelAsTitle}</h3>
            </Row>
            <Row className="item-page">
                <Col>
                   <div> {searchInput}</div>
                </Col>
            </Row>
            <Row>
            <Col xs={12} md={3} >
                <Row  >
                    <PedagogicModuleSearchViewComponent presentTypeElements={presentTypeElements}
                        allPedagogicModules={allPedagogicModules}
                        setFilteredPedagogicModules={setFilteredPedagogicModules}
                        setLevelLabelAsTitle={setLevelLabelAsTitle}
                        search={search}
                        admin={AuthorizationChecker.isAdmin()}/>
                </Row>
                <Row className="side-filter-element">
                    <span  className="side-filter-element-title">
                        Type de classement
                    </span>
                    {typeCheckSort}
                </Row>
                <Row className="side-filter-element" >
                    <span className="side-filter-element-title">
                        Type d'affichage
                    </span>
                    {typeCheckViewType}
                </Row>
                { AuthorizationChecker.hasCreateRights("pedagogicModules") &&
                <Row >
                    {createPedagogicModuleButton}
                </Row>
                }
            </Col>    
            <Col xs={12} md={9}>
                {main}
            </Col>    
            </Row>
   </Container>
        </>;

}

    export default PedagogicModulesPage;