import React from "react";
import DynamicTable from "../../widgets/dynamicTable/DynamicTable";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import AuthorizationChecker from "../../../services/AuthorizationChecker";
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";
import { useModal } from "../../../hooks/useModal";
import { useToast } from "../../../hooks/useToast.jsx";
import PropTypes from 'prop-types';

import './PreparatoryOrderTableComponent.scss';


const PreparatoryOrderTableComponent = (
    props
) => {
    const { orders, enableToControlVisibleColumns = false, withParams } = props;
    const navigate = useNavigate();
    const ordersSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('orders'));
    const { deleteOrder} = useStoreActions(actions => actions.orders);
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const toast = useToast();

    const handleClick = (uniqueId) => {
        navigate(ordersSlugs.readOne.replace(':uniqueId', uniqueId));
    }

    const statusColors = {draft: 'danger', validated: 'success', inPreparation: 'warning', Sent: 'secondary', Closed: 'info'};
    const iconifyStatus = (order) => <div className="statusdiv"> <i className={`fas fa-solid fa-circle fa-xs text-${statusColors[order.statusValue]}`}/>{order.status}
    {(order.isEmailSent && (order.statusValue === 'draft' || order.statusValue === 'validated') ) && <i className="fas fa-envelope ml-2 fa-sm"/>}</div>;


    const deleteOrderModal = (order) => {
        setModalData({
            ...modalData,
            header: <>Suppression d'un bon de préparation</>,
            content: <h5>Etes-vous sûr⋅e de vouloir supprimer le bon de {order.clientName} du {HelperMetiers360.getdisplayDateType(order.updateDate.date, 'short')} ?</h5>,
            resetButton: 'Supprimer',
            cancelButton:'Annuler',
            onReset: () => {
                setIsSending(true);
                deleteOrder({ uniqueId: order.uniqueId })
                    .then(() => {
                        toast.success();
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            }
        });
        setModalShow(true);
    };

    const sortType = orders?.length > 0
        && [
            {
                value : 'clientName', label : 'Établissement',
                test : orders[0]?.clientName !== undefined,
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
                display: (order) => order.clientName ? order.clientName : '-'
            },
            {
                value : 'tagOperations', label : 'Opé M360',
                test : orders[0]?.tagOperations !== undefined,
                method : (a, b) => HelperMetiers360.sortStringArray(
                    a.tagOperations?.map(tag => tag.name),
                    b.tagOperations?.map(tag => tag.name)),
                display: (order) => order.tagOperations.length > 0 ? order.tagOperations?.map(tag => tag.name).join(", ")  : '-'
            },
            {
                value : 'contactM360', label : 'Contacts M360',
                test : orders[0]?.contactM360Name !== undefined,
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.contactM360Name, b.contactM360Name),
                display: (order) => order.contactM360Name ? order.contactM360Name : '-'
            },
            {
                value : 'id', label : 'Id',
                test : orders[0]?.id !== undefined,
                method : (a, b) =>  b.id - a.id,
                display: (order) => order.id
            },
            {
                value : 'deliveryDate', label : 'Livraison prévue',
                test : orders?.some(o => o.deliveryDate),
                method: (a, b) => new Date(b.deliveryDate) - new Date(a.deliveryDate),
                display: (order) => order?.deliveryDate ? HelperMetiers360.getdisplayDateType(order.deliveryDate, 'day') : '-'
            },
            {
                value : 'recipientName', label : 'Contact',
                test : orders[0]?.recipientName !== undefined,
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.recipientName, b.recipientName),
                display: (order) => order.recipientName ? order.recipientName : '-'
            },
            {
                value : 'postalCode', label : 'Code postal',
                test : orders[0]?.postalCode !== undefined,
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.postalCode, b.postalCode),
                display: (order) => order.postalCode ? order.postalCode : '-'
            },
            {
                value : 'city', label : 'Ville',
                test : orders[0]?.city !== undefined,
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.city, b.city),
                display: (order) => order.city ? order.city : '-'
            },
            {
                value : 'updateDate', label : 'Modifié le',
                test : orders[0]?.updateDate.date !== undefined,
                method: (a, b) => new Date(b.updateDate.date) - new Date(a.updateDate.date),
                display: (order) => order?.updateDate.date ? HelperMetiers360.getdisplayDateType(order.updateDate.date, 'day') : '-'
            },
            {
                value : 'status', label : 'Statut',
                test : orders[0]?.status !== undefined,
                method : (a, b) => HelperMetiers360.localeCompareWithNullable(a.status, b.status),
                display: (order) => iconifyStatus(order),
                flatDisplay: (order) => order.status ? order.status : '-'
            }
        ];

    const displayDelete = (order) => order.isDeletable
            ? <Button variant="danger" size="sm" className="m-0 p-2" 
                onClick={(event) => { event.stopPropagation(); deleteOrderModal(order); }}>
                <i className="fas fa-trash-alt"></i>
            </Button>
            : <OverlayTrigger placement="left" overlay={
                <Tooltip>
                    <p>Ce bon ne peut pas être supprimé (non brouillon, email envoyé au prestataire, colis ou casque rattaché).</p>
                </Tooltip>}>
                <span>
                    <Button disabled variant="danger" size="sm" className="m-0 p-2">
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </span>
            </OverlayTrigger>;

    if(AuthorizationChecker.isAdmin() && sortType) {
        sortType.push({
            value: 'delete', label: "",
            test : orders.some(o => o.isDeletable),
            display: (order) => displayDelete(order),
            flatDisplay: (order) => !order.isDeletable ? '-':'X'
        });
    }

    return sortType 
        && <>
        <DynamicTable 
                contentTable = {orders}
                contentSort = {sortType}
                valueInitSort = "id"
                index = 'uniqueId'
                handleClick={handleClick}
                withParams={withParams}
                enableToControlVisibleColumns={enableToControlVisibleColumns && AuthorizationChecker.isAdmin()}
                tableName="preparatoryOrdersTable"
                filename="bons_preparation" />
            {modalComponent}
        </>;
};

PreparatoryOrderTableComponent.propTypes = {
    orders: PropTypes.array.isRequired,
    enableToControlVisibleColumns: PropTypes.bool, 
    withParams: PropTypes.bool,
};

export default PreparatoryOrderTableComponent;