import React, { useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { ListGroup, Row, Col, Alert } from 'react-bootstrap';

import Loader from '../../components/widgets/Loader';

import './MyWebappAccessesComponent.scss';

const MyWebappAccessesComponent = (props) => {

    const { clientById } = useStoreState(state => state.clients);
    const { initClientData } = useStoreActions(actions => actions.clients);
    const { client: clientUniqueId  } = useStoreState(state => state.auth);

    const [isLoading, setIsLoading] = useState(!clientById);

    const [msg, setMsg] = useState({});


    useEffect(() => {
        setIsLoading(true);
        initClientData({uniqueId:clientUniqueId})
            .finally(() => setIsLoading(false));
	}, [clientUniqueId, initClientData]);

    useEffect(() => {
        if(clientById) {

            switch(clientById.webappAccesses.length){
                case 0:
                    setMsg({msg1:<>Vous n'avez pas de code Webapp, veuillez contacter notre service relation client.</>});
                    break;
                case 1:
                    const url='https://webapp.metiers360.com/direct-access/'+clientById.webappAccesses[0];
                    setMsg({
                        msg1:<>
                            1.  Partagez le code <span className="code">{clientById.webappAccesses[0]}</span> à vos bénéficiaires 
                            ainsi que le lien <a target={"_blank"} rel="noreferrer noopener" href="https://webapp.metiers360.com/">https://webapp.metiers360.com/</a>
                            </>,
                        msg2:<>2. Accédez directement à la WebApp : <a target={"_blank"} href={url} rel="noreferrer noopener">{url}</a></>
                    })
                    break;
                default :
                    const codeList=clientById.webappAccesses.map((code)=>{
                        return <li key={code}> <span className="code">{code}</span> </li>
                    });

                    const directUrlList=clientById.webappAccesses.map(code => {
                        const url = 'https://webapp.metiers360.com/direct-access/'+code;
                        return <li key={code}> <a target={"_blank"} href={url}  rel="noreferrer noopener">{url}</a> </li>
                    })

                    setMsg({
                        msg1:<>1. Partagez l'un des codes suivant à vos bénéficiaires 
                        ainsi que le lien  <a  href="https://webapp.metiers360.com/" target={"_blank"} rel="noreferrer noopener">https://webapp.metiers360.com/</a>: <ul>{codeList}</ul></>,
                        msg2:<>2. Accédez directement à la WebApp via l'un des liens suivants: <ul>{directUrlList}</ul></>
                    })
                    break;
            }

        }
	}, [clientById]);

    return <Row>
        {!clientById || isLoading ? <Loader /> :
            <Alert variant="info">
                <Col className="webapp-container">
                    <h1>Vos accès à la Webapp
                        <hr/>
                        <span>
                        Découvrez une autre manière d'explorer les métiers sur mobile, tablette ou PC.
                        </span>
                    </h1>

                    <ListGroup  className="webapp-container-instructions">
                        <ListGroup.Item>{msg.msg1}</ListGroup.Item>
                        {msg.msg2 ? <ListGroup.Item>{msg.msg2}</ListGroup.Item>:null}
                    </ListGroup>
                </Col>
            </Alert>
        }
    </Row>;
}

export default MyWebappAccessesComponent;