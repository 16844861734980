import React, { useState, useEffect} from "react";


import HelperMetiers360 from "../../../../services/HelpersMetiers360";
import {  useNavigate  } from "react-router-dom";

import { useModal } from "../../../../hooks/useModal.jsx";
import { useStoreActions, useStoreState} from 'easy-peasy';
import DynamicTable from "../../../widgets/dynamicTable/DynamicTable.jsx";
import FormationSessionDetailsComponent from './FormationSessionDetailsComponent.jsx';
import AuthorizationChecker from "../../../../services/AuthorizationChecker.js";

const FormationSessionsTableComponent = (props) => {
    const {formationSessionsList, formation, fromAdmin, client=null, fromSessionPage = false,
        enableToControlVisibleColumns = false, withParams} = props;
   
    const {fetchFormationSessionById} = useStoreActions(actions => actions.formationSessions);
    const formationSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("formations"));
    const [activeRowId, setActiveRowId] = useState(null);
    const { modalComponent, modalShow, setModalShow, modalData, setModalData } = useModal();
   
    const formationSessionsToDisplay = formationSessionsList ? formationSessionsList : [];
    const navigate = useNavigate();
    const goToFormation = (formationUniqueId, formationSessionUniqueId) => {
        navigate(formationSlugs.readOneWithSub.replace(':uniqueId', formationUniqueId).replace(':otherId', formationSessionUniqueId))
    }
   
    const getRemainingPlaces = (formationSession) => {
        if(!formationSession.maxParticipants || formationSession.maxParticipants == 0){
            return "Illimitées";
        }
        else if(formationSession.places < 1){
            return "Complet";
        }
        else{
            return formationSession.places;
        }
    };

    const getPlacesNumberToSort = (formationSession) => {
        if(!formationSession.maxParticipants || formationSession.maxParticipants == 0){
            return 100000000;
        }
        else{
            return formationSession.places;
        }
    }

    const getDuration = (formationSession) => {
        return HelperMetiers360.calculateDurationInMins(formationSession?.startsAt, formationSession?.endsAt)
    };

    const formatDuration = (formationSession) => {
        return HelperMetiers360.formatDuration(getDuration(formationSession))
    };

    const hasParticipated =  (formationSession) => {
        return client && formationSession.usersHaveParticipated?.some(u=> u.client?.uniqueId === client.uniqueId);
    };

    useEffect(() => {
        if (modalShow && activeRowId) {
            setModalShow(false);
        }
    }, [formationSessionsList]);


    if (formationSessionsToDisplay?.length === 0) { return <></>; }

    const sortType = formationSessionsToDisplay ?
    [
        {
            value: 'formation', label: 'Webinaire',
            test: !formation && formationSessionsToDisplay[0]?.formation.name !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.formation?.name, b.formation?.name),
            display: (formationSession) => formationSession?.formation?.name
        },
        {
            value: 'startsAt', label: 'Début',
            test: formationSessionsToDisplay[0]?.startsAt !== undefined,
            method: (a, b) => new Date(a?.startsAt) - new Date(b?.startsAt),
            display: (formationSession) => formationSession.startsAt == null ? 
            '-' 
            : HelperMetiers360.getdisplayDateType(formationSession.startsAt)
        },
        {
            value: 'duration', label: 'Durée',
            test: formationSessionsToDisplay[0]?.endsAt !== undefined,
            method: (a, b) => getDuration(a) - getDuration(b),
            display: (formationSession) => formatDuration(formationSession)
        },
        {
            value: 'usersRegisteredCount', label: 'Inscrits',
            test: formationSessionsToDisplay[0]?.usersRegisteredCount !== undefined,
            method: (a, b) => a?.usersRegisteredCount - b?.usersRegisteredCount,
            display: (formationSession) => formationSession.usersRegisteredCount
        },
        {
            value: 'hasParticipated', label: 'A participé',
            test: !fromSessionPage && formationSessionsToDisplay.some(f=>f.usersRegisteredCount),
            method: (a, b) => (hasParticipated(a) ? "Oui" : "Non").localeCompare(hasParticipated(b) ? "Oui" : "Non"),
            display: (formationSession) => hasParticipated(formationSession) ? "Oui" : "Non",
        },
        {
            value: 'places', label: 'Places restantes',
            test: formationSessionsToDisplay[0]?.places !== undefined,
            method: (a, b) => getPlacesNumberToSort(a) - getPlacesNumberToSort(b),
            display: (formationSession) => getRemainingPlaces(formationSession)
        }
      ]:null;

      
      const openSessionModal = (selectedId) => {
        if(formation){
            setActiveRowId(selectedId);
            if(!fromAdmin){
                fetchFormationSessionById(selectedId)
                    .then((res)=>{
                        setModalData({
                            ...modalData,
                            header: 'Webinaire : ' + res?.formation?.name + ' - Session du ' + HelperMetiers360.getdisplayDateType(res?.startsAt)  ,
                            content: <FormationSessionDetailsComponent formationSession={res} formationToUpdate={formation} clientView={true}  client={client}/> ,
                            size: 'xl',
                        });
                        setModalShow(true);
                    })
            }
        }
        else{
            const selectedSession = formationSessionsList.find((session)=>{
                return session.uniqueId == selectedId;
            })
            goToFormation(selectedSession.formation.uniqueId,selectedId);
        }
    };

        return <>
            <DynamicTable 
                contentTable = {formationSessionsToDisplay}
                contentSort = {sortType}
                handleClick={openSessionModal}
                valueInitSort = "startsAt"
                index = 'uniqueId'
                className="table-formation"
                activeRowId={activeRowId}
                enableToControlVisibleColumns={enableToControlVisibleColumns && AuthorizationChecker.isAdmin()}
                tableName={"formationSequencesTable"} 
                withParams={withParams}
                filename="sessions_webinaire"
                />
            {modalComponent}
        </>
}

export default FormationSessionsTableComponent