import React, {useEffect, useState} from 'react';
import { Alert } from 'react-bootstrap';
import PropTypes from 'prop-types';
import useDebounceSearch from '../../hooks/useDebounchSearch.jsx';
import UserTableComponent from './usersList/UserTableComponent.jsx';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';

const UsersByClientComponent = ({client}) => {
    const [filteredUsers, setFilteredUsers] = useState(client.usersList);

    const {searchInput, search} = useDebounceSearch({placeholder: 'Rechercher par mots-clés (E-mail, Nom, Prénom)', delay: 400})

    useEffect(() => {
        setFilteredUsers(client.usersList.filter(user => 
            HelperMetiers360.isSearchInText(search, user.email)
                || HelperMetiers360.isSearchInText(search, user.firstName)
                || HelperMetiers360.isSearchInText(search, user.lastName)))
    }, [search])

    useEffect(() => {
        setFilteredUsers([...client.usersList])
    }, [client])

    if (!client.usersList.length){
        return <Alert variant="danger">Pas d'utilisateur⋅rices</Alert>}

    return <>
        {client.usersList.length > 1 && searchInput}
        {!filteredUsers.length &&
            <Alert variant="warning">
                Aucun⋅e utilisateur⋅rice ne correspond à votre recherche
            </Alert>
        } 
        <UserTableComponent users={filteredUsers} isShowRemoveBOAccessColumn={true} />
    </>
}

UsersByClientComponent.propTypes = {
    client: PropTypes.object.isRequired,
}

export default UsersByClientComponent;