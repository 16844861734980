import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStoreState } from 'easy-peasy';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

import useInfiniteLoadingLogic from "../../../hooks/useInfiniteLogic.jsx";
import DynamicTableInfinite from "../../widgets/dynamicTableInfinite/DynamicTableInfinite.jsx";

import './ClientsTableComponent.scss';
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";

import { useModal } from '../../../hooks/useModal.jsx';

const ClientsTableComponent = (props) => {
    const {clients, withParams} = props;
    const navigate = useNavigate();
    
    const [sortedClients, setSortedClients] = useState([]);
    const [clientsToDisplay, setClientsToDisplay] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const clientsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('clients'));
    const { modalComponent } = useModal();

    useEffect(() => {
        setShouldSort(true);
        setSortedClients([...clients]);
    }, [clients]);

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedClients, clientsToDisplay, setClientsToDisplay, 100);

    const wlIcons = {
        groupList : ["fas fa-folder", "groupe"],
        quizList : ["fas fa-question", "quiz"],
        subscriptionsQuizList : ["fas fa-question", "quiz"],
        subscriptionsVideoList : ["fas fa-video","video"],
        subscriptionQuizListIsRestrictive: ["fas fa-question", "quiz"],
        subscriptionVideoListIsRestrictive : ["fas fa-video","video"],
        videoList : ["fas fa-video","video"],
        videoBlackList : ["fas fa-video","video"],
        subscriptionsVideoBlackList: ["fas fa-video","video"],
    };

    const whitelistDisplay = (client, wType) => {
        const listCountProp = `${wType}Count`;
        const isRestrictiveProp = `${wType}IsRestrictive`;
        const isSubscriptionWL = wType.includes("subscription");
        const isBlackList = wType.includes("Black");
        const [iconClass, iconLabel] = wlIcons[wType] ?? ["", ""];
        const classNameWL = `whitelistElement 
            ${isSubscriptionWL 
                ? "subscriptionswL" 
                : isBlackList && "blackList"
            } 
            ${client[isRestrictiveProp] ? "restrictiveWL" : ""}`;

        if (client[listCountProp]) {
          return (
            <OverlayTrigger
            placement="right"
            overlay={
                <Tooltip>
                    {client[listCountProp]} {iconLabel}{client[listCountProp] > 1 ? "s" : ""} en {isBlackList ? "blacklist" : "whitelist"}{client[isRestrictiveProp] || listCountProp.includes('Restrictive') ? " restrictive" : ""}{isSubscriptionWL ? " par abonnement" : "" }
                </Tooltip>
            }    
            >
                <div className={classNameWL}>
                    {(isSubscriptionWL || isBlackList) ? <i className="fas fa-exclamation-triangle" /> :  null}
                    <i className={iconClass}/>{client[listCountProp]}
                </div>
            </OverlayTrigger>
          );
        } else {
          return null;
        }
    };
    
    const contactsM360Display = (contactsM360) => {
        return contactsM360.reduce((accumulator, currentValue) => 
            <>
                {(accumulator.props.children !== undefined) && <>{accumulator},&nbsp;</>}
                <OverlayTrigger placement="top" key={currentValue.uniqueId}
                    overlay={<Tooltip>{currentValue.relationValue}</Tooltip>}>
                    <span className="contactM360">{currentValue.name}</span>
                </OverlayTrigger>
            </>
        , <></>);
    }

    const sortType = [
        {
            value : 'name', label : 'Établissement',
            test : HelperMetiers360.isArrayContainsValue(clients, "clientName"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.clientName, b.clientName),
            display: (client) => client.clientName
        },
        {
            value: 'cp', label: "C.P.",
            test : HelperMetiers360.isArrayContainsValue(clients, "addressZipCode"),
            method: (a, b) =>HelperMetiers360.localeCompareWithNullable(a.addressZipCode, b.addressZipCode),
            display: (client) => client.addressZipCode

        },
        {
            value: 'city', label: "Ville",
            test : HelperMetiers360.isArrayContainsValue(clients, "addressCity"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.addressCity, b.addressCity),
            display: (client) => client.addressCity

        },
        {
            value: 'status', label: "Statut",
            test : HelperMetiers360.isArrayContainsValue(clients, "status"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.status, b.status),
            display: (client) => client.status

        },
        {
            value : 'tagOperations', label : 'Opé M360',
            test : HelperMetiers360.isArrayContainsValue(clients, "tagOperationsName"),
            method: (a, b) => HelperMetiers360.sortStringArray(a.tagOperationsName, b.tagOperationsName),
            display: (client) => client.tagOperationsName?.join(", ")
        },
        {
            value: 'subscriptionCount', label: "Nb abo.",
            test : HelperMetiers360.isArrayContainsValue(clients, "subscriptionCount"),
            method: (a, b) => b.subscriptionCount - a.subscriptionCount ,
            display: (client) => client.subscriptionCount ?? 0
        },
        {
            value : 'subscriptionEndDate', label : 'Date de fin',
            test : HelperMetiers360.isArrayContainsValue(clients, "subscriptionEndDate"),
            method: (a, b) => {
                const dateA = a.subscriptionEndDate ? new Date(a.subscriptionEndDate) : null;
                const dateB = b.subscriptionEndDate ? new Date(b.subscriptionEndDate) : null;
                return HelperMetiers360.compareWithNullable(dateA, dateB);
              },
            display: (client) => client.subscriptionEndDate ? HelperMetiers360.getdisplayDateType(client.subscriptionEndDate, 'day') : "-"
        },
        {
            value: 'hasWhitelist', label: "Whitelists",
            test : clients.some((client) => client.groupListCount || client.quizListCount || client.subscriptionsQuizListCount || client.subscriptionsVideoListCount || client.videoListCount || client.subscriptionQuizListIsRestrictiveCount || client.subscriptionVideoListIsRestrictiveCount || client.videoBlackListCount || client.subscriptionsVideoBlackListCount),
            method: (a, b) => (
                (a.groupListCount ?? 0) + (a.quizListCount ?? 0) + (a.subscriptionsQuizListCount ?? 0) +
                (a.subscriptionsVideoListCount ?? 0) + (a.videoListCount ?? 0) +
                (a.subscriptionQuizListIsRestrictiveCount ?? 0) + (a.subscriptionVideoListIsRestrictiveCount ?? 0) +
                (a.videoBlackListCount ?? 0) + (a.subscriptionsVideoBlackListCount ?? 0)
            ) - (
                    (b.groupListCount ?? 0) + (b.quizListCount ?? 0) + (b.subscriptionsQuizListCount ?? 0) +
                    (b.subscriptionsVideoListCount ?? 0) + (b.videoListCount ?? 0) +
                    (b.subscriptionQuizListIsRestrictiveCount ?? 0) + (b.subscriptionVideoListIsRestrictiveCount ?? 0) +
                    (b.videoBlackListCount ?? 0) + (b.subscriptionsVideoBlackListCount ?? 0)
                ),            
            display: (client) => {return (<div className="wlList">
                    {client.groupListCount > 0 && whitelistDisplay(client,'groupList')}
                    {client.videoListCount > 0 && whitelistDisplay(client,'videoList')}
                    {client.subscriptionsVideoListCount > 0 && whitelistDisplay(client,'subscriptionsVideoList')}
                    {client.subscriptionVideoListIsRestrictiveCount > 0 && whitelistDisplay(client,'subscriptionVideoListIsRestrictive')}
                    {client.videoBlackListCount > 0 && whitelistDisplay(client, 'videoBlackList')}
                    {client.subscriptionsVideoBlackListCount > 0 && whitelistDisplay(client, 'subscriptionsVideoBlackList')}
                    {client.quizListCount > 0 && whitelistDisplay(client,'quizList')}
                    {client.subscriptionsQuizListCount > 0 && whitelistDisplay(client,'subscriptionsQuizList')}
                    {client.subscriptionQuizListIsRestrictiveCount > 0 && whitelistDisplay(client,'subscriptionQuizListIsRestrictive')}
                </div>);
            },
            flatDisplay: (client) => (client.groupListCount + client.videoListCount + client.subscriptionsVideoListCount
                + client.subscriptionVideoListIsRestrictiveCount + client.videoBlackListCount + client.subscriptionsVideoBlackListCount
                + client.quizListCount + client.subscriptionsQuizListCount + client.subscriptionQuizListIsRestrictiveCount)
        },
        {
            value: 'formationCount', label: "Webinaires",
            test : HelperMetiers360.isArrayContainsValue(clients, "formationsCount"),
            method: (a, b) => b.formationsCount - a.formationsCount ,
            display: (client) => client.formationsCount
        },
        {
            value: 'headsetCount', label: "Casques",
            test : HelperMetiers360.isArrayContainsValue(clients, "headsetCount"),
            method: (a, b) => {
                const bHeadSetCount = b.headsetCount ? b.headsetCount : 0;
                const aHeadsetCount = a.headsetCount ? a.headsetCount : 0;
                return bHeadSetCount - aHeadsetCount;
            },
            display: (client) => client.headsetCount
        },
        {
            value: 'webappDeviceCount', label: "Device webapp",
            test : HelperMetiers360.isArrayContainsValue(clients, "webappDeviceCount"),
            method: (a, b) => b.webappDeviceCount - a.webappDeviceCount ,
            display: (client) => client.webappDeviceCount
        },
        {
            value: 'hasParent', label: "Enfant",
            test : HelperMetiers360.isArrayContainsValue(clients, "hasParent"),
            method: (a, b) => b.hasParent - a.hasParent ,
            display: (client) => +client.hasParent === 1 ? 'X': '-'
        },
        {
            value: 'childrenCount', label: "Nb d'enfants",
            test : HelperMetiers360.isArrayContainsValue(clients, "childrenCount"),
            method: (a, b) => b.childrenCount - a.childrenCount ,
            display: (client) => client.childrenCount
        },
        {
            value: 'loaderLastLog', label: "Dernier log (loader)",
            test : HelperMetiers360.isArrayContainsValue(clients, "loaderLastLog"),
            method: (a, b) => new Date(b.loaderLastLog) - new Date(a.loaderLastLog),
            display: (client) => HelperMetiers360.getdisplayDateType(client.loaderLastLog)
        },
        {
            value: 'users', label: "Utilisateur⋅rices",
            test : HelperMetiers360.isArrayContainsValue(clients, "users"),
            method: (a, b) => b.users - a.users ,
            display: (client) => client.users
        },
        {
            value: 'confirmedUser', label: "dont déjà connectés",
            test : HelperMetiers360.isArrayContainsValue(clients, "confirmedUser"),
            method: (a, b) => b.confirmedUser - a.confirmedUser ,
            display: (client) => client.confirmedUser
        },
        {
            value: 'pendingUser', label: "dont en attente",
            test : HelperMetiers360.isArrayContainsValue(clients, "pendingUser"),
            method: (a, b) => b.pendingUser - a.pendingUser ,
            display: (client) => client.pendingUser
        },
        {
            value: 'interactions', label: "Actions depuis dernière facture",
            test : HelperMetiers360.isArrayContainsValue(clients, "interactions"),
            method: (a, b) => HelperMetiers360.compareWithNullable(a.interactions, b.interactions),
            display: (client) => client.interactions
        },
        {
            value: 'contactsM360', label: "Contacts M360",
            test : HelperMetiers360.isArrayContainsValue(clients, "contactsM360"),
            method: (a, b) => {
                const contactA = a.contactsM360?.length > 0 ? a.contactsM360[0].name : null;
                const contactB = b.contactsM360?.length > 0 ? b.contactsM360[0].name : null;
                return HelperMetiers360.localeCompareWithNullable(contactA, contactB);
            },
            display: (client) => client.contactsM360?.length > 0 &&  contactsM360Display(client.contactsM360),
            flatDisplay: (client) => client.contactsM360?.map(contact => contact.name)?.join(", ")
        },
        {
            value: 'categories', label: "Catégories",
            test : HelperMetiers360.isArrayContainsValue(clients, "categories"),
            method: (a, b) => HelperMetiers360.sortStringArray(a.categories, b.categories),
            display: (client) => client.categories?.join(", ")
        }
    ];

    const onSelectClient = (uniqueID) => {
        navigate(clientsSlugs.readOne.replace(':uniqueId', uniqueID))
    }

    return <>
       { clientsToDisplay.length > 0 
            && <DynamicTableInfinite
                contentTable = {clientsToDisplay}
                contentSort = {sortType}
                valueInitSort = "name"
                index = 'uniqueId'
                handleClick = {onSelectClient}
                fetchData={fetchData}
                hasMore={hasMore}
                setSortedContent={setSortedClients}
                sortedContent={sortedClients}
                sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            enableToControlVisibleColumns={true}
                tableName="clientsTable"
                filename="etablissements" />
        }
        {modalComponent}
    </>;
}

export default ClientsTableComponent