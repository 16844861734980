import React, { useState, useEffect } from "react";
import { Form, Container, Row } from 'react-bootstrap';

import CardGroupMediaComponent from "../../../widgets/CardGroupMedia/CardGroupMediaComponent";

/**
 * 
 * @param {Array} sequences array of all sequences to be rendered
 * @param {Array} sortTypePreferenceTuple Optionnal -  state and action from store to handle sort type preference
 *  [
 *      videoBoardSortType: value from store,
 *      setVideoBoardSortType: actionCreator
 *  ]
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 * @example
 *  <SequenceBoardComponent sequences={sequences} sortTypePreferenceType={[string, actionCreator]} readAll={readAllSlug} create={createSlug} {...}/>
 */

const SequenceBoardComponent = (props) => {
    const { sequences, readOne: readOneSlug, sortTypePreferenceTuple } = props;

    const [sequenceBoardSortType, setSequenceBoardSortType ] = sortTypePreferenceTuple ? sortTypePreferenceTuple : [null, null];

    const sortType = [
        { value: 'date', label: "Publication (plus récente d'abord)", method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate) },
        { value: 'viewCount', label: 'Vues', method: (a, b) => b.viewCount - a.viewCount },
    ];

    const [sort, setSort] = useState(sequenceBoardSortType ? sortType.find(type => type.value === sequenceBoardSortType) : sortType[0]);
    const [sortedSequences, setSortedSequences] = useState(sequences.sort(sort.method));

    useEffect(() => {
        setSortedSequences([...sequences.sort(sort.method)]);
    }, [sort, sequences])

    const sortChoice = (e) => {
        const sortSelected = sortType.filter(type => type.value === e.target.value)[0];
        setSort(sortSelected);
        // eslint-disable-next-line no-unused-expressions
        setSequenceBoardSortType ? setSequenceBoardSortType(sortSelected.value) : null;
    };

    const checkSort = sortType.map(
        type => <Form.Check inline name="sort" label={type.label} type='radio' value={type.value} key={type.value} id={type.value} onChange={sortChoice} checked={sort.value === type.value} />
    );

    return (
        <Container fluid>
            <Row className="mb-4"><div className="d-flex justify-content-around">{checkSort}</div></Row>
            <Row>
                <CardGroupMediaComponent mediaType="sequence" mediaList={sortedSequences} readOne={readOneSlug} />
            </Row>
        </Container>


    );
}
export default SequenceBoardComponent;