import React,{ useMemo, useState, useRef } from 'react';
import { useNavigate } from 'react-router';

import TooltipListComponent from '../widgets/tooltipList/TooltipListComponent';
import HelperMetiers360 from '../../services/HelpersMetiers360';
import Tree from 'react-d3-tree';
import {Tooltip, OverlayTrigger, Button } from 'react-bootstrap';

import './GroupsTreeComponent.scss';


const GroupsTreeComponent = ({groups, filteredGroups, groupsSlugs}) => {

    const navigate = useNavigate();

    const [expandAll, setExpandAll] = useState(false);

    const initialRenderRef = useRef(true);
    const lastTreeDataRef = useRef(null);

    const getGroupsChildrenForTree = (group) => {
        const children = [];
        if (group.contents && group.contents.length > 0) {
            group.contents.forEach(
                (groupItem) => {
                    if (groupItem.type === 'group') {
                        const groupObject = groups.find(item => item.uniqueId === groupItem.uniqueId);
                        children.push(
                            {
                                name: groupItem.name,
                                attributes: {
                                    uniqueId: groupItem.uniqueId,
                                    private: groupItem.private,
                                    thumbnail: groupItem.thumbnail,
                                    producers: groupItem.producers,
                                    releaseDate: groupItem.releaseDate,
                                    hasFiles: groupItem.hasFiles,
                                },
                                children: getGroupsChildrenForTree(groupObject),
                                content: getGroupsContentForTree(groupObject)
                            }     
                        );  
                    }
                }
            )
        } 
        return children;
    }

    const getGroupsContentForTree = (group) => {
        const content = [];
        if (group.contents && group.contents.length > 0) {
            group.contents.forEach(
                (groupItem) => {
                    if (groupItem.type !== 'group') {
                        content.push(
                            {
                                name: groupItem.name,
                                uniqueId: groupItem.uniqueId,
                                type: groupItem.type
                            }  
                        ); 
                    }
                }
            )
        } 
        return content;
    }

    const treeData = useMemo(() => {
        if (groups && groups.length > 0 && (expandAll || initialRenderRef.current)) { // ne calcule le treeData que si c'est le premier render, ou si expandAll est passé à true pour forcer le re-render de l'arbre
            initialRenderRef.current = false;
            const menuGroup = groups.find(group => group.idHeadset === 0);    
            const newTreeData = {
                name: menuGroup.name,
                attributes: {
                    uniqueId: menuGroup.uniqueId,
                    type: 'group',
                    thumbnail: menuGroup.links.thumbnail
                },
                children: getGroupsChildrenForTree(menuGroup),
                content: getGroupsContentForTree(menuGroup),
            };
            lastTreeDataRef.current = newTreeData;
            return newTreeData;
        }
        return lastTreeDataRef.current; // on renvoie l'ancienne valeur de treeData sinon
    }, [groups, expandAll]);
    

    const onSelectGroup = (uniqueID) => {
         // eslint-disable-next-line no-unused-expressions
         groupsSlugs.readOne
            ? navigate(groupsSlugs.readOne.replace(':uniqueId', uniqueID))
            : null;
    }

    const generateIdCallback = (uniqueId) => {
        return `group-${uniqueId}`;
    }

    const nodeSize = { x: 400, y: 500 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -nodeSize.x*0.8/2, y: -nodeSize.x*0.8/2 };
    const initialPosition = {x: window.innerWidth/2.5, y: window.innerHeight/6};
    const depthFactor = nodeSize.y*1.15;
    const nodeSeparation = {
        siblings: 1.3,      // Adjust this value to control separation between sibling nodes
        nonSiblings: 1.2,   // Adjust this value to control separation between non-sibling nodes
    };
    const initialZoom = (treeData?.children && treeData?.children?.length > 0) 
        ? treeData.children.length < 4
            ? 2.75 / (treeData.children.length+2) * window.innerWidth/2000
            : 2.75 / treeData.children.length * window.innerWidth/1920
        : 1;

    const getItemBorder = (nodeDatumSent) => {
        const filteredId = filteredGroups.map((g)=> g.uniqueId);
        if (filteredGroups.length < groups.length && filteredId.includes(nodeDatumSent.attributes.uniqueId)) {
            return "foundGroup";
        }
    }

    const getMediaReleaseDate = (dateGiven) => {
        return HelperMetiers360.getdisplayDateType(dateGiven, 'day');
    }

    const today = new Date();

    const isDateLaterThanToday = (date1) => {
        const date1Conv = new Date(date1);
        return !!(date1Conv > today);
    }

    const renderForeignObjectNode = ({
        nodeDatum,
        foreignObjectProps,
        toggleNode,
      }) => {
        return (
        <g className='flexG'>
          <foreignObject {...foreignObjectProps} className='foreignObjectclass'>
            {nodeDatum.children?.length > 0 && 
                <div>
                    {nodeDatum.__rd3t.collapsed && <div className="dashedLine"></div>}
                    <div className={`expandBtn ${nodeDatum.__rd3t.collapsed ? "downButton":""}`} onClick={()=> {toggleNode(); setExpandAll(false)}}>
                    {nodeDatum.__rd3t.collapsed 
                        ? <i className="fas fa-chevron-down"></i> 
                        : <i className="fas fa-chevron-up"></i>}
                    </div>
                </div>}
          <div className={`nodeBox ${getItemBorder(nodeDatum)} ${nodeDatum.attributes?.private ? "privateGroup" : ""}`} onClick={() => onSelectGroup(nodeDatum.attributes?.uniqueId)}>
                { nodeDatum.attributes?.thumbnail &&
                <div className="miniThumbnail">
                    <div className="mini-icons">
                        { nodeDatum.attributes?.hasFiles && 
                        <OverlayTrigger
                            placement="right"
                            overlay={ <Tooltip>Groupe avec interaction</Tooltip> }>
                            <i className="fas fa-hand-sparkles mini-icon"></i>
                        </OverlayTrigger> }
                        { nodeDatum.attributes?.releaseDate && isDateLaterThanToday(nodeDatum.attributes.releaseDate) && 
                        <OverlayTrigger
                            placement="right"
                            overlay={ <Tooltip>Ce groupe n'est pas encore publié</Tooltip> }>
                            <i className="fas fa-hourglass mini-icon"></i>
                        </OverlayTrigger> }
                    </div>
                    <img src={nodeDatum.attributes?.thumbnail}/>
                </div>
                }
                <div className="nodeInfos">   
                    { nodeDatum.attributes?.releaseDate && <div className="dateBlock">
                        <i className="far fa-calendar-alt"></i> : {getMediaReleaseDate(nodeDatum.attributes.releaseDate)}
                    </div>}
                    <div className="nodeTitle">
                        {nodeDatum.name} {nodeDatum.attributes?.private && <i className="fas fa-lock ml-2" />}
                    </div>
                    {nodeDatum.attributes?.producers && <p className="nodeType">{nodeDatum.attributes.producers}</p>}
                    { nodeDatum.content &&
                    <div className='nodeContents'>
                        {nodeDatum.content.some((c)=>c.type === 'video') && <div className="contentList">
                            <TooltipListComponent
                            inputList={nodeDatum.content.filter((c)=>c.type === 'video')}
                            keyToDisplay='name'
                            generateIdCallback={generateIdCallback} 
                            /> Vidéos
                        </div>}
                        {nodeDatum.content.some((c)=>c.type === 'quiz') && <div className="contentList">
                            <TooltipListComponent
                            inputList={nodeDatum.content.filter((c)=>c.type === 'quiz')}
                            keyToDisplay='name'
                            generateIdCallback={generateIdCallback} 
                            /> Quizs
                        </div>}
                    </div>
                    }  
                </div>
            </div>
          </foreignObject>
        </g>
    )};

    const treeProps = { // on génère les props de l'arbre pour que si expandAll est à true, initialDepth ne soit même pas présent. S'il est présent cela le fait se render deux fois
        data:treeData,
        orientation:'vertical', 
        pathFunc:'step',     
        rootNodeClassName:"node__root",
        branchNodeClassName:"node__branch",
        leafNodeClassName:"node__leaf",
        nodeSize:nodeSize,
        renderCustomNodeElement:(rd3tProps) => renderForeignObjectNode({ ...rd3tProps, foreignObjectProps }),
        collapsible:true,
        zoom:initialZoom,
        translate:initialPosition,
        separation:nodeSeparation,
        depthFactor: depthFactor,
        ...expandAll ? {} : { initialDepth: 1 }
    };

    return (
        <>
         {treeData && <div className='treeWrapper'>
            {treeData.children?.length > 0 && <Button className='globalBTN' onClick={()=> {setExpandAll(true)}}>{"Tout déployer"}</Button>}
            <Tree 
                {...treeProps}
            />
        </div>}
        </> 
    );

}

export default GroupsTreeComponent;