import React from "react";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";

import UploadStoryComponent from "../../components/stories/UploadStoryComponent";
import useBackButton from "../../hooks/useBackButton";

const UploadStoryPage = (props) => {
	const {postStory, updateStory} =  useStoreActions((actions) => actions.stories);
    const storySlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("stories"));

	const {backButtonComponent} = useBackButton(({
        path: storySlugs.readAll,
        mediaType: "stories",
        shouldReplaceHistory: true, 

    }))

	return (
		<div className="container p-2">
			<Row className="mb-3">
				<Col className="d-flex justify-content-between align-items-center">
					<h2>Ajout d'une story</h2>
					{backButtonComponent}
				</Col>
			</Row>
            {
                <UploadStoryComponent 
					postStory={postStory} 
					updateStory={updateStory} 
					action="create"
					{...storySlugs}
				/>
            }
		</div>
	);
};

export default UploadStoryPage;
