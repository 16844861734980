import React from "react";
import { Row, Col } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useStoreActions } from "easy-peasy";

import './ProfessionnalComponent.scss'
import AuthorizationChecker from "../../services/AuthorizationChecker";
import { useModal } from "../../hooks/useModal";
import { useToast } from "../../hooks/useToast";

const ProfessionnalComponent = (props) => {
    const { pros, fromVideoUniqueId = null } = props;

    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const { deleteProFromVideo } = useStoreActions(actions => actions.videos);
    const toast = useToast();

    const hasDeleteRight = AuthorizationChecker.hasDeleteRights('videos');

    const onDelete= (pro) => {
        if(hasDeleteRight) {
            setModalData({
                ...modalData,
                header: <>Suppression d'un⋅e professionnel⋅le</>,
                content: <h5>Êtes-vous sûr⋅e de vouloir supprimer <b>{pro.name}</b>&nbsp;?</h5>,
                resetButton: 'Supprimer',
                cancelButton: 'Annuler',
                onReset: fromVideoUniqueId ?
                    () => {
                        setIsSending(true);
                        deleteProFromVideo({ videoUniqueId: fromVideoUniqueId, proUniqueId: pro.uniqueId })
                            .then(() => {
                                toast.success();
                                setModalShow(false);
                            })
                            .finally(() => setIsSending(false));
                    }
                    :null,
            });
            setModalShow(true);        }
    }

    return <Row>
        {pros.map(pro => 
            <Col key={pro.uniqueId} className="my-4 col-4" xs={12} md={6} xl={4} xxl={3}>
                <Row className="card-pro mx-0 p-3 flex-column">
                    {hasDeleteRight &&
                        <div className="btn-delete-pro" onClick={() => onDelete(pro)}>
                            <i className="fas fa-trash-alt"></i>
                        </div>}
                    <div className="photo" xs={12} md={12}>
                        <img src={pro.thumbnail} />
                    </div>
                    <div className="w-auto p-0">
                        <h5 className="text-center my-3">{pro.name}</h5>
                        {(pro.mainJob || pro.jobAppellations?.length > 0) && <div className="mb-3">
                            <i className="fas fa-briefcase fa-lg me-2" />
                            {pro.mainJob 
                                && <b>{pro.mainJob?.label + (pro.jobAppellations.length > 0 ? ', ' : '')}</b>
                            }
                            {pro.jobAppellations.map(jobAppellation => jobAppellation.label)?.join(', ')}
                        </div>}
                        {pro.areasOfInterest?.length > 0 && <div className="mb-3">
                                <i className="fa fa-heart fa-lg me-2" />
                                {pro.areasOfInterest.map(areaOfInterest => areaOfInterest.label)?.join(', ')}
                            </div>}
                        {pro.softSkills?.length > 0 && <div className="mb-3">
                            <i className="fas fa-user-cog fa-lg me-2" />
                            {pro.softSkills.map(softSkill => softSkill.label)?.join(', ')}
                        </div>}
                        {pro.workingConditions?.length > 0 && <div className="mb-3">
                            <i className="fas fa-laptop-house fa-lg me-2" />
                            {pro.workingConditions.map(workingCondition => workingCondition.label)?.join(', ')}
                        </div>}
                    </div>
                </Row>
            </Col>
        )}
    {modalComponent}
    </Row>
}

ProfessionnalComponent.propTypes = {
    pros: PropTypes.array,
    fromVideoUniqueId: PropTypes.string
};

export default ProfessionnalComponent;