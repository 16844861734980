import React, { useEffect } from "react";
import { Container, Row, Col, Alert, Spinner } from "react-bootstrap";
import './HomeRomesPage.scss';
import { useStoreActions, useStoreState } from "easy-peasy";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import { useNavigate } from "react-router-dom";

const HomeRomesPage = () => {
    const { 
        allRomes,
        allAreasOfInterest,
        allWorkingConditions,
        allSkills,
        allJobAppellations,
        isFetchingAllRomes, 
        isFetchingAllAreasOfInterest,
        isFetchingAllWorkingConditions,
        isFetchingAllSkills,
        isFetchingAllJobAppellations
    } = useCustomGetStoreState("romes")
    const { fetchAllRomes, fetchAllAreasOfInterest, fetchAllWorkingConditions, fetchAllSkills, fetchAllJobAppellations } = useStoreActions(actions => actions.romes);

    const romesSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('romes'));
    const workingConditionsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('workingConditions'));
    const areasOfInterestSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('areasOfInterest'));
    const skillsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('skills'));
    const jobAppellationsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('jobAppellations'));
	const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);

    const navigate = useNavigate();

    const isAreasOfInterestAccessible = !!ACTIONS?.areasOfInterest?.readAll;
    const isWorkingConditionsAccessible = !!ACTIONS?.workingConditions?.readAll;
    const isSkillsAccessible = !!ACTIONS?.skills?.readAll;
    const isJobAppellationsAccessible = !!ACTIONS?.jobAppellations?.readAll;

    useEffect(() => {
        Promise.all([
            allRomes.length === 0 && !isFetchingAllRomes && fetchAllRomes(),
            isAreasOfInterestAccessible && allAreasOfInterest.length === 0 && !isFetchingAllAreasOfInterest && fetchAllAreasOfInterest(),
            isWorkingConditionsAccessible && allWorkingConditions.length === 0 && !isFetchingAllWorkingConditions && fetchAllWorkingConditions(),
            isSkillsAccessible && allSkills.length === 0 && !isFetchingAllSkills && fetchAllSkills(),
            isJobAppellationsAccessible && allJobAppellations.length === 0 && !isFetchingAllJobAppellations && fetchAllJobAppellations()
        ])
    }, [])

    return <Container fluid>
        <Row className="item-page">
            <Col>
                <h2>Boîte à outils métiers</h2>
                <Alert variant="info">Retrouvez tous les métiers de notre catalogue, à travers leur fiche code ROME 
                    (<a target= "_blank" href="https://fr.wikipedia.org/wiki/R%C3%A9pertoire_op%C3%A9rationnel_des_m%C3%A9tiers_et_des_emplois">
                    Répertoire Opérationnel des Métiers et des Emplois</a>). Il vous suffit de cliquer sur un élément pour obtenir 
                    plus d’informations sur ce dernier, et afficher les ressources de Métiers360 correspondantes. Découvrez 
                    également à quelles personnalités <a target="_blank" href="https://fr.wikipedia.org/wiki/Mod%C3%A8le_RIASEC">RIASEC</a> sont liés ces éléments métiers.
                </Alert>
            </Col>
        </Row>
        <Row className="my-4 d-flex">
            <Col className="d-flex justify-content-center">
                <button className="card-entity" id="codes-romes"
                    onClick={() => !isFetchingAllRomes && navigate(romesSlugs.readAll)}>
                    <span className="card-entity-number">{isFetchingAllRomes 
                        ? <Spinner animation='border' size='sm'/> 
                        : allRomes.filter(ro => ro.usage !== 0).length}</span>
                    <span className="card-entity-title">Codes ROME</span>
                </button>
            </Col>
            { isJobAppellationsAccessible && <Col className="d-flex justify-content-center">
                    <button className="card-entity" id="job-appellations"
                        onClick={() => !isFetchingAllJobAppellations && navigate(jobAppellationsSlugs.readAll)}>
                        <span className="card-entity-number">{isFetchingAllWorkingConditions
                            ? <Spinner animation='border' size='sm' />
                            : allJobAppellations.length}</span>
                        <span className="card-entity-title">Métiers</span>
                    </button>
                </Col>
            }
            { isWorkingConditionsAccessible && <Col className="d-flex justify-content-center">
                    <button className="card-entity" id="contextes"
                        onClick={() => !isFetchingAllWorkingConditions && navigate(workingConditionsSlugs.readAll)}>
                        <span className="card-entity-number">{isFetchingAllWorkingConditions
                            ? <Spinner animation='border' size='sm' />
                            : allWorkingConditions.filter(wc => wc.pros !== 0).length}</span>
                        <span className="card-entity-title">Contextes de travail</span>
                    </button>
                </Col>
            }
            { isAreasOfInterestAccessible && <Col className="d-flex justify-content-center">
                    <button className="card-entity" id="centres-interets"
                        onClick={() => !isFetchingAllAreasOfInterest && navigate(areasOfInterestSlugs.readAll)}>
                        <span className="card-entity-number">{isFetchingAllAreasOfInterest
                            ? <Spinner animation='border' size='sm' />
                            : allAreasOfInterest.filter(aoi => aoi.pros !== 0).length}</span>
                        <span className="card-entity-title">Centres d'intérêt</span>
                    </button>
                </Col>
            }
            { isSkillsAccessible && <Col className="d-flex justify-content-center">
                    <button className="card-entity" id="macro-savoirs-etre"
                        onClick={() => !isFetchingAllSkills && navigate(skillsSlugs.readAll)}>
                        <span className="card-entity-number">{isFetchingAllSkills
                            ? <Spinner animation='border' size='sm' />
                            : allSkills.filter(sk => sk.pros !== 0).length}</span>
                        <span className="card-entity-title">Macro savoir-être professionnels</span>
                    </button>
                </Col>
            }
        </Row>
    </Container>;
}

export default HomeRomesPage;