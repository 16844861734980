import { action } from "easy-peasy";

const responsiveInitialState = {
	isMobile: false,
	navIsOpen: false,
}

const responsive = {
	...responsiveInitialState,

	setIsMobile: action((state, payload) => {
		state.isMobile = payload;
	}),

	setNavIsOpen: action((state, payload) => {
		state.navIsOpen = payload;
	}),
}

export default responsive;