import { useStoreActions, useStoreState } from 'easy-peasy';
import React, {useEffect, useState} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../components/widgets/Loader';
import TesterComponent from '../../components/lab/TesterComponent';
import NoneTesterComponent from '../../components/lab/NoneTesterComponent';

const LabPage = () => {
    const {isTester} = useStoreState(state => state.auth);
    const [isLoading, setIsLoading] = useState(isTester===null);
    const {getIsTester} = useStoreActions(state => state.auth);

    useEffect(() => {
        if(isTester===null) {
            getIsTester().then((response) => {setIsLoading(false);});
        }
    },[]);
    const content = isTester ?
        <TesterComponent />
        :<NoneTesterComponent />;
    return <Container>
        <Row>
            <Col>
                <h2>Le Lab</h2>
            </Col>
        </Row>
        {isLoading && <Loader />}
        {!isLoading && content}
    </Container>
}
export default LabPage;