import React, { useEffect, useState } from "react";
import { Container, Row, Col, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import useBackButton from "../../hooks/useBackButton.jsx";
import { useModal } from "../../hooks/useModal.jsx";
import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import UploadFileFormComponent, { submitFileForm } from "../../components/widgets/uploadFileForm/UploadFileFormComponent.jsx";
import EditableImageComponent from "../../components/widgets/editableImage/EditableImageComponent.jsx";
import ROMEByTransversalPropComponent from "../../components/romes/ROMEByTransversalPropComponent.jsx";
import VideoByTransversalPropComponent from "../../components/romes/VideoByTransversalPropComponent.jsx";

const transversalPropLabelRef = {
    areasOfInterest: "ce centre d'intérêt",
    workingConditions: 'ce contexte de travail',
    skills: 'ce macro savoir-être professionnel',
    default: "cette propriété transversale"
}

const RomeTransversalPropDetailPage = ({ transversalProp }) => {
    const { apiData: { BACKOFFICE_DEFAULT_VALUES } } = useStoreState(state => state.actionSlugs);
    const { fileContraints } = BACKOFFICE_DEFAULT_VALUES;
    const { uniqueId } = useParams();
    const { isFetchingOneTransversalProp } = useCustomGetStoreState('romes');
    const { fetchOneTransversalProp, updateOneTransversalPropThumbnail } = useStoreActions(actions => actions.romes);
    const { oneTransversalProp } = useStoreState(state => state.romes);

    const [allRomes, setAllRomes] = useState([]);
    const [fromROME, setFromROME] = useState(true);
    const { backButtonComponent } = useBackButton({ path: -2 });
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const { apiData: { ACTIONS } } = useStoreState(state => state.actionSlugs);

    const transversalPropName = transversalPropLabelRef[transversalProp] ??
        transversalPropLabelRef.default;

    useEffect(() => {
        if (!isFetchingOneTransversalProp) {
            fetchOneTransversalProp({
                code: uniqueId,
                transversalProp: transversalProp
            }).then((data) => {
                setFromROME(data.videosByPro?.length == 0);
                setAllRomes(data.romes);
            });
        }
    }, []);

    const updateThumbnailModal = () => {
        if (AuthorizationChecker.hasUpdateRights('romes')) {

            const validate = () => {
                setIsSending(true);
                return submitFileForm(uniqueId, updateOneTransversalPropThumbnail, transversalProp + "_updateThumbnail", fileContraints, "image", transversalProp)
                    .then(() => {
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            }
            setModalData({
                ...modalData,
                header: <>Modifier la vignette de {transversalPropName}</>,
                content: <UploadFileFormComponent typeOfFile="image" fileFormName={transversalProp + "_updateThumbnail"} fileLabel="Vignette" token={ACTIONS[transversalProp]["updateThumbnail"]["csrfToken"]} />,
                cancelButton: 'Annuler',
                validateButton: "Valider",
                onValidate: validate,
                size: 'lg'
            });
            setModalShow(true);
        }
    }

    const onToggleOrigin = (val) => {
        setFromROME(val);
    }
    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    {isFetchingOneTransversalProp ? <Loader /> : <>
                        {oneTransversalProp?.thumbnail &&
                            <Col xs={1} style={{ maxHeight: '4em' }}>
                                <EditableImageComponent src={oneTransversalProp.thumbnail} onEdit={updateThumbnailModal} />
                            </Col>
                        }
                        <Col xs={6} className={(oneTransversalProp?.thumbnail && "align-items-center ") + "d-flex flex-column"}>
                            <Row className="mb-4">{oneTransversalProp?.label}</Row>
                            <Row>
                                <ToggleButtonGroup
                                    type="radio"
                                    name="switch_view"
                                    onChange={onToggleOrigin}
                                    value={fromROME} className='ms-2' style={{ maxWidth: '15em' }}>
                                    <ToggleButton value={true} checked={!!fromROME} id='from_ROME'>Codes ROME</ToggleButton>
                                    <ToggleButton value={false} checked={!fromROME} id='not_from_ROME'>Vidéos via les pros</ToggleButton>
                                </ToggleButtonGroup>
                            </Row>
                        </Col>
                    </>
                    }
                    {backButtonComponent}
                </h2>
            </Row>
            {isFetchingOneTransversalProp && <Loader />}
            {fromROME
                ? <ROMEByTransversalPropComponent
                    oneTransversalProp={oneTransversalProp}
                    allRomes={allRomes}
                    uniqueId={uniqueId}
                    transversalPropLabel={transversalPropName} />
                : <VideoByTransversalPropComponent
                    oneTransversalProp={oneTransversalProp}
                    uniqueId={uniqueId}
                    transversalPropLabel={transversalPropName} />
            }
            {modalComponent}
        </Container>
    );
}

RomeTransversalPropDetailPage.propTypes = {
    transversalProp: PropTypes.string.isRequired,
};

export default RomeTransversalPropDetailPage;