import React, { useEffect, useState, useRef } from "react";
import { Col, Container, Row, Button, Form, Alert } from "react-bootstrap";
import { useStoreState } from 'easy-peasy';
import { useSearchParams, Link } from "react-router-dom";
import useSearchWithParams from "../../hooks/useSearchWithParams";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import Loader from "../../components/widgets/Loader";
import WelcomeMessageTableComponent from "../../components/welcomeMessage/WelcomeMessageTableComponent";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360";

const WelcomeMessageTableComponentWithParams = withURLParamsTableComponent(WelcomeMessageTableComponent);


const WelcomeMessagePage = () => {

    const { allMessages, isFetchingAllMessages } = useCustomGetStoreState('welcomeMessage');
    const [filteredMessages, setFilteredMessages] = useState(allMessages);
    const [displayExpiredMessages, setDisplayExpiredMessages] = useState(false);
    const [searchParam, setSearchParam] = useSearchParams();
    const checkedRef = useRef();

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    useEffect(() => {
        const newSearchParam = searchParam.get('expiredMessages');
        if (newSearchParam) {
            const booleanParam = JSON.parse(newSearchParam);
            setDisplayExpiredMessages(booleanParam);
            checkedRef.current.checked = booleanParam;
        }
    }, []);

    const messageSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("welcomeMessage"));

    const { searchInput, search } = useSearchWithParams({
        placeholder:
            'Rechercher par mots-clés (titre, contenu)', delay: 400
    });

    const createMessageButton = messageSlugs.create ?
        <Button variant="success" as={Link} to={messageSlugs.create}><i className="fas fa-plus"></i> &nbsp;Nouveau</Button>
        : null;

    const setParamsToUrl = (newParams) => {
        const paramsToSet = {}
        for (const [key, value] of searchParam.entries()) {
            paramsToSet[key] = value;
        }
        for (const [key, value] of newParams) {
            paramsToSet[key] = value;
        }
        setSearchParam(paramsToSet);
    }

    useEffect(() => {
        setParamsToUrl([
            ['expiredMessages', displayExpiredMessages],
        ])
    }, [displayExpiredMessages])

    useEffect(() => {
        setFilteredMessages(
            [...allMessages
                .filter((message) => {
                    const expirationDate = new Date(message.expirationDate?.date);
                    return (displayExpiredMessages
                        ? true
                        : (!message.hasBeenDeactivated && expirationDate >= today))
                }
                ).filter((message) => HelperMetiers360.isSearchInText(search, message.title)
                    || HelperMetiers360.isSearchInText(search, message.content)
                )]
        )
    }, [search, allMessages, displayExpiredMessages]);

    return <Container fluid>
        <Row>
            <Col>
                <h2>Bannières d'accueil</h2>
            </Col>
        </Row>
        <Row>
            <Col>{searchInput}</Col>
            <Col className="d-flex justify-content-end align-items-center">{createMessageButton}</Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Form.Check
                    type="checkbox"
                    onChange={() => setDisplayExpiredMessages((prev) => !prev)}
                    defaultChecked={false}
                    label="Afficher les bannières passées"
                    id="expiredMessages"
                    ref={checkedRef}
                />
            </Col>
        </Row>
        <Row>
            {isFetchingAllMessages && <Loader />}
            {filteredMessages.length > 0 ?
                <WelcomeMessageTableComponentWithParams messages={filteredMessages} enableToControlVisibleColumns={false} />
                : <Alert variant="warning">Aucun message d'accueil à afficher</Alert>
            }
        </Row>
    </Container>
}

export default WelcomeMessagePage;