import React, { useState, useEffect, useRef} from "react";
import HelperMetiers360 from "../../services/HelpersMetiers360";
import AuthorizationChecker from '../../services/AuthorizationChecker.js';
import DynamicTable from "../widgets/dynamicTable/DynamicTable";
import { Alert, Button, Form } from 'react-bootstrap';
import { useModal } from '../../hooks/useModal.jsx';
import SubscriptionFormComponent, { submitSubscriptionForm } from './uploadForm/SubscriptionFormComponent.jsx';
import { useStoreActions} from "easy-peasy";
import { useToast } from "../../hooks/useToast.jsx";
import PropTypes from 'prop-types';

const SubscriptionTableComponent = (props) => {
    const {onSelect, scrollTargetRef, subscriptions, updatable=true, withParams,
        multipleSelection = false, subscriptionUniqueIdsChecked, setSubscriptionUniqueIdsChecked} = props;
    const { modalComponent, setModalShow, modalData, setModalData, setIsSending } = useModal();
    const [activeRowId, setActiveRowId] = useState(null);
    const {updateSubscription, deleteSubscription} = useStoreActions(actions => actions.subscriptions);
    const toast = useToast();
    const confirmDeleteRef = useRef(null);

    useEffect(() => { 
        if (!multipleSelection && subscriptionUniqueIdsChecked?.length > 0) {
            setActiveRowId(subscriptionUniqueIdsChecked[0]);
        }
    },[]);

    const sortType = subscriptions?.length > 0
        ? [
            {
                value : 'name', label : 'Nom',
                test : HelperMetiers360.isArrayContainsValue(subscriptions, 'subscriptionName'),
                method: (a,b) =>  HelperMetiers360.localeCompareWithNullable(a.subscriptionName, b.subscriptionName),
                display: (subscription) => subscription.subscriptionName,
            },
            {
                value : 'creation', label : 'Date de début',
                test : subscriptions[0]?.createdAt !== undefined,
                method: (a,b) =>  new Date(b.createdAt.date) - new Date(a.createdAt.date),
                display: (subscription) => HelperMetiers360.getdisplayDateType(subscription.createdAt.date, 'day')
            },
            {
                value : 'subscriptionEndDate', label : 'Date de fin',
                test : subscriptions[0]?.subscriptionEndDate !== undefined,
                method: (a, b) => new Date(b.subscriptionEndDate) - new Date(a.subscriptionEndDate),
                display: (subscription) => HelperMetiers360.getdisplayDateType(subscription.subscriptionEndDate, 'day')
            },
            {
                value : 'headsetCount', label : 'Nb de casques',
                test : subscriptions[0]?.headsetCount !== undefined,
                method: (a, b) => b.headsetCount - a.headsetCount ,
                display: (subscription) => subscription.headsetCount
            },
            {
                value : 'headsetNumberPredicted', label : 'Nb de casques prévus',
                test : subscriptions[0]?.headsetNumberPredicted !== undefined,
                method: (a, b) => b.headsetNumberPredicted - a.headsetNumberPredicted ,
                display: (subscription) => subscription.headsetNumberPredicted
            },
            {
                value : 'videoList', label : 'Liste Blanche Vidéo ?',
                test : subscriptions[0]?.videoList !== undefined,
                method: (a, b) => b.videoList.length - a.videoList.length ,
                display: (subscription) => subscription.videoList.length != 0 ? 
                    subscription.videoListIsRestrictive ? 'restrictive':'oui'
                    :'non'
            },
            {
                value : 'quizList', label : 'Liste Blanche Quizz ?',
                test : subscriptions[0]?.videoList !== undefined,
                method: (a, b) => b.quizList.length - a.quizList.length ,
                display: (subscription) => subscription.quizList.length != 0
                    ? subscription.quizListIsRestrictive ? 'restrictive':'oui'
                    : 'non'
            },
            {
                value: 'update', label: "Éditer",
                test : AuthorizationChecker.hasUpdateRights('subscriptions') && updatable,
                method: (a, b) => new Date(b?.subscriptionEndDate) - new Date(a?.subscriptionEndDate),
                display: (subscription) => <Button variant="secondary" size="sm" onClick={() => createEditModal(subscription)}>
                    <i className="fas fa-edit"></i>
                </Button>
            }
        ]
        : null;

        const createEditModal = (subscription) => {
            const handleSubmit = () => {
                setIsSending(true);
                submitSubscriptionForm({action: 'update', subscription, updateSubscription})
                    .then(() => {
                        toast.success()
                        setModalShow(false);
                    })
                    .finally(() => setIsSending(false));
            }
    
            const deleteSubscriptionModal = () => {
                setModalData({
                    ...modalData,
                    header: <>Suppression de l'abonnement</>,
                    content: <>
                        <Alert variant="danger">
                            <h5>Êtes-vous sûr⋅e de vouloir supprimer l'abonnement&nbsp;
                                {subscription.subscriptionName ?? ''}&nbsp;?
                            </h5>
                        </Alert>
                        <label htmlFor="confirm-control">Écrivez <b>SUPPRIMER</b> pour valider la suppression</label>
                        <Form.Control type='text' id="confirm-control" ref={confirmDeleteRef} />
                    </>,
                    resetButton: 'Supprimer',
                    cancelButton: 'Annuler',
                    onReset: () => {
                        if(confirmDeleteRef?.current?.value !== "SUPPRIMER") {
                            toast.open({ message: "Le message de confirmation ne correspond pas au message attendu : SUPPRIMER", variant: "danger" })
                        } else {
                            setIsSending(true);
                            deleteSubscription({ uniqueId: subscription.uniqueId })
                                .then(() => {
                                    toast.open({ message: "Suppression de l\'abonnement réalisée avec succès", variant: "success" })
                                    setModalShow(false);
                                })
                                .finally(() => setIsSending(false));
                        }
                    },
                });
                setModalShow(true);
            }

            setModalData({
                ...modalData,
                header: <>Edition de l'abonnement</>,
                content: <SubscriptionFormComponent subscription={subscription} action='update' />,  
                cancelButton: "Annuler",
                resetButton: "Supprimer",
                resetDisabledReason: !subscription.isDeletable 
                    ? "Cet abonnement ne peut pas être supprimé car il est lié à des casques ou à un bon de préparation"
                    : null,
                onValidate: handleSubmit,
                onReset: () => deleteSubscriptionModal(),
                size: 'xl', 
            });
            setModalShow(true);
        }

        const handleClick = onSelect ? 
            (selectedId) => {
                if (activeRowId === selectedId && subscriptions?.length > 1) {
                    onSelect("");
                    setActiveRowId("");
                } else {
                    onSelect(subscriptions.find( subscription => subscription.uniqueId === selectedId ));
                    setActiveRowId(selectedId);
                }

                if (scrollTargetRef ) {
                    scrollTargetRef?.current?.scrollIntoView({behavior: 'smooth'});
                }
            }
            :null;

        return (
        <>
            <DynamicTable 
                contentTable = {subscriptions}
                contentSort = {sortType}
                valueInitSort = "name"
                index = 'uniqueId'
                handleClick = {!multipleSelection ? handleClick : null}
                className="table-subscription"
                activeRowId={activeRowId}
                withCheckBoxes={multipleSelection}
                lineIndexesChecked={subscriptionUniqueIdsChecked}
                setLineIndexesChecked={setSubscriptionUniqueIdsChecked}
                withParams={withParams}
            />
            {modalComponent}
        </>
        )
}

SubscriptionTableComponent.propTypes = {
    onSelect: PropTypes.any,
    scrollTargetRef: PropTypes.any,
    subscriptions: PropTypes.array.isRequired,
    updatable: PropTypes.any,
    multipleSelection: PropTypes.bool,
    subscriptionUniqueIdsChecked: PropTypes.array,
    setSubscriptionUniqueIdsChecked: PropTypes.any,
};

export default SubscriptionTableComponent