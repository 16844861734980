import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Alert } from "react-bootstrap";

import RomesTableComponent from "../../components/romes/RomesTableComponent.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";
import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import RomeDetailsContentsComponent from "../../components/romes/romeDetail/RomeDetailsContentsComponent.jsx";

const RomesTableWithParams = withURLParamsTableComponent(RomesTableComponent);

const ROMEByTransversalPropComponent = (props) => {
    const { oneTransversalProp, allRomes, uniqueId, transversalPropLabel } = props;

    const { isFetchingOneTransversalProp } = useCustomGetStoreState('romes');
    const { allStories, isFetchingStories } = useCustomGetStoreState("stories");
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState("videos");

    const [romeStories, setRomeStories] = useState([]);
    const [romeVideos, setRomeVideos] = useState([]);
    const { searchInput, search } = useSearchWithParams({ placeholder: 'Rechercher par mots-clés (Métier, Code ROME, Riasec majeur ...)', delay: 400 });


    useEffect(() => {
        if (oneTransversalProp?.code === uniqueId && oneTransversalProp?.stories) {
            setRomeStories(allStories?.filter(story => oneTransversalProp?.stories?.includes(story.uniqueId))
                .sort((a, b) => oneTransversalProp?.stories?.indexOf(b.uniqueId)
                    - oneTransversalProp?.stories?.indexOf(a.uniqueId)));
        }
    }, [allStories, oneTransversalProp, uniqueId]);


    useEffect(() => {
        if (oneTransversalProp?.code === uniqueId && oneTransversalProp?.videos) {
            setRomeVideos(allVideos?.filter(video => oneTransversalProp?.videos?.includes(video.uniqueId))
                .sort((a, b) => oneTransversalProp?.videos?.indexOf(b.uniqueId)
                    - oneTransversalProp?.videos?.indexOf(a.uniqueId)))
        }
    }, [allVideos, oneTransversalProp, uniqueId]);

    const filteredRomes = useMemo(() => {
        if (oneTransversalProp) {
            if (!search) return oneTransversalProp?.romes;
            else {
                const filterRomes = (search) => {
                    if (search === '') {
                        return oneTransversalProp.romes;
                    }
                    return oneTransversalProp.romes.filter(rome => HelperMetiers360.isSearchInText(search, rome.codeRome)
                        || HelperMetiers360.isSearchInText(search, rome.label)
                        || HelperMetiers360.isSearchInText(search, HelperMetiers360.riasecLabel(rome.riasecMajeur))
                        || HelperMetiers360.isSearchInText(search, HelperMetiers360.riasecLabel(rome.riasecMineur)));
                }
                return [...filterRomes(search)];
            }
        }
    }, [search, oneTransversalProp, allRomes]);

    const isNoRome = !isFetchingOneTransversalProp && !oneTransversalProp?.romes?.length;
    const isNoResult = !isFetchingOneTransversalProp && oneTransversalProp?.romes?.length && !filteredRomes?.length;

    return <>
        <Alert variant="info">Voici les codes ROME associés à {transversalPropLabel}. Retrouvez également, les ressources de Métiers360 correspondantes.</Alert>
        <Row>
            <Col>
                {searchInput}
            </Col>
        </Row>
        <Row>
            <Col className="col-7">
                {filteredRomes?.length > 0 && <RomesTableWithParams romes={filteredRomes} />}
                {isNoRome && <Alert className="mt-3" variant="warning">Aucun métier ne correspond à votre recherche</Alert>}
                {isNoResult && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun métier "{search}"</Alert>}
            </Col>
            <Col className="col-5">
                <RomeDetailsContentsComponent
                    romeStories={romeStories}
                    romeVideos={romeVideos}
                    isFetchingStories={isFetchingStories}
                    isFetchingAllVideos={isFetchingAllVideos} />
            </Col>
        </Row>
    </>;
}

export default ROMEByTransversalPropComponent;