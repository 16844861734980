import React, { useEffect } from 'react';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {Form, InputGroup, Row, Col, Alert} from 'react-bootstrap';

const CreateUpdateUserComponent = (props) => {
    const {user=null, action=null} = props;
    const { createFormErrors } = useStoreState(state => state.users);
    const { setCreateFormErrors } = useStoreActions(actions => actions.users);
    const { apiData: {ACTIONS} } = useStoreState(state => state.actionSlugs);
    const {formName, csrfToken} = ACTIONS['users'][action];

    useEffect(() => {
        setCreateFormErrors({});
      }, [])

      return (
        <div className='form-create-user'>
            <Form id={formName} onSubmit={(event) => event.preventDefault()}>
                <Row>
                    <Col>
					<InputGroup>
                        <InputGroup.Text>Nom :</InputGroup.Text>
						<Form.Control
							name={`lastName`}
                            defaultValue={user ? user.lastName : ''}
                            isInvalid={ createFormErrors?.lastName !== undefined}
						/>
                        <Form.Control.Feedback type='invalid'>{ createFormErrors?.lastName }</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                    <Col>
					<InputGroup>
                        <InputGroup.Text>Prénom :</InputGroup.Text>
						<Form.Control
							name={`firstName`}
                            defaultValue={user ? user.firstName : ''}
                            isInvalid={ createFormErrors?.firstName !== undefined}
						/>
                        <Form.Control.Feedback type='invalid'>{ createFormErrors?.firstName }</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
                       <InputGroup.Text>Fonction :</InputGroup.Text>
						<Form.Control
							name={`job`}
                            defaultValue={user ? user.job : ''}
                            isInvalid={ createFormErrors?.job !== undefined}
						/>
                        <Form.Control.Feedback type='invalid'>{ createFormErrors?.job }</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
					<InputGroup>
						<InputGroup.Text>Tél :</InputGroup.Text>
						<Form.Control
							name={`phoneNumber`}
                            defaultValue={user ? user.phoneNumber : ''}
                            isInvalid={ createFormErrors?.phoneNumber !== undefined}
						/>
                        <Form.Control.Feedback type='invalid'>{ createFormErrors?.phoneNumber }</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                    <Col>
					<InputGroup>
						<InputGroup.Text>Mobile :</InputGroup.Text>
						<Form.Control
							name={`cellphoneNumber`}
                            defaultValue={user ? user.cellphoneNumber : ''}
                            isInvalid={ createFormErrors?.cellphoneNumber !== undefined}
						/>
                        <Form.Control.Feedback type='invalid'>{ createFormErrors?.cellphoneNumber }</Form.Control.Feedback>
					</InputGroup>
                    </Col>
                </Row>
				<Row>
					<InputGroup>
						<InputGroup.Text>
                            Email de l'usager {(user?.isRegisteredUser || action === 'create') && '* '}:
                        </InputGroup.Text>
						<Form.Control
                            type="email"
							name={`email`}
                            defaultValue={user ? user.email : ''}
                            isInvalid={ createFormErrors?.email !== undefined}
                            onChange={() => createFormErrors && setCreateFormErrors({})}
						/>
                        <Form.Control.Feedback type='invalid'>{ createFormErrors?.email }</Form.Control.Feedback>
                        <Form.Group >
                            <Form.Control
                                name={`token`}
                                value={csrfToken}
                                type="hidden"
                            />
                        </Form.Group>
                        {user?.isRegisteredUser && <Alert variant='warning' className='mt-2'><i className='fas fa-exclamation-triangle'/> Ce contact a un accès back-office: l'éditer modifiera aussi ses informations d'accès.</Alert>}

					</InputGroup>
				</Row>
                {typeof createFormErrors === "string" && <Alert variant='danger'>{createFormErrors}</Alert>}

                
            </Form>
        </div>
    )

}

const checkEmail = (email, isRequired) => {
    const validEmail = () => {
        try {
            const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w*)+$/;
            return emailRegex.test(email);
        } catch {
            return false;
        }
    }

    if(isRequired) {
        return email && validEmail(email);
    } else {
        return !email || validEmail(email);
    }
}

function checkFields(formData) {
    for (const field of formData.entries()) {
        if(field[1] && field[0] !== 'token') return true;
    }
    return false;
}

export const submitUserForm = (action, formName, uniqueId, clientInfoToCheck = null, fullDBInfoToCheck = null, isEmailRequired = true) => {

    const formData = new FormData(document.getElementById(formName));

    if (!checkFields(formData)) {
        return Promise.reject({formError: "Vous devez renseigner au moins un champ du formulaire"});  
    }

    const email = formData.get(`email`).trim();
    const isExistingContact = clientInfoToCheck ? (email && email !== '' && clientInfoToCheck.includes(email)) : false;
    const required = formData.get('firstFormationRequired');
    if(required){
        formData.append('firstFormationRequired', "required");
    }
    else{
        formData.append('firstFormationRequired', "");
    }

    if (!checkEmail(email, isEmailRequired)) {
        return Promise.reject({formError: {email: "Email non valide"}});  
    }

    if (isExistingContact) {
        return Promise.reject({formError: "Un contact avec cet email existe déjà chez ce client."});  
    }

    if (fullDBInfoToCheck && fullDBInfoToCheck.includes(email) && !isExistingContact) {
        return Promise.reject({formError: "Cet email a déjà un accès au back-office."});  
    }

    const payload = {
        formData,
        uniqueId: uniqueId,
    }

    return action(payload)
}

export default CreateUpdateUserComponent;