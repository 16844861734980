import { action, actionOn, thunk } from "easy-peasy";
import { store } from './index.js';
import axios from '../services/axios';


const usersInitialState = {
    allUsers: [],
    isFetchingAllUsers: false,
    isUpdateNecessary: false,
    initialFetchDone: false,
    createFormErrors: {},
    allSessionsByIdForUser: [],
    isFetchingAllSessionsByIdForUser: false,
    isMergeClientsPropsInAllUsersNecessary: true
}

const users = {
    ...usersInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({
                    allUsers: state.allUsers,
                    isFetchingAllUsers: state.isFetchingAllUsers,
                    isUpdateNecessary: state.isUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    createFormErrors: state.createFormErrors,
                    allSessionsByIdForUser: state.allSessionsByIdForUser,
                    isFetchingAllSessionsByIdForUser: state.isFetchingAllSessionsByIdForUser,
                    isMergeClientsPropsInAllUsersNecessary: state.isMergeClientsPropsInAllUsersNecessary
                } = usersInitialState);
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setCreateFormErrors: action((state, payload) => {
        state.createFormErrors = payload;
    }),

    setAllUsers: action((state, payload) => {
        state.allUsers = payload;
    }),
    setIsFetchingAllUsers: action((state, payload) => {
        state.isFetchingAllUsers = payload;
    }),
    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),
    setIsMergeClientsPropsInAllUsersNecessary: action((state, payload) => {
        state.isMergeClientsPropsInAllUsersNecessary = payload;
    }),

    updateUserInStore: action((state, payload) => {
        const idxUser = state.allUsers.findIndex((user) => user.uniqueId === payload.uniqueId);
        if (idxUser !== -1) {
            const oldUser = state.allUsers[idxUser];
            state.allUsers[idxUser] = { ...oldUser, ...payload };
        } else {
            state.allUsers.push(payload);
        }
    }),


    updateMultipleUsersInStore: thunk((state, payload, helpers) => {
        const { updateUserInStore } = helpers.getStoreActions().users;
        payload.map((user)=>{
            updateUserInStore(user);
        })
    }),


    removeFromAllUsers: action((state, payload) => {
        const indexToRemove = state.allUsers.findIndex(user => user.uniqueId === payload);
        state.allUsers.splice(indexToRemove, 1);
    }),

    fetchUsers: thunk((actions, payload, helpers) => {
        const { isFetchingAllUsers, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const fetchUrl = ACTIONS?.users?.readAll?.url;
        if (!isFetchingAllUsers && isUpdateNecessary) {
            actions.setIsFetchingAllUsers(true);

            return axios.get(fetchUrl)
                .then(data => {
                    actions.setAllUsers(data.users);
                    actions.setIsUpdateNecessary(usersInitialState.isUpdateNecessary);
                    return data.users;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingAllUsers(false))
        } else {
            return Promise.resolve();
        }
    }),

    // Merge props from allClients to allUsers only if not already merged (tagOperations, categories, subscriptionEndDate, status)
    mergeClientsPropsInAllUsers: thunk((actions, payload, helpers) => {
        const { allClients } = helpers.getStoreState().clients;
        const { allUsers } = helpers.getStoreState().users;
        const { hasAccessToClientsProps } = payload;

        if(hasAccessToClientsProps && allClients.length > 0 && allUsers.length > 0) {
            const newAllUsers = allUsers
                .map(user => {
                    const client = allClients.find((c) => user.clientUniqueId === c.uniqueId);
                    return {
                        ...user, 
                        tagOperations: client?.tagOperations ?? null,
                        categories: client?.categories ?? null,
                        subscriptionEndDate: client?.subscriptionEndDate ?? null,
                        status: client?.status ?? null
                    }
                })
            actions.setAllUsers(newAllUsers);
            actions.setIsMergeClientsPropsInAllUsersNecessary(false);
        }
    }),

    createUser: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const createUsersUrl = apiData?.ACTIONS?.users[formData.has("email") ? 'create' : 'createFromContact']?.url?.replace('uniqueId', uniqueId);
        const { setClientById } = helpers.getStoreActions().clients;
        return axios.post(createUsersUrl, formData)
            .then(({ clientInfos, clientCRMInfos, userInfos }) => {
                actions.updateUserInStore(userInfos);
                const { clientById } = helpers.getStoreState().clients;
                if (clientById && clientById.uniqueId === clientInfos.uniqueId) {
                    setClientById(clientInfos);
                }
                if (clientCRMInfos) {
                    store.getActions().clients.setClientCRMById(clientCRMInfos);
                }
                return clientInfos;
            })
            .catch((error) => Promise.reject(error))
    }),

    updateUser: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const updateUsersUrl = apiData.ACTIONS?.users?.update?.url?.replace('uniqueId', uniqueId);
        const { setClientById } = helpers.getStoreActions().clients;

        return axios.post(updateUsersUrl, formData)
            .then((response) => {
                if (response.userInfos) {
                    actions.updateUserInStore(response.userInfos);
                }
                const { clientById } = helpers.getStoreState().clients;
                if (response.clientInfos) {
                    if (clientById && clientById.uniqueId === response.clientInfos.uniqueId) {
                        setClientById(response.clientInfos);
                    }
                }
                if (response.clientCRMInfos) {
                    store.getActions().clients.setClientCRMById(response.clientCRMInfos);
                }
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }),



    transferUser: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const updateUsersUrl = apiData.ACTIONS?.users?.transfer?.url?.replace('uniqueId', uniqueId);
        const { setClientById, setClientCRMById } = helpers.getStoreActions().clients;

        return axios.post(updateUsersUrl, formData)
            .then((response) => {
                if (response.userInfos) {
                    actions.updateUserInStore(response.userInfos);
                }
                const { clientById } = helpers.getStoreState().clients;
                if (response.clientInfos) {
                    if (clientById && clientById.uniqueId === response.clientInfos.uniqueId) {
                        setClientById(response.clientInfos);
                    }
                }
                if (response.clientCRMInfos) {
                    setClientCRMById(response.clientCRMInfos);
                }
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }),


    deleteUser: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const formData = new FormData();
        formData.set(`${apiData.ACTIONS?.users?.delete?.formName}[token]`, apiData.ACTIONS?.users?.delete?.csrfToken)
        const deleteUserUrl = apiData.ACTIONS?.users?.delete?.url?.replace('uniqueId', uniqueId);

        return axios.delete(deleteUserUrl, formData)
            .then(({ clientInfos, clientCRMInfos, userUniqueId }) => {
                actions.removeFromAllUsers(userUniqueId);
                const { clientById, clientCRMById } = helpers.getStoreState().clients;
                const { setClientById, setClientCRMById } = helpers.getStoreActions().clients;
                if (clientById && clientById.uniqueId === clientInfos.uniqueId) {
                    setClientById(clientInfos);
                }
                if (clientCRMById && clientCRMInfos && clientCRMById.uniqueId === clientCRMInfos.uniqueId) {
                    setClientCRMById(clientCRMInfos);
                }
            })
            .catch(error => Promise.reject(error));
    }),

    deleteContact: thunk((actions, payload, helpers) => {
        const { uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const formData = new FormData();
        formData.set(`${apiData.ACTIONS?.users?.deleteContact?.formName}[token]`, apiData.ACTIONS?.users?.deleteContact?.csrfToken)
        const deleteContactUrl = apiData.ACTIONS?.users?.deleteContact?.url?.replace('uniqueId', uniqueId);

        return axios.delete(deleteContactUrl, formData)
            .then((clientCRMInfos) => {
                const { clientCRMById } = helpers.getStoreState().clients;
                const { setClientCRMById } = helpers.getStoreActions().clients;
                if (clientCRMById && clientCRMById.uniqueId === clientCRMInfos.uniqueId) {
                    setClientCRMById(clientCRMInfos);
                }
            })
            .catch(error => {
                Promise.reject(error);
            });
    }),

    createContact: thunk((actions, payload, helpers) => {
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const createContactUrl = apiData.ACTIONS?.users?.createContact?.url?.replace('uniqueId', uniqueId);

        return axios.post(createContactUrl, formData)
            .then((result) => {
                if (result.clientCRMInfos) {
                    store.getActions().clients.setClientCRMById(result.clientCRMInfos);
                }
                return result.clientCRMInfos;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }),

    fetchContactByUniqueId: thunk((actions, payload, helpers) => {
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const readContactUrl = apiData.ACTIONS?.users?.readOneContact?.url?.replace('uniqueId', payload);

        return axios.get(readContactUrl)
            .then((result) => {
                return result;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }),

    setAllSessionsByIdForUser: action((state, payload) => {
        state.allSessionsByIdForUser = payload;
    }),

    setIsFetchingAllSessionsByIdForUser: action((state, payload) => {
        state.isFetchingAllSessionsByIdForUser = payload;
    }),

    fetchFormationSessionsByIdForUser: thunk((actions, payload, helpers) => {
        const { isFetchingAllSessionsByIdForUser } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.users?.getProps?.getFormationSessions?.url?.replace('uniqueId', payload);

        if (!isFetchingAllSessionsByIdForUser) {
            actions.setIsFetchingAllSessionsByIdForUser(true);
            actions.setAllSessionsByIdForUser(usersInitialState.allSessionsByIdForUser);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllSessionsByIdForUser(data);
                    return data;
                })
                .catch(error => {
                    throw error.errors;
                })
                .finally(() => {
                    actions.setIsFetchingAllSessionsByIdForUser(usersInitialState.isFetchingAllSessionsByIdForUser);
                })
        } else {
            Promise.resolve();
        }
    }),

    updateLastMessageClosed: thunk((actions, payload, helpers) => {

        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const updateUsersUrl = apiData.ACTIONS?.users?.lastMessageClosed?.url;
        payload.token = apiData.ACTIONS?.users?.lastMessageClosed?.csrfToken;

        return axios.post(updateUsersUrl, payload)
            .then((response) => {
                if (response.userInfos) {
                    actions.updateUserInStore(response.userInfos);
                }
                return response;
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }),

}

export default users;