import React, { useEffect, useState } from "react";
import DynamicTableInfinite from "../widgets/dynamicTableInfinite/DynamicTableInfinite";
import useInfiniteLoadingLogic from "../../hooks/useInfiniteLogic";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from 'react-router-dom';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip, Image } from 'react-bootstrap';

/**
 * 
 * @param {Array} transversalProps array of all transversalProps to be rendered
 * @param {String} readAll  readAll slug to access the transversalProp list page
 * @param {String} readOne  readOne slug to access a transversalProp details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the transversalProp creation page - optional, need according rights
 * @param {String} update  update slug to access the transversalProp update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 
 * @example
 *  <MetiersTableComponent transversalProps={transversalProps} readAll={readAllSlug} create={createSlug} {...}/>
 * 
 */

const TransversalPropsTableComponent = ({ transversalProps = [], withParams, transversalProp }) => {
    const [transversalPropToDisplay, settransversalPropToDisplay] = useState([]);
    const [sortedtransversalProp, setSortedtransversalProp] = useState([]);
    const [shouldSort, setShouldSort] = useState(false);

    const transversalPropsSlugs = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher(transversalProp));
    const navigate = useNavigate();

    useEffect(() => {
        setShouldSort(true);
        setSortedtransversalProp([...transversalProps]);
    }, [transversalProps]);

    const { hasMore, fetchNext: fetchData } = useInfiniteLoadingLogic(sortedtransversalProp, transversalPropToDisplay, settransversalPropToDisplay, 100);

    if (transversalProps.length === 0) {
        return <></>;
    }

    const transversalPropLabel = (transversalProp) => {
        switch (transversalProp) {
            case 'areasOfInterest':
                return "Centre d'intérêt";
            case 'workingConditions':
                return 'Contexte de travail';
            case 'skills':
                return 'Compétences';
            default:
                return 'Non défini';
        }
    };

    const sortType = [
        {
            value: 'label', label: transversalPropLabel(transversalProp),
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'label'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (transversalProp) => transversalProp.label
        },
        {
            value: 'thumbnail', label: 'Vignette',
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'thumbnail'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.label, b.label),
            display: (transversalProp) => (
                <OverlayTrigger
                    placement='right'
                    overlay={
                        <Tooltip>
                            <Image src={transversalProp.thumbnail} />
                        </Tooltip>
                    }
                >
                    <Image src={transversalProp.thumbnail} className="img-overlay" thumbnail />
                </OverlayTrigger>
            ),
            flatDisplay: (transversalProp) => transversalProp.thumbnail
        },
        {
            value: 'category', label: 'Catégorie',
            test: HelperMetiers360.isArrayContainsValue(transversalProps, 'category'),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.category, b.category),
            display: (transversalProp) => HelperMetiers360.categoryWorkingConditionsLabel(transversalProp.category)
        },
        // {
        //     value: 'usage', label: 'Utilisation dans ROME associé',
        //     test: transversalProps[0].usage !== undefined,
        //     method: (a, b) => b.usage - a.usage,
        //     display: (transversalProp) => transversalProp.usage
        // },
        {
            value: 'pros', label: 'Pros',
            test: transversalProps[0].pros !== undefined,
            method: (a, b) => b.pros - a.pros,
            display: (transversalProp) => transversalProp.pros
        },
    ];

    const handleClick = (codetransversalProp) => {
        if (transversalPropsSlugs.readOne) {
            navigate(transversalPropsSlugs.readOne.replace(':uniqueId', codetransversalProp));
        }
    }

    return (
        <DynamicTableInfinite
            contentTable={transversalPropToDisplay}
            contentSort={sortType}
            valueInitSort="pros"
            index='code'
            fetchData={fetchData}
            hasMore={hasMore}
            setSortedContent={setSortedtransversalProp}
            sortedContent={sortedtransversalProp}
            sortState={[shouldSort, setShouldSort]}
            withParams={withParams}
            handleClick={handleClick}
            filename={transversalPropLabel(transversalProp).toLocaleLowerCase().replace(" ", "_")}
        />
    );
}

TransversalPropsTableComponent.propTypes = {
    transversalProps: PropTypes.array,
    withParams: PropTypes.bool.isRequired,
    transversalProp: PropTypes.string.isRequired
};

export default TransversalPropsTableComponent;