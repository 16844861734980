import React, { useState, useEffect }  from 'react';
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import Loader from "../../components/widgets/Loader.jsx";
import { useModal } from '../../hooks/useModal.jsx';
import UserTableComponent from '../../components/users/usersList/UserTableComponent.jsx';
import AuthorizationChecker from '../../services/AuthorizationChecker.js';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import { useStoreActions } from "easy-peasy";
import withURLParamsTableComponent from '../../HOC/withURLParamsTableComponent.jsx';
import useFreshworkWidget from '../../hooks/useFreshworksWidget.jsx';
import CreateMultiUsersOrContactsComponent from '../../components/clients/multiUsersOrContacts/CreateMultiUsersOrContactsComponent.jsx';
import UsersFiltersComponent from '../../components/users/usersList/UsersFiltersComponent.jsx'

const UserTableWithParams = withURLParamsTableComponent(UserTableComponent);

const UsersPage = () => {
    const {allUsers, isFetchingAllUsers: usersAreLoading, isMergeClientsPropsInAllUsersNecessary, isUpdateNecessary } = useCustomGetStoreState("users");
    const { mergeClientsPropsInAllUsers} = useStoreActions(actions => actions.users);
    const { allClients, isAllClientPropsAreMerged, initialFetchDone:initialFetchDoneClient } = useCustomGetStoreState("clients");
    const { fetchAllClientsAndMergeWithProps } = useStoreActions(actions => actions.clients);
    

    const {openWidgetContactForm} = useFreshworkWidget();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();

    useEffect(() => {
        initialFetchDoneClient && fetchAllClientsAndMergeWithProps({
            hasAccessToAllPropsClientsCRM: AuthorizationChecker.hasGetPropsForAllRights('clientsCRM')});
    }, [initialFetchDoneClient]);

    const shouldMergeClientsPropsInAllUsers = isMergeClientsPropsInAllUsersNecessary && isAllClientPropsAreMerged && !isUpdateNecessary;
    
    useEffect(() => {
        if(shouldMergeClientsPropsInAllUsers) mergeClientsPropsInAllUsers({hasAccessToClientsProps: AuthorizationChecker.hasReadAllRights('clientsCRM')});
    }, [shouldMergeClientsPropsInAllUsers]);

    const isNoUsers = !usersAreLoading && !allUsers.length;
    const isNoResult = !usersAreLoading && allUsers?.length > 0 && filteredUsers?.length === 0;

    const createMultiUserModal = () => {
        setModalData({
            ...modalData,
            header: <>Ajouter plusieurs utilisateur⋅rices</>,
            content: <CreateMultiUsersOrContactsComponent entityType="user" />,
            size: 'lg',
            id: 'client-create-multi-user-modal'
        });
        setModalShow(true);
    }

    return <Container fluid>
        <Row>
            {!AuthorizationChecker.isAdmin() &&
                <Col>
                    <h2>Vos différent⋅es utilisateur⋅rices</h2>
                    <Alert variant="info">
                        Les utilisateur⋅rices de votre réseau possédant un accès à leur espace.<br />
                        <Button as="a" bsPrefix={'no-btn'} onClick={openWidgetContactForm}>Contactez-nous</Button> pour ajouter de nouveaux⋅elles
                        collaborateur⋅rices pouvant se connecter au backoffice.
                    </Alert>
                </Col>
            }
            {AuthorizationChecker.isAdmin() && 
                <Row className='pe-0'>
                    <Col>
                        <Alert variant='info' className='mt-2'>
                            <i className="fas fa-plus me-2"/>
                            Pour créer un accès, merci de créer un contact dans l'onglet CRM puis de lui donner l'accès BO.
                            <br/>
                            <i className="fas fa-edit me-2"/>
                            Pour modifier, transférer ou supprimer un accès, merci d'utiliser l'onglet CRM de l'établissement.
                            <br/>
                        </Alert>
                    </Col>
                    <Col className='d-flex justify-content-end align-items-center pe-0' xs={3}>
                        <Button variant="success" onClick={createMultiUserModal}><i className="fas fa-plus"/>&nbsp; Ajout multiple</Button>
                    </Col>
                </Row>
            }
        </Row>
        {!usersAreLoading && <UsersFiltersComponent allUsers={allUsers} setFilteredUsers={setFilteredUsers}
            allParentClients={allClients.filter(client => client.childrenCount > 0)} 
            displayFilterZone={AuthorizationChecker.isAdmin()} />}
        <Row>
            { (usersAreLoading) && <Loader /> }
            {isNoResult && <Alert className="mt-3" variant="warning">
                Aucun⋅e utilisateur⋅rice ne correspond à votre recherche
                </Alert>}
            {isNoUsers && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun⋅e utilisateur⋅rice</Alert>}
            {filteredUsers.length > 0 && <UserTableWithParams users={filteredUsers} full={true}
                enableToControlVisibleColumns={true}
                isLoading={AuthorizationChecker.hasReadAllRights('clientsCRM') && !isAllClientPropsAreMerged}/>}
        </Row>
        {modalComponent}
    </Container>
}

export default UsersPage;