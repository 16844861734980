import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Button, Col, Row } from "react-bootstrap";


import UploadStoryComponent from "../../components/stories/UploadStoryComponent.jsx";
import Loader from "../../components/widgets/Loader.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";


const UploadStoryPage = (props) => {
    const { uniqueId } = useParams(); 
    
    const [isLoading, setIsLoading] = useState(false);

    const { storyById } = useStoreState(state => state.stories);
    const storySlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("stories"));

    const { fetchStoryById, postStory, updateStory } = useStoreActions((actions) => actions.stories);

    useEffect(() => {

        setIsLoading(true);
        fetchStoryById(uniqueId)
            .finally(() => setIsLoading(false));

	}, []);

    const {backButtonComponent} = useBackButton(({
        path:storySlugs.readOne.replace(':uniqueId', storyById && storyById.uniqueId),
        mediaType: "stories",
        shouldReplaceHistory: true, 

    }))
    
	return (
		<div className="container p-2">
            {
                (isLoading) ? 
                <Loader />
                : <> 
                    <Row className="mb-3">
                        <Col className="d-flex justify-content-between align-items-center">
                            <h2>
                                Mise à jour de la story : {storyById && storyById.name ? storyById.name : "Mise à jour d'une story"}
                            </h2>
                            {backButtonComponent}
                        </Col>
			        </Row>
                    <UploadStoryComponent 
                    storyToUpdate={storyById} 
                    postStory={postStory} 
                    updateStory={updateStory} 
                    action="update"
                    {...storySlugs}
                    />
                </>
            }
			
		</div>
	);
};

export default UploadStoryPage;
