import { useStoreState } from 'easy-peasy';
import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

/**
 * 
 * @param {object} client client infos
 * @param {array} list a list of medias (either array of quiz or array of videos) from store
 * @param {string} mediaType  ("video"|"quiz") - will determine if the form concerns videos or quizzes
 * 
 * @example <WhiteListFormComponent client={client} list={allVideos} mediaType={"video"}>
 */
const WhiteListFormComponent = (props) => {
    const { client, list, mediaType, actionName } = props;
    const {apiData} = useStoreState(state => state.actionSlugs);

    const {formName, csrfToken} = apiData.ACTIONS['clients']['editProps'][actionName];

    const [mediaList, setMediaList] = useState([...list]);

    const restrictiveValues = {
        video: client?.videoListIsRestrictive ? client.videoListIsRestrictive : false,
        quiz: client?.quizListIsRestrictive ? client.quizListIsRestrictive : false,
        group: client?.GroupListIsRestrictive ? client.GroupListIsRestrictive : false
    }

    const formWhiteListLabel = {
        video:'Vidéos exclusives',
        quiz:'Quiz exclusifs',
        group:'Groupes exclusifs'
    }

    const [isRestrictiveList, setIsRestrictiveList] = useState(restrictiveValues[mediaType]);

    useEffect(() => {
        setMediaList([...list]);
    }, [list])

    const handleRestrictiveCheckBox = (event) => {
        const checkBox = event.currentTarget;
        setIsRestrictiveList(checkBox.checked);
      };

    const now = Date.now();

    const whiteCheckBoxList = 
    mediaList.filter((currentMediaElem) => {
                if (isRestrictiveList) {
                    return true;
                } else {
                    const releaseDate = new Date(currentMediaElem.releaseDate);
                    return currentMediaElem.private || releaseDate > now;
                }
            })
            .sort((t1, t2) => t1.position - t2.position)
            .map((currentMediaElem) => {

                return (
                <Form.Check
                    type="checkbox"
                    id={`client_${mediaType}List_${currentMediaElem.uniqueId}`}
                    key={`client_${mediaType}List_${currentMediaElem.uniqueId}`}
                >
                    <Form.Check.Input
                    type="checkbox"
                    value={currentMediaElem.uniqueId}
                    name={`${formName}[${mediaType}List][]`}
                    defaultChecked={
                        client && client[`${mediaType}List`] ? client[`${mediaType}List`].includes(currentMediaElem.uniqueId) : null
                    }
                    />
                    <Form.Check.Label>
                        {currentMediaElem.position} -{" "}
                        <i
                            className={
                                currentMediaElem.private ? "fas fa-lock" : "fas fa-lock-open"
                            }
                            aria-hidden="true"
                        />{" "}
                        {/* //todo Make sure quiz name property is named quizName! */}
                        {currentMediaElem[`${mediaType}Name`] || currentMediaElem[`name`]}
                    </Form.Check.Label>
                </Form.Check>
            )});
     
    const blackCheckBoxList = (mediaType === 'video')
        && mediaList
            .filter((videos) => !videos.private)
            .sort((v1, v2) => v1.position - v2.position)
            .map((video) => (
                <Form.Check
                type="checkbox"
                id={`client_blackList_${video.uniqueId}`}
                key={`client_blackList_${video.uniqueId}`}
                >
                <Form.Check.Input
                    type="checkbox"
                    value={video.uniqueId}
                    name={`${formName}[blackList][]`}
                    defaultChecked={
                    client && client.videoBlackList ? client.videoBlackList.includes(video.uniqueId) : null
                    }
                />
                <Form.Check.Label>
                    {video.position} -{" "}
                    <i
                    className={
                        video.private ? "fas fa-lock" : "fas fa-lock-open"
                    }
                    aria-hidden="true"
                    />{" "}
                    {video.videoName}
                </Form.Check.Label>
                </Form.Check>
            ));

    return (
        <Form id={`${formName}-form`} method="post" encType="multipart/form-data">
            <Row>
                {/* WHITE CHECKBOX LIST */}
                <Form.Group as={Col} controlId="formWhiteList">
                    <h3>{formWhiteListLabel[mediaType]} (whitelist)</h3>
                    <Form.Check type="checkbox" id={`client_${mediaType}ListIsRestrictive`} >
                        <Form.Check.Input
                            name={`${formName}[${mediaType}ListIsRestrictive]`}
                            type="checkbox"
                            value="1"
                            defaultChecked={restrictiveValues[mediaType]}
                            onChange={handleRestrictiveCheckBox}
                        />
                        <Form.Check.Label>
                            {" "}
                            <b>Cochez s'il s'agit d'une liste restrictive</b>{" "}
                            <i className="fas fa-question-circle"></i>
                        </Form.Check.Label>
                    </Form.Check>
                    { whiteCheckBoxList}
                </Form.Group>
                {/* BLACK CHECKBOX LIST */}
                { mediaType !== 'video' || isRestrictiveList ? null : (
                    <Form.Group as={Col} controlId="formBlackList">
                        <h3>Vidéos non autorisés (blacklist)</h3>
                        { blackCheckBoxList }
                    </Form.Group>
                )}
            </Row>
            {/* HIDDEN INPUT */}
            <Form.Group>
                <Form.Control
                    name={`${formName}[_token]`}
                    defaultValue={csrfToken}
                    type="hidden"
                />
            </Form.Group>
        </Form>
    )
}

export const submitWhiteListForm = (formId, updateAction, uniqueId) => {
    const formData = new FormData(document.getElementById(formId));

    return updateAction({formData, uniqueId});
}

export default WhiteListFormComponent;