import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { Container, Row, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CardGroupMediaComponent from '../../widgets/CardGroupMedia/CardGroupMediaComponent.jsx';

import Loader from '../../widgets/Loader.jsx';

import './RomeDetailsContentsComponent.scss';

const RomeDetailsContentsComponent = (props) => {
    const { romeStories, isFetchingStories, romeVideos, isFetchingAllVideos } = props;
    const { readOne: readOneStory } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('stories'));
    const { readOne: readOneVideo } = useStoreState(actions => actions.actionSlugs.actionSlugsDispatcher('videos'));

    const [isVideosOpen, setIsVideosOpen] = useState(true);
    const [isStoriesOpen, setIsStoriesOpen] = useState(true);

    const isUsedInStory = !!romeStories?.length;
    const isUsedInVideo = !!romeVideos?.length;

    return (<Card className="contents_details_card">
        <Card.Title className="contents_details_title fw-bold">
            {isUsedInStory && isUsedInVideo ? 'Ressources associées' : 'Ressource associée'}
        </Card.Title>
        <Card.Body className="contents_details_body">
            {(isUsedInStory || isUsedInVideo)
                ? <Container>
                    <Row>
                        {isUsedInVideo && !isFetchingAllVideos &&
                            <div className="related_content_details_item">
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between"
                                    onClick={() => setIsVideosOpen(!isVideosOpen)}>
                                    {romeVideos.length} {romeVideos.length > 1 ? 'expériences immersives' : 'expérience immersive'}
                                    {isVideosOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isVideosOpen
                                    && <CardGroupMediaComponent mediaType="videos" mediaList={romeVideos} readOne={readOneVideo} />
                                }
                            </div>
                        }
                        {isUsedInStory && !isFetchingStories &&
                            <div className="related_content_details_item">
                                <Card.Title
                                    className="associated_card_title d-flex justify-content-between"
                                    onClick={() => setIsStoriesOpen(!isStoriesOpen)}>
                                    {romeStories.length} {romeStories.length > 1 ? 'stories' : 'story'}

                                    {isStoriesOpen
                                        ? <i className="fa fa-angle-up fa-lg"></i>
                                        : <i className="fa fa-angle-down fa-lg"></i>
                                    }
                                </Card.Title>
                                {isStoriesOpen
                                    && <CardGroupMediaComponent mediaType="stories" mediaList={romeStories} readOne={readOneStory} />
                                }
                            </div>
                        }
                        {(isFetchingAllVideos || isFetchingStories) && <Loader />}
                    </Row>
                </Container>
                : <p className='ms-4'>Aucune ressource</p>
            }
        </Card.Body>
    </Card>);
}

RomeDetailsContentsComponent.propTypes = {
    romeVideos: PropTypes.array.isRequired,
    romeStories: PropTypes.array.isRequired,
    isFetchingStories: PropTypes.bool.isRequired,
    isFetchingAllVideos: PropTypes.bool.isRequired,
};

export default RomeDetailsContentsComponent;