import React, { useState, useEffect }  from 'react';
import { Container, Row, Col, Alert} from 'react-bootstrap';

import WebappAccessTableComponent from '../../components/webappAccess/WebappAccessTableComponent.jsx';
import MyWebappAccessesComponent from '../../components/webappAccess/MyWebappAccessesComponent.jsx';

import Loader from '../../components/widgets/Loader.jsx';
import useCustomGetStoreState from '../../hooks/useCustomGetStoreState.jsx';
import useSearchWithParams from '../../hooks/useSearchWithParams.jsx';
import withURLParamsTableComponent from '../../HOC/withURLParamsTableComponent.jsx';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';


const WebappAccessTableWithParams = withURLParamsTableComponent(WebappAccessTableComponent);

const WebappAccessesPage = () => {
    const {allWebappAccesses, isFetchingWebappAccesses} = useCustomGetStoreState("webappAccesses")

    
    const [filteredWebappAccesses, setFilteredWebappAccesses] = useState(allWebappAccesses);

    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par Code et Établissement', delay: 400})


    useEffect(() => {
        setFilteredWebappAccesses(allWebappAccesses);
    }, [allWebappAccesses])

    useEffect(() => {
        const filterWebappAccesses = (search) => {
            if (search === '') {
                return allWebappAccesses
            }
            return allWebappAccesses.filter(webappAccess => 
                HelperMetiers360.isSearchInText(search, webappAccess.code)
                || HelperMetiers360.isSearchInText(search, webappAccess.clientName))
        }

        setFilteredWebappAccesses([...filterWebappAccesses(search)]);
    }, [search, allWebappAccesses])

    const isNoWebapp = !isFetchingWebappAccesses && !allWebappAccesses.length
    const isNoResult = !isFetchingWebappAccesses && allWebappAccesses.length && !filteredWebappAccesses.length;

    const allWebappAccessesPanel = allWebappAccesses.length > 1 &&
        <>
            <Row>
                <Col>
                    <h2>Utilisation des différents codes</h2>
                </Col>
                
            </Row>
            <Row>
                <Col>{searchInput}</Col>
            </Row>
            {filteredWebappAccesses.length > 0 && <WebappAccessTableWithParams webappAccesses={filteredWebappAccesses} />}
            {isNoResult &&
                <Alert className="mt-3" variant="warning">
                    Aucun accès webapp ne correspond à votre recherche
                </Alert>
            }
        </>

    return (
        <Container fluid>
            <MyWebappAccessesComponent />
            {allWebappAccessesPanel}
            { (isFetchingWebappAccesses) && <Loader /> }
            {
                isNoWebapp && <Alert className="mt-3" variant="warning">Vous n'avez pas d'accès à la Webapp</Alert>
            }
        </Container>
    );
}

export default WebappAccessesPage;