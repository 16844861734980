const faqData = [
    {
    category: "casque",
    label: "Questions relatives au casque",
    labelVideo: "Quelques tutos vidéo pour bien démarrer...",

    videos: [
        {
            title: <>
                        <h5>3 boutons pour démarrer</h5>
                    </>,
            link: "https://www.youtube.com/embed/B97Pk62ofto",

            image: <>
                        <img src="https://www.metiers360.com/wp-content/uploads/2022/05/3-boutons.png" />
                    </>,
        },

        {
            title: <>
                        <h5>Naviguer dans l'application</h5>
                    </>,

            link: "https://www.youtube.com/embed/zA1jNXUvxY8",

            
            image: <>
                        <img src="https://www.metiers360.com/wp-content/uploads/2022/05/Capture-décran-2022-05-14-à-14.14.41.png" />
                    </>,
        },


        {
            title: 
            <>
                 <h5>Recentrer la vue</h5> 
            </>,

            link: "https://www.youtube.com/embed/VISpn7DyH64",

            image: 
            
            <>
                <img src="https://www.metiers360.com/wp-content/uploads/2022/05/pico-1024x683.jpeg"/>
            </>
        }


    ],
    questions: [

        {questionLabel: <>Comment charger les nouvelles vidéos ?</>,
        answerLabel: <><p>Tous les 15 du mois, nous enrichissons notre catalogue de vidéos 360 de deux nouveaux épisodes soit environ 7 à 10 nouveaux métiers. Vous avez donc intérêt à mettre à jour vos casques pour proposer des expériences de découverte plus nombreuses&#8230; Comment faire ? Vous avez <strong>deux possibilités.</strong></p><ul><li> Connecter votre casque à un <strong>réseau wifi</strong>.<ul><li>Une fois connecté au wifi, votre casque interroge automatiquement nos serveurs pour connaître les nouvelles ressources qui se chargent directement. </li><li>Dans le casque vous voyez les vidéos se charger, elles sont ensuite stockées localement dans le casque.</li><li>Le temps de l&rsquo;opération est fonction de la qualité de votre wifi, comptez environ une minute par vidéo si vous avez la fibre.</li></ul></li><li>Brancher vos casques à un PC Windows équipé du <strong>loader</strong>. <ul><li>Le loader est une application gratuite conçue pour PC. Elle vous permet de mettre à jour simultanément plusieurs casques.</li><li>Consulter les infos sur le loader ci-dessous pour en savoir plus.</li></ul></li></ul></>
        },

        {
            questionLabel: 
                <>
                    Je n'arrive pas à charger les nouvelles vidéos en Wifi
                </>,
                
            answerLabel: 
                <>
                    <ul><li>La solution la plus simple est d&rsquo;installer le loader sur un PC, vous pourrez faire votre mise à jour plus rapidement et en toute sécurité.</li><li>Si vous n&rsquo;avez pas de PC, il est possible que la version de l&rsquo;application Métiers 360 sur votre casque soit trop ancienne. Vérifiez que la dernière version de Métiers360 est installée – pour ce faire :<ul><li>Oculus : démarrez l’application en étant connecté au Wi-Fi, une notification apparaît vous proposant une mise à jour, acceptez et c’est dans la poche !</li><li>PICO : Vérifiez que l’application est bien fermée : Sortez de l’application et revenez sur la page d’accueil, appuyez deux fois sur le bouton PICO de la manette (bouton avec le logo), les applications ouvertes apparaissent, cliquez sur la croix pour les fermer !<p>Rendez-vous dans le PICO Store, et trouvez Métiers360 dans la catégorie Expériences, cliquez dessus, une mise à jour vous est proposée, le tour est joué !</p></li></ul></li><li>Si le chargement des vidéos reste bloqué à 0% après quelques minutes, le problème est lié à l’espace disponible sur le casque. Supprimez des applications du casque pour libérer de la mémoire.</li></ul>
            </>,
        },

        {
            questionLabel:
                <>
                    Je dois télécharger les vidéos mais ne veux pas garder le casque sur la tête, comment faire ?						
                </>,
            answerLabel:
                <>
                    <ul><li>Pour les Oculus, vous pouvez changer le réglage de mise en veille automatique des casques : Paramètres -&gt; Appareil -&gt; Alimentation -&gt; Mise en veille automatique sur 4 heures. Pensez à le modifier une fois le téléchargement terminé !</li><li>Pour les PICO, couvrez le capteur entre les deux verres avec un objet opaque et posez le casque, vous êtes libre !</li></ul>
                </>,
        },
        {
            questionLabel:

                <>
                    Mon casque ne s'allume plus...
                </>,
            answerLabel:
                <>
                    <ul><li>Vérifier que vous utilisez un chargeur adapté : les chargeurs rapides de téléphones nouvelle génération ne fonctionnent pas.</li><li>Brancher le casque sur secteur plutôt que sur un ordinateur : s’il est complètement vide, il se rechargera plus vite sur secteur.</li><li>Essayer avec un autre câble.</li></ul>
                </>,
        },

        {
            questionLabel:
                <>
                    Je ne trouve pas mon numéro de série de mon casque
                </>,
            answerLabel:
                <>
                    <ul><li>Oculus : Le numéro de série se trouve derrière la sangle gauche, sur la partie extérieure du casque. Ne pas confondre avec le numéro de série écrit sur la boîte qui ne correspond pas à celui du casque.</li><li>PICO : Le numéro de série se trouve directement sur la boîte d’emballage. Si vous n’avez plus la boite, il se trouve dans les paramètres du casque, accessibles directement depuis le menu principal.</li></ul>
                </>,
        },

        {
            questionLabel:
                <>
                    La manette de mon casque Oculus n'est plus reconnue
                </>,
            answerLabel:
                <>
                  <ul><li>s’assurer d’avoir la manette correspondante au casque en essayant les autres manettes à votre disposition</li><li>appuyer longuement sur les 2 boutons de la manette et voir si une lumière apparaît en haut de la manette<ul><li>si elle n’apparaît pas, changer la pile </li><li>si elle apparaît, allumer le casque et appuyer sur les deux boutons de la manette pendant 10 secondes</li></ul></li></ul>
                </>,
        },
        
        {
            questionLabel:
                <>
                   Je souhaite utiliser mon Oculus Quest
                </> ,
            answerLabel:
                <>
                    <p>Pour installer Métiers360 sur un casque Oculus Quest 2, vous pouvez consulter la documentation ci-dessous : </p>

                    <h3>Prérequis :</h3>		
				
                <p>Effectuez votre installation sur un équipement dont la batterie est totalement chargée afin d’éviter tout problème lié à l’arrêt de votre appareil.<br />Disponible sur PC à partir de Windows 7 ou ultérieur.</p>
                    
                <h3>Etape 1 : Créer un compte Oculus Développeur</h3>		
                                
                    <img width="400" height="429,5" src="https://www.metiers360.com/wp-content/uploads/2021/11/connexion.png" alt="tutoriel Quest 2 étape 1" loading="lazy"  sizes="(max-width: 800px) 100vw, 800px" />
                                                        
                    <p>Rendez vous sur le site internet suivant : <a href="https://developer.oculus.com/sign-up">https://developer.oculus.com/sign-up</a><br />Connectez-vous avec le même compte Facebook que celui utilisé pour vous connecter dans votre application mobile Oculus et dans votre Quest.<br />Il vous sera alors demandé de valider votre identité soit en ajoutant une carte bancaire, soit en vérifiant un numéro de téléphone.</p>					
                                        
                        <h3>Etape 2 : Activer le mode développeur sur le casque</h3>
                            
                        <img width="299" height="512" src="https://www.metiers360.com/wp-content/uploads/2021/11/02.jpg" alt="tutoriel Quest 2 etape 2" loading="lazy" sizes="(max-width: 598px) 100vw, 598px" />
                
                        <p>Une fois que vous êtes déclaré comme développeur par l&rsquo;une de ces deux méthodes, la suite se passe sur votre mobile.<br />Dans l&rsquo;application Oculus, vous aller dans Paramètres, vous sélectionnez le Quest à passer en mode développeur<br />Cliquez sur « Autres Paramètres », « Mode développeur » et vous activez la fonction qui devrait alors passer en bleu.</p>					
                            
                        <img width="352" height="141" src="https://www.metiers360.com/wp-content/uploads/2021/11/03.png" alt="tutoriel quest 2 etape 2 bis" loading="lazy" sizes="(max-width: 528px) 100vw, 528px" />

                        			<h3>Etape 3 : Télécharger et décompressez l'application Métiers360</h3>

			<p>Sur votre PC, téléchargez le pack contenant l&rsquo;application en cliquant <a href="https://www.metiers360.com/download/10875/"><span>ici</span></a>.</p><p>Décompressez le fichier Zip, vous pouvez utiliser pour se faire le logiciel <a href="https://www.7-zip.fr">7zip</a>. (opensource)</p>
						
			<h3>Etape 4 : Installer l'application Métiers360</h3>
								
			<p>Connectez le casque au PC sous windows à l&rsquo;aide d&rsquo;un câble USB et lancez le script d&rsquo;installation (Install.bat)</p>
						
								
			<img width="200" height="154" src="https://www.metiers360.com/wp-content/uploads/2021/11/05.png" alt="tutoriel quest 2 étape 3" loading="lazy" />
						
				
			<p>Il est possible qu&rsquo;il faille confirmer l&rsquo;action depuis le casque.<br />Lorsque le programme d&rsquo;installation affiche « Success » vous pouvez déconnecter le casque.</p>
						
			<h3>Etape 5 : Télécharger les contenus.</h3>
				
				
			<img width="800" height="514" src="https://www.metiers360.com/wp-content/uploads/2021/11/com.oculus.shellenv-20211116-124851.jpg" alt="tutoriel quest 2 etape 4" loading="lazy" sizes="(max-width: 800px) 100vw, 800px" />
						
				

			
			<img width="653" height="554" src="https://www.metiers360.com/wp-content/uploads/2021/11/com.oculus.shellenv-20211116-124616.jpg" alt="tutoriel quest 2 étape 4bis" loading="lazy" sizes="(max-width: 653px) 100vw, 653px" />
			

			
			<p>Une fois l&rsquo;application installée, via le casque, vous pouvez la lancer depuis l&rsquo;onglet « Applications », en cliquant sur « Tout » en haut à droite, puis « Sources inconnues ».<br />L&rsquo;application télécharge ensuite automatiquement le catalogue de vidéos.</p><p>Vous avez installé l&rsquo;application avec succès.</p>
			
                </>,
            
        },

        {
            questionLabel:
                <>
                    Mon casque n’a que la vidéo Maître-Chien d’Avalanche :
                </>,
            answerLabel:
                <>
                    <ul><li>Si vous n’avez que la vidéo Maître-Chien d’Avalanche, c’est que votre abonnement est arrivé à échéance.</li><li>Contactez votre commercial pour mettre à jour votre abonnement à Métiers 360</li></ul>
                </>,
        },

        {
            questionLabel:
                <>
                    Lier la manette à un casque Pico
                </>,

            answerLabel:
                <>
                    <p><span>La manipulation peut être effectuée sur un casque déjà en mode kiosque.</span></p><p> </p><p><span>&#8211; Appuyer en même temps sur « Volume &#8211; » et le boutton du milieu (côté droit du casque).</span></p><p><span>&#8211; Cliquer sur « Système », « Option pour les developpeur », « Star Controller Binding Application » (vers le bas de la liste).</span></p><p><span>&#8211; S&rsquo;assurer que la manette est allumée</span></p><p><span>&#8211; Sélectionner la manette, et appuyer sur le logo « i » puis « Lier »</span></p>
                </>,
        },

        {
            questionLabel:
                <>
                    Device code unknown !
                </>,
            answerLabel:
                <>
                    <p>Depuis mai 2022, il est possible que ce message s&rsquo;affiche sur votre casque Oculus ou Quest s&rsquo;il a été mis à jour récemment.</p><p>La mise à jour d&rsquo;Androïd qui fait tourner votre casque, n&rsquo;autorise plus le partage de l&rsquo;information sur le numéro de série de votre casque. Nous ne le reconnaissons donc plus, et vous n&rsquo;avez plus accès au contenu.</p><p>La solution : faites une mise à jour de votre casque avec le <a href="https://www.metiers360.com/faq-metiers360/comment_installer_application_metiers360_loader/">Loader</a> qui contourne ce problème.</p>
                </>

        },
    ]
},

{
    category: "loader",
    label: "Questions relatives au loader",
    questions: [
        {
            questionLabel: 
                <>
                    Qu'est-ce que le loader ?
                </>,
            answerLabel: 
                <>
                    <div>Le loader est un programme de mise à jour des casques de réalité virtuelle sur ordinateur.</div><div>Une fois installé sur un PC, il suffit de lancer le programme en cliquant sur l’icône Métiers360, de brancher les casques à l’ordinateur à l’aide d’un câble type USB C et le tour est joué !</div><div></div><div><div>Avec le loader vous pouvez donc : </div><ul><li>Mettre à jour le catalogue des vidéos</li><li>Avoir la dernière version de l’application Métiers360 </li><li>Faire remonter les données statistiques d’utilisation sur le back office</li></ul><p>Configuration nécessaire : PC équipé de Windows 10 ou ultérieur, casque VR PICO.</p></div>
                </>
        },

        {
            questionLabel: 
                <>
                    Comment installer le loader ?
                </>,
            answerLabel:
            <>
                <p>L’installation du loader sur un PC est simple et rapide.</p>
                                
                <p>
                    <span>Sur votre PC, téléchargez le Loader&nbsp;:</span><br />
                    <a role="button" className="btn btn-primary"  href="https://cdn.metiers360.com/loader360_client_latest.php" >
                        <i className="fas fa-download"></i> &nbsp;Télécharger le loader
                    </a>
                </p>
                <p>
                    Décompressez le dossier zip, vous pouvez utiliser pour se faire le logiciel <a href="https://www.7-zip.fr/"><b>7zip</b></a>. (opensource)
                </p>
                <p>Lancer le fichier Loader360.exe c'est fait !</p>
            </>,
        },
    ]
},

    {
    category: "backoffice",
    label: "Questions relatives au backoffice",
    questions: [
        {
            questionLabel: 
                <>
                    Qu est ce que le back office
                </>,
            answerLabel:
                <>
                    <p>Client abonné à Métiers 360, vous disposez d&rsquo;une interface web vous permettant de mieux bénéficier de notre solution. Sur le backoffice vous pouvez :</p><ul><li>Voir votre parc de casque VR, connaître l&rsquo;état de la batterie des casques, renommer des casques&#8230;</li><li>Accéder à votre code Webapp et le lien d&rsquo;accès direct.</li><li>Consulter les statistiques des dernières vidéos vues sur vos casques.</li><li>Personnaliser votre expérience (mettre un fond d&rsquo;écran à vos couleurs).</li><li>Contacter votre chargé de clientèle.</li><li>&#8230;</li></ul><p>Nous travaillons constamment à l&rsquo;amélioration de cet espace, faites nous part de vos suggestions !</p><p> </p><p> </p>
                </>,
        },

        {
            questionLabel:
                <>
                    Comment créer un compte pour mes collègues ?
                </>,
            answerLabel: 
                <>
                    <p>Une WebApp est associée à votre abonnement. Vos publics peuvent y accéder gratuitement et sans limite. Vous pouvez leur donner accès avec un code ou une adresse url.</p><p>Pour les trouver, rendez-vous sur votre back-office dans l&rsquo;onglet « WebApp ».</p>
                </>,
        },

        {
            questionLabel: 
                <>
                    Comment imprimer les modules pédagogiques ?
                </>,
            answerLabel:
                <>
                    <p>Vous pouvez cliquer sur un module pédagogique et vous rendre en bas de page, où se trouve un bouton “Télécharger la version pdf” !</p>
                </>,
        },
        
        {
            questionLabel:
                <>
                    Comment changer le fond d'écran de mon application Métiers360 ?
                </>,
            answerLabel: 
                <>
                    <p>Envoyez un mail à votre interlocuteur Métiers360, avec votre logo en pièce jointe, en JPEG ou PNG, elle se chargera de vous mettre en relation avec notre graphiste</p>
                </>,
        },

        {
            questionLabel:
                <>
                    Comment créer une playlist ?
                </>,
            answerLabel:
                <>
                    <ul><li><span>Connectez-vous au back office avec vos identifiants</span></li><li><span>sur la barre à gauche, cliquez sur “playlists »</span></li><li>Donnez un nom à votre playlist et sauvegarder</li><li><span>Cliquez sur les vidéos souhaitées qui apparaissent dans la rubrique catalogue. Avec un simple clic, votre vidéo intègre la playlist. </span></li><li>Sauvegarder votre playlist</li><li>Note : Une fois créée votre playlist, vous pouvez depuis l&rsquo;onglet Casques l&rsquo;affecter à un ou plusieurs casques.</li></ul>
                </>,
        },
    
        {            
            questionLabel:
                <>
                    Comment charger mes propres vidéos ?
                </>,
            answerLabel:
                <>
                    <ul><li><span>L’intégration de vos vidéos dans l’application Métiers 360 n’est pas automatique. Elle se fait par nos équipes en interne selon un mode opératoire bien défini qui permet notamment d&rsquo;encoder les vidéos de façon optimale et de s&rsquo;assurer de leur diffusion privative sur vos seuls casques.</span></li><li><span>Cette prestation est payante. Si vous souhaitez la mettre en place, contactez votre commercial.</span></li></ul>
                </>
        },
    ]},

    {
    category: "webapp",
    label: "Questions relatives à la webapp",
    questions: [
        {
            questionLabel:
                <>
                    Comment avoir accès à la webapp ?
                </>,
            answerLabel: 
                <>
                    <div><p>Une WebApp est associée à votre abonnement. Vos publics peuvent y accéder gratuitement et sans limite. Vous pouvez leur donner accès avec un code ou une adresse url.</p><p>Pour les trouver, rendez-vous sur votre back-office dans l&rsquo;onglet « WebApp ».</p><p><img src="https://www.metiers360.com/wp-content/uploads/2022/05/Diapositive1-300x169.png" alt="où se trouve le code webapp"/></p><p></p></div>
                </>,
        },

        {
            questionLabel: 
                <>
                    Sur quel support consulter la webapp ?
                </>,
            answerLabel: 
                <>
                    <p>Votre webapp est accessible sur mobile (c&rsquo;est le mieux) mais aussi sur PC et tablette.</p>
                </>,
        },

        {
            questionLabel: 
                <>
                    A quoi sert la webapp ?
                </>,
            answerLabel: 
                <>
                    <div><p>C&rsquo;est un outil complémentaire de vos casques de réalité virtuelle. Elle permet de faire découvrir des métiers à partir d&rsquo;anecdotes sur les métiers, parfois avec un peu d&rsquo;humour, de décalage. Vous pouvez donc l&rsquo;intégrer dans votre accompagnement :</p><ul><li>Avant un atelier, par exemple en demandant aux participants de repérer sur la webapp 4 métiers inconnu.</li><li>Pendant un atelier, notamment si vous avez des groupes importants. Un groupe travaille avec les casques, un autre sur PC ou tablettes.</li><li>Après un atelier, en demandant de retrouver sur la webapp des métiers reliés à leur expérience immersive.</li></ul><p>Note : Un bon nombre de nos modules pédagogiques vous propose des activités avec la webapp, n&rsquo;hésitez pas à les consulter !</p></div>
                </>,
        },
    ]
  }
]
export default faqData