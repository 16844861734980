import React, { useState, useEffect, useRef } from "react";
import {useStoreState} from 'easy-peasy';
import { useNavigate } from "react-router-dom";

import {Form, Button, Alert, Image, Row, Col, InputGroup} from 'react-bootstrap';

import { Editor } from "@tinymce/tinymce-react";
import { useModal } from '../../hooks/useModal';

import Jumbotron from "../widgets/jumbotron/Jumbotron";
import ButtonLoaderComponent from "../widgets/ButtonLoaderComponent.jsx";

import SelectMultipleClientsComponent from "../widgets/selectClient/SelectMultipleClientsComponent.jsx";
import UploadMediaComponent from "../widgets/uploadMedia/UploadMediaComponent.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";

import {URLS} from '../../services/axios';
import SelectMultipleItemsWithSearchBar from "../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar.jsx";

import HelperMetiers360 from "../../services/HelpersMetiers360.js";

import DatePicker, { registerLocale } from "react-datepicker";
import { fr } from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";

registerLocale('fr', fr);

const dropzoneOptions = {
	url: URLS.API_URL + "/_uploader/gallery/upload",
    headers: {
		'Cache-Control': null,
		'X-Requested-With': null,

	},
	withCredentials: true,
    maxFileSizeUpload: 2000, 
    chunkSize: 2000000, 
    retryChunksLimit: 3,
    acceptedFiles: "image/jpeg,image/jpg,audio/*,video/*",
}

const UploadStoryComponent = (props) => {

    const navigate = useNavigate();

	const {allVideos} = useCustomGetStoreState("videos");
    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState((state) => state.actionSlugs);

	const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;

	const { postStory, updateStory, storyToUpdate, readOne: readOneStorySlug } = props;
    const { allRomes } = useCustomGetStoreState('romes');

	const { typeOfMedia: TYPE_OF_MEDIA } = BACKOFFICE_DEFAULT_VALUES;
	const { typeOfStory: TYPE_OF_STORY } = BACKOFFICE_DEFAULT_VALUES;

	let mediaTypes=[
		{value : 'video360', label:'Vidéo 360', acceptedFiles:'video/*'}
    ];

	TYPE_OF_MEDIA.forEach(type => {
		mediaTypes.push({value:type.value, label:type.label, acceptedFiles:type.acceptedFiles})
	});

	let initMediaType = 'video360';

    if(storyToUpdate && storyToUpdate.type) {
		if(!(storyToUpdate.type === 'video' && storyToUpdate.is360)) initMediaType = storyToUpdate.type;
    }

	let storyTypes=[
        {value:'regular', label:'Sans type'}
    ];
	TYPE_OF_STORY.forEach(type => {
		storyTypes.push({value:type.value, label:type.label})
	})

	let initStoryType = 'regular';

    if(storyToUpdate && storyToUpdate.typeOfStory && storyToUpdate.typeOfStory.name) {
		initStoryType = storyToUpdate.typeOfStory.name;
    }

	const [mediaType, setMediaType] = useState(initMediaType);
	const [storyType, setStoryType] = useState(initStoryType);
    const [acceptedFiles, setAcceptedFiles] = useState(mediaTypes.filter(type => type.value===mediaType)[0].acceptedFiles);
	const [triggerUploadFile, setTriggerUploadFile] = useState(false);

	const uploadFormRef = useRef(null);
	const [isSuccess, setIsSuccess] = useState(false);
	const [errors, setErrors] = useState({});
	const {modalComponent, setModalShow, setModalData, modalData} = useModal();

	const [numberOfMediaSelected, setNumberOfMediaSelected] = useState(0);
    const [uploadedMedia, setUploadedMedia] = useState(null);

	const fileInput = useRef(null);
	const [name, setName] = useState(storyToUpdate && storyToUpdate.name ? storyToUpdate.name: '');
	const [position, setPosition] = useState(storyToUpdate && storyToUpdate.position ? storyToUpdate.position: '');
	const [thumbnailFileInputName, setThumbnailFileInputName] = useState(storyToUpdate && storyToUpdate.links && storyToUpdate.links.thumbnail ? 'Changer la vignette' : 'Choisir le fichier');
	const [featured, setFeatured] = useState(storyToUpdate && storyToUpdate.featured ? storyToUpdate.featured: false);
	const [sequenceOnly, setSequenceOnly] = useState(storyToUpdate && storyToUpdate.sequenceOnly ? storyToUpdate.sequenceOnly: false);
	const [freemium, setFreemium] = useState(storyToUpdate && storyToUpdate.freemium ? storyToUpdate.freemium: false);
	const [isPrivate, setPrivate] = useState(storyToUpdate && storyToUpdate.private ? storyToUpdate.private : false);
	const [externalLink, setExternalLink] = useState(storyToUpdate && storyToUpdate.links && storyToUpdate.links.external ? storyToUpdate.links.external: '');
	const [selectedRomes, setSelectedRomes] = useState(storyToUpdate && storyToUpdate.romes ? storyToUpdate.romes.map(String) : []);
	const [selectedClients, setSelectedClients] = useState(storyToUpdate && storyToUpdate.producers ? storyToUpdate.producers.map(producer => producer.id).map(String) : []);
	const [editorContentDescription, _setEditorContentDescription] = useState(storyToUpdate && storyToUpdate.description ? storyToUpdate.description: '');
	const editorContentRefDescription = useRef(editorContentDescription);
	const setEditorContentDescription = data => {
		editorContentRefDescription.current = data;
		_setEditorContentDescription(data);
	}
	const [editorContentGreenfact, _setEditorContentGreenfact] = useState(storyToUpdate && storyToUpdate.greenfact ? storyToUpdate.greenfact: '');
	const editorContentRefGreenfact = useRef(editorContentGreenfact);
	const setEditorContentGreenfact = data => {
		editorContentRefGreenfact.current = data;
		_setEditorContentGreenfact(data);
	}

	const [releaseDate, setReleaseDate] = useState(storyToUpdate?.releaseDate ? new Date(storyToUpdate.releaseDate) : null);
	
	const [isSending, setIsSending] = useState(false);

	const handleEditorChangeDescription = (content, editor) => {
		setEditorContentDescription(content);
	}

	const handleEditorChangeGreenfact = (content, editor) => {
		setEditorContentGreenfact(content);
	}

	const { action } = props;
	const token = ACTIONS['stories'][action].csrfToken;

	// This useEffect change the acceptedFile value depending on the mediaType radio selected
	useEffect(()=> {
        setAcceptedFiles(mediaTypes.filter(type => type.value === mediaType)[0].acceptedFiles)
    }, [mediaType] );

	// Triggers form submission Once the media has successfully finished downloading
	useEffect(() => {
		if (uploadedMedia) {
			submitForm();
		}
	}, [uploadedMedia])

	const checkData = (e) => {
		setErrors([]);
		setIsSuccess(false);

		let errorsList = {};

        uploadFormRef.current =(document.getElementById('storyUploadData-form'));
        let formData = new FormData (uploadFormRef.current);

		const thumbnail = formData.get('story_upload[thumbnail]');
		if ((!storyToUpdate && !thumbnail.size) || (storyToUpdate && !storyToUpdate.links.thumbnail && !thumbnail.size)  ) {
            errorsList.thumbnail='Sélectionnez une vignette au format jpg';
        }

		if (thumbnail.size ) {
            if (thumbnail.size > fileContraints.MAX_SIZE_IMAGE_BYTES ) {
                errorsList['thumbnail'] = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(thumbnail.type) ) {
                errorsList['thumbnail'] = "Format de la vignette non autorisé";
            }
        }

		if (!formData.get('story_upload[name]')) {
			errorsList.name ='Veuillez saisir le nom de la story';
		}

		if (!releaseDate) {
			errorsList.releaseDate = 'Veuillez sélectionner une date de publication';
		}

		if (Object.keys(errorsList).length) {
			handleErrors(errorsList);
		} 
		else {
			setIsSuccess(true);
			setErrors({});
			setIsSending(true);

			if (numberOfMediaSelected >= 1) {
				// we're uploading a new media (in case of creation or update)
				// so we're triggering the upload to server
				setTriggerUploadFile(true);
			}
			else {
				// we're only updating the story without changing the media
				submitForm();
			}
		}
    }

	const submitForm = (e) => {
		const formData = new FormData(document.getElementById('storyUploadData-form'));
		formData.append('story_upload[description]', editorContentRefDescription.current);
		formData.append('story_upload[greenfact]', editorContentRefGreenfact.current);
		formData.append('story[type]', storyType);
		formData.append('media[type]', mediaType);
        formData.append(`story_upload[releaseDate]`, HelperMetiers360.formatDateForFormControl(releaseDate));

		if (uploadedMedia){
            formData.append('media[fileName]', uploadedMedia.fileName);
            formData.append('media[fileSize]', uploadedMedia.fileSize);
        }

		if (action === 'create') {
			postStory({formData})
					.then((result) => {
						const { uniqueId } = result;
						setIsSuccess(true);
						navigate(
							readOneStorySlug.replace(':uniqueId', uniqueId),
							{
                                replace: true,
                                state: {fromEdit: true}
                            }
						 );
					})
				} 
				else { 
				updateStory({formData, uniqueId: storyToUpdate.uniqueId})
					.then(() => {
						setIsSuccess(true);
						navigate(
							readOneStorySlug.replace(':uniqueId', storyToUpdate.uniqueId),
							{
								replace: true,
								state: {fromEdit: true}
							}
						);
					})
		}
	}

	const scrollToTop = () => {
		window.scrollTo({
			top:0,
			behavior: 'smooth',
		})
	}

	const handleErrors = (errorsList) => {
		setIsSuccess(false);
		setErrors(errorsList)
		scrollToTop();
	}

	const checkStoryType =  storyTypes.map((type) => {
		
		return <Form.Check	
			inline 
			label={type.label}
			type='radio'
			id={type.value}
			key={type.value}
			name="story[type]"
			value={type.value}
			defaultChecked={storyType === type.value}
			onChange={(e) => setStoryType(e.target.value)}
			/>
	});

	const checkMediaType =  mediaTypes.map((type) => {
		
		return <Form.Check	
			inline 
			label={type.label}
			type='radio'
			id={type.value}
			key={type.value}
			name="media[type]"
			value={type.value}
			checked={mediaType === type.value}
			onChange={(e) => setMediaType(e.target.value)}
			/>
	});

	const onShowMedia = () => {
		setModalData({
			...modalData,
			header: <>Média enregistré</>,
			content: <>
			{storyToUpdate.type === 'video' &&  <video width="100%" controls>
				<source src={storyToUpdate.links.media} type="video/mp4" />
				Your browser does not support the video tag. </video>
			}
			{storyToUpdate.type === 'video360' &&  <iframe title={"video"} width="100%" height="315" src={storyToUpdate.links.media} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
			{storyToUpdate.type === 'podcast' && <iframe title={"podcast"} width="100%" height="315" src={storyToUpdate.links.media} frameBorder="0"  allowFullScreen></iframe>}
			{storyToUpdate.type === 'image' &&  <img src={storyToUpdate.links.media} width="100%" alt="story media" />
			}
			</>
	});
		
		setModalShow(true);
	}

	const optionVideos =  allVideos
	.sort((a,b) => a.videoName.localeCompare(b.videoName))
	.map(video => 
		<option 
			value={video.uniqueId}
			key={video.uniqueId}
			defaultValue={storyToUpdate ?  storyToUpdate.fromVideo === video.uniqueId:false}>{video.videoName}</option>
	);

	const selectVideos = <Form.Group as={Row}>
							<Form.Label column sm="4">
								Extrait de la vidéo : 
							</Form.Label>
							<Col sm="8">
								<Form.Control as="select" name="media[fromVideo]" id="media[fromVideo]">
									<option 
										value='none'
										defaultValue={!storyToUpdate}>Sélectionner une vidéo</option>
									{optionVideos}
								</Form.Control>
							</Col>
						</Form.Group>;

	const inputDuration = <InputGroup>
								<InputGroup.Text>Durée (en s) : </InputGroup.Text>
								<Form.Control
									id="media_duration"
									name="media[duration]"
									type="number"
									defaultValue={storyToUpdate && storyToUpdate.duration ? storyToUpdate.duration : null}
								/>
							</InputGroup>;

	return (
		<div>
	  		<div>
			  {
					errors.global ?
						
							<Alert variant="danger">
								{errors.global}
							</Alert> : null
				}

				{
					isSuccess ? <Alert variant="success"> Chargement terminé ! </Alert> : null
				}

				<Form id="storyUploadData-form" method="post" encType="multipart/form-data">

					<Form.Group controlId="story_upload_name">
						<Form.Label>Nom</Form.Label>
						<Form.Control
							name="story_upload[name]"
							placeholder="Entrer le nom de la story"
							defaultValue={name}
							onChange={(e => setName(e.target.value))}
							isInvalid={errors.name !== undefined}
						/>
						<Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
					</Form.Group>

					<Form.Group controlId="story_upload_position">
						<Form.Label>Position</Form.Label>
						<Form.Control
							type="number"
							name="story_upload[position]"
							defaultValue={position}
							placeholder="Entrer la position de la story"
							onChange={(e => setPosition(e.target.value))}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Publication</Form.Label>
						<DatePicker
								showIcon
								locale='fr'
								dateFormat="dd/MM/yyyy"
								selected={releaseDate}
								onChange={(date) => {
										setErrors((prev) => ({...prev, releaseDate: null}));
										setReleaseDate(date);
									}
								} 
								placeholderText="jj/mm/aaaa"
                    	/>
					    {errors?.releaseDate && <div className='invalid-feedback' style={{display: "block"}}>Veuillez indiquer une date de publication</div>} 
					</Form.Group>
					<Form.Group>
						<Form.Label>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
						<div className="input-group mb-4">
							<div className="input-group-prepend">
								<span className="input-group-text" id="inputGroupFileAddon01">
									{storyToUpdate && storyToUpdate.links && storyToUpdate.links.thumbnail ? <Image src={storyToUpdate.links.thumbnail[128]} rounded thumbnail className={"max-width-200"} />:'Choisir la vignette'}
								</span>
							</div>
							<div className="custom-file">
								<input
									type="file"
									name="story_upload[thumbnail]"
									className={"custom-file-input"+(errors.thumbnail ? " is-invalid":null)}
									id="inputGroupFile01"
									aria-describedby="inputGroupFileAddon01"
									accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
									ref={fileInput}
									onChange={()=> setThumbnailFileInputName(fileInput.current.files[0].name)}
								/>
								<label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Parcourir">
									{thumbnailFileInputName}
								</label>
							</div>
						</div>
						<Form.Control
						type="hidden"
						isInvalid={errors.thumbnail !== undefined}
						/>
						<Form.Control.Feedback type='invalid'>{errors.thumbnail}</Form.Control.Feedback>
				</Form.Group>
				<Row>
                    <div className="d-flex mt-2">
                        <Form.Check className="m-1" type='checkbox' id='story_upload_featured' name='story_upload[featured]'  label="Cette story est mise en avant" defaultChecked={featured} 
                        onChange = {(e) => setFeatured(e.target.checked)}/>

                        <Form.Check className="m-1" type='checkbox' id='story_upload_sequenceOnly' name='story_upload[sequenceOnly]'  label="Cette story est dans un parcours uniquement" defaultChecked={sequenceOnly} 
                        onChange = {(e) => setSequenceOnly(e.target.checked)}/>

                        <Form.Check className="m-1" type='checkbox' id='story_upload_private' name='story_upload[private]'  label="Cette story est privée" defaultChecked={isPrivate} 
                        onChange = {(e) => setPrivate(e.target.checked)}/>

                        <Form.Check className="m-1" type='checkbox' id='story_upload_freemium' name='story_upload[freemium]'  label="Cette story est freemium" defaultChecked={freemium} 
                        onChange = {(e) => setFreemium(e.target.checked)}/>
                    </div>
				</Row>

				<Form.Group className="mt-2" controlId="story_upload_externalLink">
						<Form.Label>Lien externe</Form.Label>
						<Form.Control
							name="story_upload[externalLink]"
							placeholder="Exemple: https://metiers360.com/"
							defaultValue={externalLink}
							onChange={(e => setExternalLink(e.target.value))}
							isInvalid={errors.externalLink !== undefined}
						/>
						<Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
				</Form.Group>

				<Form.Group className="mt-2">
                    <Form.Label><h3>Type</h3></Form.Label>
                    <Row>
                        <div className="d-flex">
                            {checkStoryType}
                        </div>
                    </Row>
                </Form.Group>
    
				<Form.Group className="mt-2">
						<Form.Label>Description</Form.Label>
						<Editor
							name="story_upload[description]"
							apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
							init={{
							height: 500,
							menubar: false,
							plugins: [
								'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
								'searchreplace', 'visualblocks', 'code', 'fullscreen',
								'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
							],
							toolbar:
								'undo redo | fontsize blocks | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent | charmap emoticons | link | removeformat code | help'
							}}
							onEditorChange={handleEditorChangeDescription}
							initialValue={storyToUpdate ? storyToUpdate.description : ' '}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Greenfact</Form.Label>
						<Editor
							name="story_upload[greenfact]"
							apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
							init={{
							height: 300,
							menubar: false,
							plugins: [
								'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
								'searchreplace', 'visualblocks', 'code', 'fullscreen',
								'insertdatetime', 'media', 'table', 'help', 'wordcount', 'emoticons'
							],
							toolbar:
								'undo redo | fontsize blocks | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent | charmap emoticons | link | removeformat code | help'
							}}
							onEditorChange={handleEditorChangeGreenfact}
							// initialValue={storyToUpdate && storyToUpdate.greenfact ? storyToUpdate.greenfact : ''}
							initialValue={storyToUpdate ? storyToUpdate.greenfact : ' '}
						/>
					</Form.Group>

					<div className="mt-4 mb-4">
						<SelectMultipleItemsWithSearchBar idSelector="story_upload[rome][]" label="Codes ROME"
							allItems={allRomes} 
							selectedItemKeys={selectedRomes} setSelectedItemKeys={setSelectedRomes} 
							itemKey="codeRome" itemValue="codeRome" itemValueDescription="label" />
					</div>
				<Row>
					<Col>
						<Form.Label><h3>Producteur(s)</h3></Form.Label>
						<SelectMultipleClientsComponent
							nameSelect='story_upload[producers][]'
							selectedClients = { selectedClients && selectedClients.length ? selectedClients:[] }
							setSelectedClients = {(clients) => setSelectedClients(clients)}
						/>
					</Col>
				</Row>
							<br />
				<Jumbotron style={{border: errors.media ? "2px solid #dc3545" : ""}} >
                    <h2>Média</h2>
                    <div>
                        {checkMediaType}
						{mediaType === 'video360' ? selectVideos:null}
                        {mediaType === 'video' || mediaType === 'video360' ? inputDuration:null}
                    </div>
					<UploadMediaComponent  {...dropzoneOptions}
                        new={storyToUpdate === 'undefined'}
						acceptedFiles = {acceptedFiles} 
                        onFileSelected = {(fileQueueLength)=>setNumberOfMediaSelected(fileQueueLength)}
                        onFileUploaded = {(file)=>setUploadedMedia(file)}
						triggerUploadFile= {triggerUploadFile}
                    />
					<Form.Control
						type="hidden"
						isInvalid={errors.media !== undefined}
						/>
						<Form.Control.Feedback type='invalid'>{errors.media}</Form.Control.Feedback>
                    <Form.Group >
                        <Form.Control
                            name="media[fileName]"
                            type="hidden"
                        />
                        {storyToUpdate && storyToUpdate.links && storyToUpdate.links.media ? 
                        <Form.Text className={"text-muted"}>
						<Button onClick={onShowMedia}> Voir le média </Button>
                        </Form.Text> : null }
                    </Form.Group>
                </Jumbotron>
					<Form.Group >
						<Form.Control
							name="story_upload[_token]"
							defaultValue={token}
							type="hidden"
						/>
					</Form.Group>
				
				</Form>
				{modalComponent}
				<br/>

				<ButtonLoaderComponent
					isSending={isSending}
					disabled={isSending}
					active={!isSending}
					variant="secondary"
					onClick={checkData}
					msg={storyToUpdate && storyToUpdate.uniqueId ? <><i className="fas fa-save"/> &nbsp;Éditer</>:<><i className="fas fa-save"/> &nbsp;Sauvegarder</>}
				/>

			</div>
		</div>
	);
};

export default UploadStoryComponent;
