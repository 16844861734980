import React, { useEffect, useState } from "react";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import PropTypes from 'prop-types';

import AuthorizationChecker from "../../services/AuthorizationChecker.js";
import { useModal } from '../../hooks/useModal.jsx';
import iconInteractionTypes from "../../routerUtils/iconInteractionTypes.js";
import { Alert, Button, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AxonautLogoBubbleComponent from "../widgets/axonautLogoBubble/AxonautLogoBubbleComponent.jsx";
import CardInteractionComponent from "./cardInteraction/CardInteractionComponent.jsx";
import DynamicTable from "../widgets/dynamicTable/DynamicTable.jsx";
import FormInteractionComponent from "./formInteraction/FormInteractionComponent.jsx";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import './InteractionsTableComponent.scss';

const titleInteraction = (interaction) => {
    return interaction.idAxonaut !== null
        ? <div className="d-flex justify-content-start">
            <AxonautLogoBubbleComponent />
            <span className="d-flex justify-content-center word-break-all w-100">{ HelperMetiers360.textTruncate(interaction.title, 60) }</span>
        </div>
        : <>{HelperMetiers360.textTruncate(interaction.title, 70)}</>
}

const typeInteraction = (interaction) => {
    let iconIsDone = null;
    if(interaction.type?.name === "Tâche") { 
        iconIsDone = <span className={"round round-" + (interaction.isDone ? "green" : "red")}></span>
    }
    return <>
        <i className={ iconInteractionTypes[interaction.type?.value] ?? "fas fa-question" }></i>
        {iconIsDone}
    </>;
}

const InteractionsTableComponent = ({ clientId, interactions, displayAddButton = false, displayClientColumns = false, 
    enableToControlVisibleColumns = false, withParams }) => {
    const { modalComponent, setModalShow, modalData, setModalData } = useModal();
    const oneClientSlug = useStoreState(state => state.actionSlugs.actionSlugsDispatcher('clients'))?.readOne;
    const {allClients, allClientsCRM} = useCustomGetStoreState('clients');
    const { fetchClientsCRM } = useStoreActions(actions => actions.clients);

    // null: form closed, 'add': form open in creation, 'edit': form open in edition
    const [openFormType, setOpenFormType] = useState(null);
    const [interactionToEdit, setInteractionToEdit] = useState(null);

    const [clientIdFormInteraction, setClientIdFormInteraction] = useState(clientId ?? null);

    useEffect(() => {
        fetchClientsCRM();
    }, [])

    const clientContactsList = (contacts) => contacts?.map(contact => 
        contact.firstName || contact.lastName
            ? HelperMetiers360.capitalize(contact.firstName) + " " + contact.lastName?.toUpperCase()
            : contact.email)
        .join(', ');

    const openFormEditInteraction = (interaction) => {
        setClientIdFormInteraction(clientId ?? interaction.clientData?.uniqueId)
        setOpenFormType('edit');
        setInteractionToEdit(interaction);
    }

    const editButton = (interaction) => <Button variant="secondary"  size="sm"
            disabled={interaction.idAxonaut}
            className="px-3 py-1"
            onClick={(e) => {
                e.stopPropagation();
                openFormEditInteraction(interaction);
            }}>
                <i className="fas fa-edit"></i>
        </Button>;

    const displayEdit = (interaction) => interaction.idAxonaut 
        ? <OverlayTrigger
            placement="left"
            overlay={<Tooltip>Non modifiable car vient d'Axonaut</Tooltip>}>
            <span>
                { editButton(interaction) }
            </span>
        </OverlayTrigger>
        : editButton(interaction);

    const displayClientName = (client) => {
        if(client?.clientName) {
            if(client?.uniqueId) {
                if(allClients.some(cli => cli.uniqueId === client.uniqueId)) {
                    return <Link to={oneClientSlug.replace(':uniqueId', client.uniqueId)}>{client.clientName}</Link>
                } else if(allClientsCRM.some(cli => cli.uniqueId === client.uniqueId)) {
                    return <Link to={"/clientsCRM?clientUniqueId="+client.uniqueId}>{client.clientName}</Link>;
                }
            }
            return <>{client.clientName}</>;
        }
        return <>-</>;
    }

    const sortType = [
        {
            value : 'title', label : 'Titre',
            test : HelperMetiers360.isArrayContainsValue(interactions, "title"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.title, b.title),
            display: (interaction) => titleInteraction(interaction),
            flatDisplay: (interaction) => interaction.title
        },
        {
            value : 'type', label : 'Type',
            test : HelperMetiers360.isArrayContainsValue(interactions, "type"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.type?.name, b.type?.name),
            display: (interaction) => typeInteraction(interaction),
            flatDisplay: (interaction) => interaction.type?.name
        },
        {
            value : 'clientName', label : 'Établissement',
            test : displayClientColumns && HelperMetiers360.isArrayContainsValue(interactions, "clientData"),
            method: (a,b) => HelperMetiers360.localeCompareWithNullable(a.clientData?.clientName, b.clientData?.clientName),
            display: (interaction) => displayClientName(interaction.clientData),
            flatDisplay: (interaction) => interaction.clientData?.clientName
        },
        {
            value : 'tagOperations', label : 'Opé M360',
            test : displayClientColumns && HelperMetiers360.isArrayContainsValue(interactions, "clientData"),
            method: (a, b) => HelperMetiers360.sortStringArray(a.clientData?.tagOperations, b.clientData?.tagOperations),
            display: (interaction) => interaction.clientData?.tagOperations?.length > 0
                ? interaction.clientData?.tagOperations?.map(tag => tag.name)?.join(", ")
                : "-"
        },
        {
            value: 'clientContacts', label: "Contacts client",
            test : HelperMetiers360.isArrayContainsValue(interactions, "contacts"),
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(
                clientContactsList(a.contacts), clientContactsList(b.contacts)),
            display: (interaction) => clientContactsList(interaction.contacts) ?? "-"
        },
        {
            value: 'contactsM360', label: "Contacts M360",
            test : HelperMetiers360.isArrayContainsValue(interactions, "contactsM360"),
            method: (a, b) => {
                const contactA = a.contactsM360?.length > 0 ? a.contactsM360[0].name : null;
                const contactB = b.contactsM360?.length > 0 ? b.contactsM360[0].name : null;
                return HelperMetiers360.localeCompareWithNullable(contactA, contactB);
            },
            display: (interaction) => interaction.contactsM360?.map(contact => contact.name)?.join(", ")
        },
        {
            value : 'date', label : 'Date',
            test : HelperMetiers360.isArrayContainsValue(interactions, "timestamp"),
            method: (a, b) => HelperMetiers360.compareWithNullable(a.timestamp, b.timestamp),
            display: (interaction) => HelperMetiers360.timestampToDisplayDate(interaction.timestamp)
        },
        {
            value: 'update', label: "",
            test : AuthorizationChecker.hasUpdateRights('clients'),
            display: (interaction) => displayEdit(interaction),
            flatDisplay: () => null
        }
    ];

    const openInteractionDetails = (interactionUniqueId) => {
        const interactionToShow = interactions.find(int => int.uniqueId === interactionUniqueId);
        if(interactionToShow) {
            setModalData({
                ...modalData,
                header: <></>,
                content: <CardInteractionComponent 
                    interaction={interactionToShow} editable={false}
                    setOpenFormType={setOpenFormType} setInteractionToEdit={setInteractionToEdit}
                    setModalCallingShow={setModalShow} />,
                id: 'modal-interaction',
                size: 'lg'
            });
            setModalShow(true);
        }
    }

    const isTimestampBeforeToday = (timestamp) => timestamp < Math.round(+new Date()/1000);

    return <>
        { displayAddButton
            && <Row className='d-flex justify-content-end mb-5 pe-0'>
                <Button variant="success" className="w-auto"
                    onClick={() => setOpenFormType('add')}>
                    <i className="fas fa-plus"></i>&nbsp;Ajouter une interaction
                </Button>
            </Row>
        }
        { interactions.length > 0 
            ? <DynamicTable
                contentTable = {interactions
                    .map(int => {
                        return {
                            ...int,
                            backgroundColor: (int.isDone === false && isTimestampBeforeToday(int.timestamp)) 
                                ? '#FFA7A9' 
                                : null
                        }
                    })}
                contentSort = {sortType}
                valueInitSort = "date"
                index = 'uniqueId'
                handleClick={(uniqueId) => openInteractionDetails(uniqueId)}
                enableToControlVisibleColumns={enableToControlVisibleColumns && AuthorizationChecker.isAdmin()}
                tableName="interactionsTable"
                withParams={withParams}
                filename="suivi_clients"
            />
            : <Alert variant="danger" className="mt-4">Aucune interaction</Alert>
        }

        { openFormType && 
            <div className="form-interaction-full-width">
                <FormInteractionComponent clientId={clientIdFormInteraction} openFormType={openFormType}
                    setOpenFormType={setOpenFormType} interactionToEdit={interactionToEdit} />
            </div>
        }
        {modalComponent}
    </>;
}

InteractionsTableComponent.propTypes = {
    clientId: PropTypes.string,
    interactions: PropTypes.array.isRequired,
    displayAddButton: PropTypes.bool,
    displayClientColumns: PropTypes.bool,
    enableToControlVisibleColumns: PropTypes.bool,
    withParams: PropTypes.bool
};

export default InteractionsTableComponent