import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import useBackButton from "../../hooks/useBackButton.jsx";
import JobAppellationsTableComponent from "../../components/romes/JobAppellationsTableComponent.jsx";

import { useStoreActions, useStoreState } from 'easy-peasy';


const JobAppellationsTableWithParams = withURLParamsTableComponent(JobAppellationsTableComponent);

const JobAppellationsPage = () => {

    const { allJobAppellations, isFetchingAllJobAppellations } = useStoreState(state => state.romes);
    const { allVideos, isFetchingAllVideos } = useCustomGetStoreState('videos');
    const { fetchAllJobAppellations } = useStoreActions(actions => actions.romes);

    const [formattedJobAppellations, setFormattedJobAppellations] = useState([]);


    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés', delay: 400});
    
    const {backButtonComponent} = useBackButton({path: "/romesHome"});

    useEffect(() => {
        if (!allJobAppellations?.length && !isFetchingAllJobAppellations) {
            fetchAllJobAppellations();
        }
    }, [allJobAppellations, isFetchingAllJobAppellations])


    useEffect(() => {
        if(!isFetchingAllJobAppellations && !isFetchingAllVideos 
            && allJobAppellations.length !==0 && allVideos.length !==0
        ) {
            setFormattedJobAppellations(
                allJobAppellations.map(job => {
                    return {
                        ...job, 
                        videos:allVideos.filter(video => job.videosUniqueId.includes(video.uniqueId))
                    }
                })
            );
        }
    }, [allJobAppellations, allVideos, isFetchingAllJobAppellations, isFetchingAllVideos])

    const filteredJobAppellations = useMemo(() => {
        if(!search) return formattedJobAppellations;
        else {
            const filterJobAppellations = (search) => {
                if (search === '') {
                    return formattedJobAppellations
                }
                return formattedJobAppellations
                    .filter(job => HelperMetiers360.isSearchInText(search, job.rome)
                        || HelperMetiers360.isSearchInText(search, job.label) 
                        || job.videos?.some(v => HelperMetiers360.isSearchInText(search, v.videoName)
                                || HelperMetiers360.isSearchInText(search, v.subHeading)))
            }
            return [...filterJobAppellations(search)];
        }
    }, [search, formattedJobAppellations, allJobAppellations, isFetchingAllJobAppellations]);

    const isNoJobs = !isFetchingAllJobAppellations && allJobAppellations.length === 0;
    const isNoResult = !isNoJobs && filteredJobAppellations.length === 0;

    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    Métiers
                    {backButtonComponent}
                </h2>
            </Row>
            <Row>
                <Col>
                {searchInput}
                </Col>
            </Row>
            <Row>
                {(isFetchingAllJobAppellations || isFetchingAllVideos) && <Loader />}
                {!isNoJobs && <JobAppellationsTableWithParams jobAppellations={filteredJobAppellations} />}
                {isNoJobs && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun métier</Alert>}
                {isNoResult && <Alert className="mt-3" variant="warning">Aucun métier ne correspond à votre recherche "{search}"</Alert>}
               
            </Row>
        </Container>
    )
}

export default JobAppellationsPage;