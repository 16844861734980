import React, { useEffect, useState } from "react";
import { Table, Form, Container } from "react-bootstrap";

import HelperMetiers360 from "../../../../../services/HelpersMetiers360";

import './SelectStoryTableComponent.scss';
import useDebounceSearch from "../../../../../hooks/useDebounchSearch";

/**
 * 
 * @param {array} stories list objects representing the stories
 * @param {array} selectedStories indexes of the selected stories
 * @param {function} setSelectedStories callback 
 * @example
 * 	<SelectStoryTableComponent
 *	 	stories={stories}
 *	 	selectedStories={selectedStories}
 *	 	setSelectedStories={setSelectedStories}
 * 	/>
 */

const SelectStoryTableComponent = (props) => {
	const { stories, selectedStories, setSelectedStories, formName } = props;

	const storiesToDisplay = stories ? stories : [];

	const labelVR = "VR360";

	const {searchInput, search} = useDebounceSearch({placeholder:
		'Rechercher par mots-clés (Nom, Description, Média ...)', delay: 400})
	const [filteredStories, setFilteredStories] = useState([...stories]);
	const sortType = [
		{
			value: "title",
			label: "Nom",
			test: storiesToDisplay[0]?.name !== undefined,
			display: (story) => story.name,
		},
		{
			value: "position",
			label: "Position",
			test: storiesToDisplay[0]?.position !== undefined,
			display: (story) => story.position,
		},
		{
			value: "type",
			label: "Media",
			test: storiesToDisplay[0]?.type !== undefined,
			display: (story) => (story.is360 ? labelVR : story.type),
		},
		{
			value: "fromVideo",
			label: "Extrait de",
			test: storiesToDisplay[0]?.fromVideo !== undefined,
			display: (story) => (story.fromVideo ? story.fromVideo : "-"),
		},
		{
			value: "date",
			label: "Publication",
			test: storiesToDisplay[0]?.releaseDate !== undefined,
			display: (story) =>
				HelperMetiers360.getdisplayDateType(story.releaseDate, "day"),
		},
		// {
		// 	value: "view",
		// 	label: <i className='far fa-eye' />,
		// 	test: storiesToDisplay[0].viewCount !== undefined,
		// 	display: (story) => (story.viewCount ? story.viewCount : 0),
		// },
		// {
		// 	value: "like",
		// 	label: <i className='far fa-thumbs-up' />,
		// 	test: storiesToDisplay[0].isLiked !== undefined,
		// 	display: (story) => (story.isLiked ? story.isLiked : 0),
		// },
		// {
		// 	value: "dislike",
		// 	label: <i className='far fa-thumbs-down' />,
		// 	test: storiesToDisplay[0].isUnliked !== undefined,
		// 	display: (story) => (story.isUnliked ? story.isUnliked : 0),
		// },
		{
			value: "romes",
			label: "Codes Rome",
			test: storiesToDisplay[0]?.romes !== undefined,
			display: (story) => (story.romes ? story.romes.toString() : "-"),
		},
		{
			value: "private",
			label: "Privée",
			test: storiesToDisplay[0]?.private !== undefined,
			display: (story) => (story.private ? "X" : "-"),
		},
		{
			value: "freemium",
			label: "Free",
			test: storiesToDisplay[0]?.freemium !== undefined,
			display: (story) => (story.freemium ? "X" : "-"),
		},
        {
            value: 'typeOfStory', label: "Type",
            test : storiesToDisplay[0]?.typeOfStory !== undefined,
            method: (a, b) => HelperMetiers360.localeCompareWithNullable(a.typeOfStory, b.typeOfStory),
            display: (story) => story.typeOfStory ? story.typeOfStory.label:'-'
        },
		{
			value: "producers",
			label: "Producteur(s)",
			test: storiesToDisplay[0]?.producers !== undefined,
			display: (story) => story.producers.map(producer => producer.clientName)?.join(", "),
		},
	];

	useEffect(() => {
		setFilteredStories([
			...stories
				.filter(
					(story) => HelperMetiers360.isSearchInText(search, story.name)
						|| HelperMetiers360.isSearchInText(search, story.description)
						|| HelperMetiers360.isSearchInText(search, story.is360 ? labelVR : story.type)
						|| HelperMetiers360.isSearchInText(search, story.fromVideo)
						|| HelperMetiers360.isSearchInText(search, story.romes?.join(", "))
						|| HelperMetiers360.isSearchInText(search, story.typeOfStory.label)
						|| HelperMetiers360.isSearchInText(search, story.producers.map(producer => producer.clientName)?.join(", "))
						|| selectedStories.includes(story.uniqueId.toString())
				)
		])
		
	}, [search, stories, selectedStories]);

	const handleSelectStory = (uniqueId) => {
		if (selectedStories.includes(uniqueId)) {
			setSelectedStories(
				selectedStories.filter((storyId) => uniqueId !== storyId),
			);
		} else {
			setSelectedStories([...selectedStories, uniqueId]);
		}
	};

	if (storiesToDisplay.length === 0) {
		return <></>;
	}

	const tableRows = filteredStories
		.sort((a, b) => {
			if (
				selectedStories.find((storyId) => storyId === a.uniqueId.toString()) &&
				selectedStories.find((storyId) => storyId === b.uniqueId.toString())
			) {
				return 0;
			}

			if (selectedStories.find((storyId) => storyId === a.uniqueId.toString()))
				return -1;

			if (selectedStories.find((storyId) => storyId === b.uniqueId.toString()))
				return 1;

			return 0;
		})
		
		.map((story) => {
			return (
				<tr style={{ cursor: "pointer" }} key={story.uniqueId}>
					<td>
						<Form.Check
							type='checkbox'
							id={`${formName}_storyList_${story.uniqueId}`}
							key={HelperMetiers360.generateUniqueId()}
						>
							<Form.Check.Input
								type='checkbox'
								value={story.uniqueId.toString()}
								name={`${formName}[storyList][]`}
								defaultChecked={
									selectedStories.includes(story.uniqueId.toString())
										? true
										: false
								}
								onChange={() => handleSelectStory(story.uniqueId.toString())}
							/>
						</Form.Check>
					</td>
					{sortType.map((type) =>
						type.test ? (
							<td
								key={type.value.concat("-", story.uniqueId)}
								onClick={() => handleSelectStory(story.uniqueId.toString())}
							>
								{type.display(story)}
							</td>
						) : null,
					)}
				</tr>
			);
		});

	const table = (
		<Table striped bordered hover size='sm' className='table-story'>
			<thead id="story-table-header" className='text-center'>
				<tr>
					<th className='bg-primary text-white'>#</th>
					{sortType.map((type) =>
						type.test ? (
							<th
								key={type.value}
								style={{ cursor: "pointer" }}
								className='bg-primary text-white'
							>
								{type.label}
							</th>
						) : null,
					)}
				</tr>
			</thead>
			
			<tbody id="story-table-body" className='text-center'>{tableRows}</tbody>
		</Table>
	);

	return (
		<>
			<Container fluid>
				{searchInput}
				{table}
			</Container>
		</>
	);
};

export { SelectStoryTableComponent };
