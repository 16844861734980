import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

import {useStoreActions, useStoreState} from 'easy-peasy';
import { Col, Row } from "react-bootstrap";

import CreateGroupComponent from "../../components/groups/CreateGroupComponent.jsx";
import useBackButton from "../../hooks/useBackButton.jsx";
import Loader from "../../components/widgets/Loader.jsx";

import "./UpdateGroupPage.scss";

const UpdateGroupPage = (props) => {
    const { uniqueId } = useParams(); 
    
    const [isLoading, setIsLoading] = useState(false);

    const { groupById } = useStoreState(state => state.groups);

	const { fetchGroupById, postGroup, updateGroup} =  useStoreActions((actions) => actions.groups);
    const groupSlugs = useStoreState((state) => state.actionSlugs.actionSlugsDispatcher("groups"));

    const {backButtonComponent} = useBackButton({
        path: groupSlugs.readOne.replace(':uniqueId', uniqueId), 
        mediaType:"groups", 
        shouldReplaceHistory:true, 
    })

	useEffect(() => {
        setIsLoading(true);
        fetchGroupById(uniqueId)
            .finally(() => setIsLoading(false));
	}, []);

	return (
		<div className="container p-2">
            {
                isLoading ?
                <Loader />
                : <>
            		<Row className="mb-3">
                    <Col className="d-flex justify-content-between align-items-center">
                            <h2>
                                Mise à jour du groupe : {groupById && groupById.name ? groupById.name : "Mise à jour d'un groupe"}
                            </h2>
                            {backButtonComponent}
                    </Col>
                    </Row>
                    <CreateGroupComponent 
                        groupToUpdate={groupById}
                        postGroup={postGroup} 
                        updateGroup={updateGroup} 
                        action="update"
                        {...groupSlugs}
                    />
                </>
            }
		</div>
	);
};

export default UpdateGroupPage;