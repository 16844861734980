import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import {Alert} from 'react-bootstrap';
import HelperMetiers360 from "../../services/HelpersMetiers360";
import Loader from "../widgets/Loader";
import HeadsetTableComponent from "./HeadsetTableComponent.jsx";
import useDebounceSearch from "../../hooks/useDebounchSearch";

/**
 * This function is used to display the list of headsets associated to a client
 * @param props - the props of the component that is calling this function.
 * @returns A component
 */
const HeadsetPanelComponent = (props) =>  {
    const {subscription, client} = props;
    const {apiData: {BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);
    const { typeOfHeadsetOwnership } = BACKOFFICE_DEFAULT_VALUES;
    
    const { headsetsByClientId, isFetchingHeadsetsByClientId} = useStoreState(state => state.clients);
    const { fetchHeadsetsByClientId } = useStoreActions(actions => actions.clients);

    const [filteredHeadsets, setFilteredHeadsets] = useState(null);
    const [subsciptionAlreadySelected, setSubsciptionAlreadySelected] = useState(null);
    
    const {searchInput, search, resetSearchBar} = useDebounceSearch({placeholder: 
        'Rechercher par mots-clés (Nom, N° série, Type ...)',
        delay: 400})
   
    useEffect(() => {
        fetchHeadsetsByClientId(client?.uniqueId);
    }, [])

    useEffect(() => {
        if(subscription && subsciptionAlreadySelected != subscription.uniqueId) {
            setSubsciptionAlreadySelected(subscription.uniqueId);
            resetSearchBar();
        }

        const filterHeadsets = (search) => {
            return headsetsByClientId.filter(headset => 
                    (subscription ? headset.subscriptionUniqueId === subscription.uniqueId : true) 
                    && (HelperMetiers360.isSearchInText(search, headset.headsetName)
                    || HelperMetiers360.isSearchInText(search, headset.deviceId)
                    || HelperMetiers360.isSearchInText(search, headset.macAdress)
                    || HelperMetiers360.isSearchInText(search, headset.appVersion)
                    || (headset.ownership && HelperMetiers360.isSearchInText(search, typeOfHeadsetOwnership[headset.ownership])))
        )}
        setFilteredHeadsets(filterHeadsets(search));
    }, [search, headsetsByClientId, subscription])

    const headsetTabToDisplay = headsetsByClientId?.length > 0 && filteredHeadsets !== undefined 
        && <>
            {((!subscription && headsetsByClientId.length > 1) || (subscription && subscription.headsetCount > 1)) && searchInput}
            {(!headsetsByClientId || !headsetsByClientId?.length) ?
            <Alert variant="warning">Aucun casque à afficher</Alert>
            : (subscription && subscription.headsetCount === 0) ?
            null
            : !filteredHeadsets?.length &&
                <Alert className="mt-3" variant="warning">
                    Aucun casque correspondant
                </Alert>
            }

            <HeadsetTableComponent headsets={filteredHeadsets} />
        </>;

    return isFetchingHeadsetsByClientId
        ? <Loader /> 
        : headsetTabToDisplay;
}
export default HeadsetPanelComponent