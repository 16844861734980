import React, { useMemo } from 'react';
import { createPortal } from 'react-dom';
import { ToastContext } from '../../../services/ToastContext';
import ToastComponent from './ToastComponent';
import { useStoreActions, useStoreState } from 'easy-peasy';

export const ToastProvider = (props) => {
    const {toastsToDisplay} = useStoreState(state => state.toasts);
    const {addToast, removeToast} = useStoreActions(action => action.toasts);

    const open = (newToast) => {
        addToast(newToast);
    }

    const success = () => {
        addToast({message: "Opération réalisée avec succès", variant: "success"});
    }

    const contextValue = useMemo(() => ({ open, success }), []);

    return <ToastContext.Provider value={contextValue}>
        {props.children}
        {createPortal(
            <div className="toasts-wrapper">
                {toastsToDisplay.map((toast) => {
                    return <ToastComponent
                        key={toast.id}
                        contentMessage={toast.message}
                        autohide={true}
                        variant={toast.variant}
                        onClose={() => removeToast(toast.id)}
                        delay={toast.variant === 'success' ? toast.delay : 20000}
                    />;
                })}
            </div>,
            document.body
        )}
    </ToastContext.Provider>;
};