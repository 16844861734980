import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import PropTypes from 'prop-types';

import TransversalPropsTableComponent from "../../components/romes/TransversalPropsTableComponent.jsx";

import Loader from "../../components/widgets/Loader.jsx";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState.jsx";
import useSearchWithParams from "../../hooks/useSearchWithParams.jsx";

import withURLParamsTableComponent from "../../HOC/withURLParamsTableComponent.jsx";
import HelperMetiers360 from "../../services/HelpersMetiers360.js";
import { useStoreActions } from "easy-peasy";
import useBackButton from "../../hooks/useBackButton.jsx";

const TransversalPropsTableWithParams = withURLParamsTableComponent(TransversalPropsTableComponent);

const TransversalPropsPage = ({transversalProp}) => {

    const { allAreasOfInterest, allWorkingConditions, allSkills,isFetchingAllAreasOfInterest, 
        isFetchingAllWorkingConditions, isFetchingAllSkills } = useCustomGetStoreState('romes');
    const {fetchAllAreasOfInterest, fetchAllWorkingConditions, fetchAllSkills} = useStoreActions(actions => actions.romes);

    const [allTransversalProps, setAllTransversalProps] = useState([]);

    const {searchInput, search} = useSearchWithParams({placeholder: 'Rechercher par mots-clés', delay: 400})
    
    const {backButtonComponent} = useBackButton({path: "/romesHome"});

    const pageTile = (transversalProp) => {
        switch (transversalProp) {
            case 'areasOfInterest':
                return "Centres d'intérêt";
            case 'workingConditions':
                return 'Contextes de travail';
            case 'skills':
                return 'Macro savoir-être professionnels';
            default:
                return 'Non défini';
        }
    };

    useEffect(() =>{
        switch(transversalProp) {
            case 'areasOfInterest':
                if(allAreasOfInterest.length === 0 && !isFetchingAllAreasOfInterest) {
        // ? is all of this necessary ?
        // ? as we use allAreasOfInterest from useCustomGetStoreState('romes'), allAreasOfInterest should be fetched automatically
                    fetchAllAreasOfInterest()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allAreasOfInterest);
                break;
            case 'workingConditions':
                if(allWorkingConditions.length === 0 && !isFetchingAllWorkingConditions) {
                    fetchAllWorkingConditions()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allWorkingConditions);
                break;
            case 'skills':
                if(allSkills.length === 0 && !isFetchingAllSkills) {
                    fetchAllSkills()
                        .then((data) => setAllTransversalProps(data))
                } else setAllTransversalProps(allSkills);
                break;
        }
    }, []);

    const filteredTransversalProps = useMemo(() => {
        if(!search) return allTransversalProps;
        else {
            const filterTransversalProps = (search) => {
                if (search === '') {
                    return allTransversalProps
                }
                return allTransversalProps.filter(transversalProp => HelperMetiers360.isSearchInText(search, transversalProp.codeRome)
                    || HelperMetiers360.isSearchInText(search, transversalProp.label))
            }
            return [...filterTransversalProps(search)];
        }
    }, [search, allTransversalProps]);

    const isNoRome = !allTransversalProps.length
    const isNoResult = !isFetchingAllAreasOfInterest && !isFetchingAllWorkingConditions && !isFetchingAllSkills && allTransversalProps.length && !filteredTransversalProps.length;
    
    return (
        <Container fluid>
            <Row className="item-page">
                <h2 className="d-flex justify-content-between">
                    {pageTile(transversalProp)}
                    {backButtonComponent}
                </h2>
            </Row>
            <Row>
                <Col>
                {searchInput}
                </Col>
            </Row>
            <Row>
                {isFetchingAllAreasOfInterest || isFetchingAllWorkingConditions || isFetchingAllSkills && <Loader />}
                {filteredTransversalProps.length > 0 && 
                    <TransversalPropsTableWithParams transversalProps={filteredTransversalProps}
                        transversalProp={transversalProp} />}
                {isNoRome && <Alert className="mt-3" variant="warning">Vous n'avez accès à aucun code ROME</Alert>}
                {isNoResult && <Alert className="mt-3" variant="warning">Aucun code ROME ne correspond à votre recherche "{search}"</Alert>}
               
            </Row>
        </Container>
    )
}

TransversalPropsPage.propTypes = {
    transversalProp: PropTypes.string.isRequired,
};

export default TransversalPropsPage;