import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Tooltip} from 'chart.js'; // 
import {Line, Bar} from 'react-chartjs-2';
import { useStoreState } from 'easy-peasy';

import axios from '../../services/axios';

import './VideoPlayGraph.scss';
import AuthorizationChecker from '../../services/AuthorizationChecker';

const VideoPlayGraph = (props) => {
    const {clientUniqueId=null} = props;

	const {client} = useStoreState(state => state.auth);
	const all = AuthorizationChecker.isAdmin() ? 'all':client;
	const uniqueIdToFetch = clientUniqueId ? clientUniqueId:all;

	const {apiData: {ACTIONS}} = useStoreState(state => state.actionSlugs);

    let color = 'rgb(207, 119, 255)';
	const [hasDataForVideosPlayed, setHasDataForVideosPlayed] = useState(false);
	const [hasDataForTopVideos, setHasDataForTopVideos] = useState(false);

	const [lineDisplay, setLineDisplay] = useState(null);
	const [barDisplay, setBarDisplay] = useState(null);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		BarElement,
		Tooltip
	  );

	useEffect(() => {

		let days = [];
		let data = [];

		let daysNumber = 30;
		let today = new Date();

		// find full date 30 days ago
		let date30DaysAgo = new Date(new Date().setDate(today.getDate() - daysNumber));

		// handle get month play count
		axios.get(ACTIONS.clients.getProps.getMonthPlay.url.replace('uniqueId', uniqueIdToFetch)).then( (response) => {
			// check if array is empty, so no available data
			if (response && !response.length) {
				setHasDataForVideosPlayed(false);
			}

			else {
				setHasDataForVideosPlayed(true);

				let currentDay = date30DaysAgo;
				let currentMonthName = currentDay.toLocaleString('default', { month: 'long' });

				// fills days and data array
				for (let i = 1; i <= daysNumber; i++) {

					days.push(currentDay.getDate() + ' ' + currentMonthName);

					// try to find data of the current day, current day me be undefined if no video has been played at day i
					let currentDayData = response.find((dayCount) => {
						return parseInt(dayCount.day) === currentDay.getDate() && parseInt(dayCount.month) === currentDay.getMonth() + 1; // +1 needed for getMonth() use
					});

					// if no data have been found for current day, suppose that videos views is 0
					if (currentDayData) {
						data.push(currentDayData.views);
					} else {
						data.push(0);
					}


					// compute next day
					currentDay = date30DaysAgo.setDate(date30DaysAgo.getDate() + 1);
					currentDay = new Date(currentDay);
					currentMonthName = currentDay.toLocaleString('default', { month: 'long' });
				}

				const options = {responsive: true};
				const dataToDisplay = {
						labels: days,
						datasets: [{
							label: 'Vidéos vues',
							backgroundColor: color,
							borderColor: color,
							data: data,
							cubicInterpolationMode: 'monotone',
						}],
					};

				setLineDisplay(<Line options = {options} data = {dataToDisplay} />);
			}

		});

		//handle get top videos played
		axios.get(ACTIONS.clients.getProps.getTopPlayed.url.replace('uniqueId', uniqueIdToFetch)).then( (response) => {
			// check if array is empty, so no available data
			if (response && !response.length) {
				setHasDataForTopVideos(false);
			}

			else {
				setHasDataForTopVideos(true);

				const {topVideosViews, topVideosNames} = response.reduce((acc,video) =>{
					acc.topVideosViews.push(video.views);
					acc.topVideosNames.push(video.subHeading ? [video.videoName,video.subHeading]:video.videoName);
					return acc;
				},{topVideosViews:[],topVideosNames:[]})

				const options = {
					indexAxis: 'y',
					scales: {
						y: {
							beginAtZero: true
						},
					}
				};
				const dataToDisplay = {
					labels: topVideosNames,
					datasets: [{
						data: topVideosViews,
						backgroundColor: color,
						borderColor: color,
						label: 'Vidéos vues'
					}],
				};

				setBarDisplay(<Bar options = {options} data={dataToDisplay}/>);
			}
		});
		return () =>{
			setLineDisplay(null);
			setBarDisplay(null);
		}
	}, [ACTIONS.clients.getProps.getTopPlayed.url, ACTIONS.clients.getProps.getMonthPlay.url, color, clientUniqueId]);

	let noData = <Row className='no-data'>
		<p>Mettez à jour vos casques pour visualiser les données</p>
	</Row>;


    return (
    	<div className='stats-container'>
			<Container>
                <Row>
                    <Col>
                        <h3>Expériences immersives vues au cours des 30 derniers jours</h3>
                        {hasDataForVideosPlayed ? lineDisplay : noData}
                    </Col>
                    <Col>
                        <h3>Top expériences immersives vues au cours des 30 derniers jours</h3>
                        {hasDataForTopVideos ? barDisplay : noData}
                    </Col>
                </Row>
			</Container>

		</div>
	);

}

export default VideoPlayGraph;