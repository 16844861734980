import React, { useState, useRef, useEffect } from "react";
import { useStoreState } from 'easy-peasy';
import { useNavigate } from "react-router-dom";

import { Form, Alert, Image, Row, Col, InputGroup } from 'react-bootstrap';

import ButtonLoaderComponent from "../../widgets/ButtonLoaderComponent";

import { Editor } from "@tinymce/tinymce-react";

import SelectMediaModuleComponent from "../../widgets/SelectMediaModule/SelectMediaModuleComponent";
import useCustomGetStoreState from "../../../hooks/useCustomGetStoreState";
import SelectMultipleItemsWithSearchBar from "../../widgets/selectMultipleItemsWithSearchBar/SelectMultipleItemsWithSearchBar.jsx";

import './UploadPedagogicModuleComponent.scss';

const UploadPedagogicModuleComponent = (props) => {

    const navigate = useNavigate();

    const {apiData: {ACTIONS, BACKOFFICE_DEFAULT_VALUES}} = useStoreState(state => state.actionSlugs);

    const { postPedagogicModule, updatePedagogicModule, pedagogicModuleToUpdate, readOne: readOnePedagogicModuleSlugs } = props;

    const { typeOfTool: allTools } = BACKOFFICE_DEFAULT_VALUES;
	const [selectedTools, setSelectedTools]= useState(pedagogicModuleToUpdate?.tools?.length ? pedagogicModuleToUpdate.tools:[]);

    const { typeOfLevel: allLevels } = BACKOFFICE_DEFAULT_VALUES;
	const [selectedLevels, setSelectedLevels]= useState(pedagogicModuleToUpdate?.levels?.length ? pedagogicModuleToUpdate.levels:[]);

    const { typeOfCategory: allCategories } = BACKOFFICE_DEFAULT_VALUES;
	const [selectedCategories, setSelectedCategories]= useState(pedagogicModuleToUpdate?.categories?.length ? pedagogicModuleToUpdate.categories:[]);

    const { typeOfStage: allStages } = BACKOFFICE_DEFAULT_VALUES;
	const [selectedStages, setSelectedStages]= useState(pedagogicModuleToUpdate?.stages?.length ? pedagogicModuleToUpdate.stages:[]);

    const { fileContraints: fileContraints } = BACKOFFICE_DEFAULT_VALUES;


    const [thumbnailFileInputName, setThumbnailFileInputName] = useState(pedagogicModuleToUpdate && pedagogicModuleToUpdate.links.image ? 'Changer la vignette' : 'Choisir le fichier');
    const fileInput = useRef(null);

    const pdfFileInput = useRef(null);
    const [pdfFileInputName, setPdfFileInputName] = useState(pedagogicModuleToUpdate && pedagogicModuleToUpdate.pdfFile ? 'Changer le fichier' : 'Sélectionnez un fichier');

    const editorContentRefDescription = useRef(pedagogicModuleToUpdate?.description ? pedagogicModuleToUpdate.description : '');
    const handleEditorChangeDescription = (content, editor) => {
        editorContentRefDescription.current = content;
    }

    const editorContentRefGoal = useRef(pedagogicModuleToUpdate?.goal ? pedagogicModuleToUpdate.goal : '');
    const handleEditorChangeGoal = (content, editor) => {
        editorContentRefGoal.current = content;
    }

    const { action } = props;
    const token = ACTIONS['pedagogicModules'][action].csrfToken;

    const [errors, setErrors] = useState([]);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const uploadFormRef = useRef(null);

    const { allTags } = useCustomGetStoreState("tags")
    
    const [tags, setTags] = useState(pedagogicModuleToUpdate?.tags ? pedagogicModuleToUpdate.tags : '');

    const {allVideos} = useCustomGetStoreState("videos");
    const videoResult = useRef(pedagogicModuleToUpdate?.videos ? pedagogicModuleToUpdate.videos : [])
    
    const {allQuizzes} = useCustomGetStoreState("quizzes");
    const quizResult = useRef(pedagogicModuleToUpdate?.quizzes ? pedagogicModuleToUpdate.quizzes : []);


    const checkData = () => {
        setErrors([]);
        setIsSuccess(false);

        let errorsList = {};

        uploadFormRef.current = (document.getElementById('pedagogicModule-form'));
        let formData = new FormData(uploadFormRef.current);
        
        let name = formData.get('pedagogic_module[name]');
        let thumbnail = formData.get('pedagogic_module[image]')
        let pdf = formData.get('pedagogic_module[pdfFile]')
     
        if (!name) {
            errorsList.name = "Le nom du module est obligatoire";
        }
        
        if (!pedagogicModuleToUpdate && !thumbnail.size) {
            errorsList.image = "Veuillez selectionner une vignette"
        }



        if (thumbnail.size ) {
            if (thumbnail.size > fileContraints.MAX_SIZE_IMAGE_BYTES ) {
                errorsList.image = "Vignette trop volumineuse";
            }
            if (!fileContraints.IMAGE_MIME_TYPES.includes(thumbnail.type) ) {
                errorsList.image = "Format de la vignette non autorisé";
            }
        }


        if (pdf.size ) {
            if (pdf.size > fileContraints.MAX_SIZE_TEXT_FILE_BYTES ) {
                errorsList.pdfFile = "Fichier trop volumineux";
            }
            if (!fileContraints.TEXT_FILE_MIME_TYPES.includes(pdf.type) ) {
                errorsList.pdfFile = "Format du fichier non autorisé";
            }
        }


        if (Object.keys(errorsList).length) {
            errorsList.global = "Une erreur est survenue a l'enregistrement."
            setErrors(errorsList);
            scrollToTop();
        }
        else {
            setIsSuccess(true);
            setErrors([]);
            setIsSending(true);
            submitForm();
        }
    }

    const submitForm = () => {
        
        const formData = new FormData(document.getElementById('pedagogicModule-form'));
        formData.append('pedagogic_module[description]', editorContentRefDescription.current);
        formData.append('pedagogic_module[goal]', editorContentRefGoal.current);
     
        for (const video of videoResult.current) {
            formData.append('pedagogic_module[relatedVideos][]', video.id.toString())
        }

        for (const quiz of quizResult.current) {
            formData.append('pedagogic_module[relatedQuizzes][]', quiz.id.toString())
        }

       if (action === 'create') { 
        postPedagogicModule({formData})
            .then((result) => {
                const {uniqueId} = result;
                setIsSuccess(true);
                navigate(
                    readOnePedagogicModuleSlugs.replace(':uniqueId', uniqueId),
                    {
                        replace: true,
                        state: {fromEdit: true}
                    }
                    );
            })
            .catch((error) => {
                setIsSending(false);
                const errorList = {};
    
                if(error && error.errors) {
                    for (const key of Object.keys(error.errors)) {
                        errorList[key] = error.errors[key]
                    }
                }
                errorList.global = "Le module n'a pu être enregistré"
                setErrors(errorList);
                scrollToTop();
            })
        
    } else {
        updatePedagogicModule({formData, uniqueId: pedagogicModuleToUpdate.uniqueId})
        .then((result) => {
            setIsSuccess(true);
            navigate(
                readOnePedagogicModuleSlugs.replace(':uniqueId', pedagogicModuleToUpdate.uniqueId),
                {
                    replace: true,
                    state: {fromEdit: true}
                });
        }).catch((error) => {
            setIsSending(false);
            const errorList = {};

            if(error && error.errors) {
                    for (const key of Object.keys(error.errors)) {
                        errorList[key] = error.errors[key]
                    }
            }
                errorList.global = "Le module n'a pu être mis à jour"
            setErrors(errorList);
            scrollToTop();
        });
    }
    }

    const scrollToTop = () => {
		window.scrollTo({
			top:0,
			behavior: 'smooth',
		})
	}
  

    return (
        <div>
	  		<div>
			  {
					errors.global ?
						
							<Alert variant="danger">
								{errors.global}
							</Alert> : null
				}

				{
					isSuccess ? <Alert variant="success"> Chargement terminé ! </Alert> : null
				}

				<Form id="pedagogicModule-form" method="post" encType="multipart/form-data">
                <Row>
                    <Col xs='9'>
                        <Form.Group controlId="pedagogic_module[name]">
                            <Form.Label className='form-title'>Nom</Form.Label>
                            <Form.Control
                                name="pedagogic_module[name]"
                                placeholder="Exemple: Qu'est-ce que l'entreprise ?"
                                defaultValue={pedagogicModuleToUpdate && pedagogicModuleToUpdate.name ? pedagogicModuleToUpdate.name : ''}
                                isInvalid={errors.name != undefined}
                            />
                            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col xs={3}>
                        <p className='form-title'>Durée</p>
                        <InputGroup>
                        <InputGroup.Text>Durée (en min) : </InputGroup.Text>
                        <Form.Control
                            id="duration"
                            name="pedagogic_module[duration]"
                            type="number"
                            defaultValue={pedagogicModuleToUpdate?.duration ? pedagogicModuleToUpdate.duration : null}
                        />
                        <Form.Control.Feedback type='invalid'>{errors.duration}</Form.Control.Feedback>
                    </InputGroup></Col>
                </Row>

                    <hr />

                    <Form.Group>
                        <Form.Label className='form-title'>Vignette ({fileContraints.IMAGE_MIME_TYPES_NAMES?.join(',')})  ({fileContraints.MAX_SIZE_IMAGE_MO} Mo max.)</Form.Label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroupFileAddon01">
									{pedagogicModuleToUpdate && pedagogicModuleToUpdate.links.image ? <Image src={pedagogicModuleToUpdate.links.image} rounded thumbnail className={"max-width-200"} />:'Choisir la vignette'}
								</span>
                            </div>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    name="pedagogic_module[image]"
                                    className={"custom-file-input" + (errors.image ? " is-invalid" : null)}
                                    id="thumbnailInput"
                                    accept={fileContraints.IMAGE_MIME_TYPES?.join(',')}
                                    ref={fileInput}
                                    onChange={() => setThumbnailFileInputName(fileInput.current.files[0].name)}
                                />
                                <label className="custom-file-label" htmlFor="thumbnailInput" data-browse="Parcourir">
                                    {thumbnailFileInputName}
                                </label>
                            </div>
                        </div>
                        <Form.Control
						        type="hidden"
						        isInvalid={errors.image !== undefined}
					        />
                        <Form.Control.Feedback type='invalid'>{errors.image}</Form.Control.Feedback>
                    </Form.Group>
                    <hr />
                    <Row className='form-selection'>
                            <Col xs={3}>
                                <SelectMultipleItemsWithSearchBar idSelector="pedagogic_module[levels][]" label="Contexte(s)"
                                    allItems={allLevels} 
                                    selectedItemKeys={selectedLevels} setSelectedItemKeys={setSelectedLevels} 
                                    itemKey="value" itemValue="label" />
                                <Form.Control.Feedback type='invalid'>{errors.levels}</Form.Control.Feedback>
                            </Col>
                            <Col xs={3}>
                                <SelectMultipleItemsWithSearchBar idSelector="pedagogic_module[tools][]" label="Outil(s)"
                                    allItems={allTools} 
                                    selectedItemKeys={selectedTools} setSelectedItemKeys={setSelectedTools} 
                                    itemKey="value" itemValue="label" />
                                <Form.Control.Feedback type='invalid'>{errors.tools}</Form.Control.Feedback>
                            </Col>
                            <Col xs={3}>
                                <SelectMultipleItemsWithSearchBar idSelector="pedagogic_module[categories][]" label="Catégorie(s)"
                                    allItems={allCategories} 
                                    selectedItemKeys={selectedCategories} setSelectedItemKeys={setSelectedCategories} 
                                    itemKey="value" itemValue="label" />
                                <Form.Control.Feedback type='invalid'>{errors.categories}</Form.Control.Feedback>
                            </Col>
                            <Col xs={3}>
                                <SelectMultipleItemsWithSearchBar idSelector="pedagogic_module[stages][]" label="Étape(s)"
                                    allItems={allStages} 
                                    selectedItemKeys={selectedStages} setSelectedItemKeys={setSelectedStages} 
                                    itemKey="value" itemValue="label" />
                                <Form.Control.Feedback type='invalid'>{errors.stages}</Form.Control.Feedback>
                            </Col>
                    </Row>
                    <hr />

                    <Form.Group>
                        <Form.Label className="form-title">Objectifs</Form.Label>
                        <Editor
                            name="pedagogic_module[goal]"
                            apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                            init={{
                                height: 250,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                                'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                                ],
                                toolbar:
                                'undo redo | fontsize blocks | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | charmap emoticons | link | removeformat | help'
                            }}
                            onEditorChange={handleEditorChangeGoal}
                            initialValue={pedagogicModuleToUpdate?.goal ? pedagogicModuleToUpdate.goal : ""  }
                        />
                    </Form.Group>
                    <hr />

                    <Form.Group>
                        <Form.Label className="form-title">Description</Form.Label>
                        <Editor
                            name="pedagogic_module[description]"
                            apiKey="t5rspxvw6u2zr48fduj1kf3twvxk7dsncf5bk8h50v07s8lg"
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
								'searchreplace', 'visualblocks', 'code', 'fullscreen',
								'insertdatetime', 'media', 'table', "code", 'help', 'wordcount', 'emoticons'
                                ],
                                toolbar:
                                'undo redo | fontsize blocks | bold italic backcolor | \
								alignleft aligncenter alignright alignjustify | \
								bullist numlist outdent indent | charmap emoticons | link | removeformat | help | media'
                            }}
                            onEditorChange={handleEditorChangeDescription}
                            initialValue={pedagogicModuleToUpdate?.description ? pedagogicModuleToUpdate.description : ""  }
                        />
                    </Form.Group>
                    <hr />
                    <Row className='form-selection'>
                            <Col xs={12}><p className='form-title'>Sélection des tags</p></Col>
                        {allTags
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((tag) => {
                            let checked = false;

                            if (pedagogicModuleToUpdate && pedagogicModuleToUpdate.tags) {
                                if (pedagogicModuleToUpdate.tags.find(moduleTag => moduleTag.id === tag.id)) {
                                    checked = true;
                                }
                            }

                            return <Col className='tagCard' sm={6} md={4} lg={3} key={tag.uniqueId}>
                                <Form.Check
                                    type="checkbox"
                                    label={tag.name}
                                    id={'pedagogic_module_tags_' + tag.id}
                                    name="pedagogic_module[tags][]"
                                    value={tag.id}
                                    defaultChecked={checked}
                                    onChange={(e) => setTags(e.target.value)}
                                />
                            </Col>
                        })}
                        <Form.Control.Feedback type='invalid'>{errors.tags}</Form.Control.Feedback>
                    </Row>
                    <hr />
                
                    <SelectMediaModuleComponent allMedias={allQuizzes} mediaResultRef={quizResult} draggable={false} mediaType={"quiz"}/>
                    <hr />
                    <SelectMediaModuleComponent allMedias={allVideos} mediaResultRef={videoResult} draggable={false} mediaType={"video"}/>

                    <hr />
                   
                    <Row className="mb-3">
                        <Col sm={12} md={12}>
                            
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupFileAddon01">
                                        Fichier ({fileContraints.TEXT_FILE_MIME_TYPES_NAMES?.join(',')}) ({fileContraints.MAX_SIZE_TEXT_FILE_MO} Mo max.)
                                    </span>
                                </div>
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        name="pedagogic_module[pdfFile]"
                                        className={"custom-file-input" + (errors.pdfFile ? " is-invalid" : null)}
                                        id="inputGroupFile01"
                                        aria-describedby="inputGroupFileAddon01"
                                        accept={fileContraints.TEXT_FILE_MIME_TYPES?.join(',')}
                                        ref={pdfFileInput}
                                        onChange={() => setPdfFileInputName(pdfFileInput.current.files[0].name)} />

                                    <label className="custom-file-label" htmlFor="inputGroupFile01" data-browse="Parcourir">
                                        {pdfFileInputName}
                                    </label>
                                </div>
                            </div>
                            <Form.Control
						        type="hidden"
						        isInvalid={errors.pdfFile !== undefined}
					        />
                        <Form.Control.Feedback type='invalid'>{errors.pdfFile}</Form.Control.Feedback>
                
                        </Col>

                    </Row>
                    <Form.Group >
						<Form.Control
							name="pedagogic_module[_token]"
							defaultValue={token}
							type="hidden"
						/>
					</Form.Group>

                    <ButtonLoaderComponent
                    isSending={isSending}
                    disabled={isSending}
                    active={!isSending}
                    variant="secondary"
                    onClick={checkData} 
                    msg={pedagogicModuleToUpdate && pedagogicModuleToUpdate.uniqueId ? <><i className="fas fa-save"/> &nbsp;Éditer</>:<><i className="fas fa-save"/> &nbsp;Sauvegarder</>}
				/>
                    </Form>
			</div>
		</div>
      
    );

};

export default UploadPedagogicModuleComponent;
