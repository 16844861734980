import React, { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';

import { useParams } from "react-router-dom";

import { Row, Col, Container } from "react-bootstrap";

import Loader from "../../components/widgets/Loader";
import useCustomGetStoreState from "../../hooks/useCustomGetStoreState";
import useBackButton from "../../hooks/useBackButton";
import RomeDetailViewComponent from "../../components/romes/romeDetailView/RomeDetailViewComponent.jsx";
import RomeDetailsContentsComponent from "../../components/romes/romeDetail/RomeDetailsContentsComponent.jsx";

import './RomeDetailPage.scss';
import HelperMetiers360 from "../../services/HelpersMetiers360.js";

const RomeDetailPage = () => {
    const { uniqueId } = useParams();

    const { romeById } = useStoreState(state => state.romes);
    const { fetchRomeById } = useStoreActions(actions => actions.romes);

    const { allStories, isFetchingStories} = useCustomGetStoreState("stories");
    const {allVideos, isFetchingAllVideos} = useCustomGetStoreState("videos");

    const [isLoading, setIsLoading] = useState(false);

    const [romeStories, setRomeStories] = useState([]);
    const [romeVideos, setRomeVideos] = useState([]);

    useEffect(() => {
        if (!isLoading) {
            setIsLoading(true);
            fetchRomeById(uniqueId)
                .finally(() => setIsLoading(false));
        }
    }, []);

    useEffect(() => {
        if (romeById) {
            setRomeStories(allStories
                .filter((story => romeById.stories.includes(story.uniqueId)))
                .sort((a, b) => romeById.stories.indexOf(b.uniqueId) - romeById.stories.indexOf(a.uniqueId)));
            }
    }, [allStories, romeById])

    useEffect(() => {
        if (romeById) {
            setRomeVideos(allVideos
                .filter((video => romeById.videos.includes(video.uniqueId)))
                .sort((a, b) => romeById.videos.indexOf(b.uniqueId) - romeById.videos.indexOf(a.uniqueId)));
        }
    }, [allVideos, romeById])

    const {backButtonComponent} = useBackButton({mediaType:"romes"});
    const [isAppellationsOpen, setIsAppellationsOpen] = useState(false);

    const appellations = romeById?.appellations?.length !==0
        && <ul className="appellations">{romeById?.appellations
            ?.map(appellation => <li key={HelperMetiers360.generateUniqueId()} className="appellation mt-2">
                {appellation.label}
            </li>)}
        </ul>

    return (
        <Container fluid>
            <Row className="item-page">
                <h2>
                    <span className="d-flex justify-content-between">
                        {romeById && romeById.label} {romeById && " - " + romeById.code}
                        {backButtonComponent}
                    </span>
                    <button className="link-pull-down-button" onClick={() => setIsAppellationsOpen(prev => !prev)}>
                        Voir tous les métiers
                        { isAppellationsOpen 
                            ? <i className="fa fa-caret-up ms-2"></i>
                            : <i className="fa fa-caret-down ms-2"></i>
                        }
                    </button>
                    {isAppellationsOpen && <div>
                        {appellations}
                    </div>}
                </h2>
            </Row>
            <Row>
                <Col className="col-7">
                    { (!romeById || isLoading)
                        ? <Loader />
                        : <RomeDetailViewComponent rome={romeById} />
                    }
                </Col>
                <Col className="col-5">
                    { (!romeById || isLoading)
                        ? <Loader />
                        : <RomeDetailsContentsComponent
                            romeStories={romeStories}
                            romeVideos={romeVideos}
                            isFetchingStories={isFetchingStories}
                            isFetchingAllVideos={isFetchingAllVideos}
                        />
                    }
                </Col>
            </Row>
        </Container>

    )

}

export default RomeDetailPage;