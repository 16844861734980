import React, { useEffect} from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useParams } from "react-router-dom";
import Loader from "../../components/widgets/Loader";
import FormationDetailsComponent from "../../components/formations/formationDetails/FormationDetailsComponent";
import { Row, Col, Container} from 'react-bootstrap';
import useBackButton from "../../hooks/useBackButton";


import "./FormationDetailPage.scss";

const FormationDetailPage = (props) => {
    const {uniqueId} = useParams();

    const {formationById , isFetchingOneFormation} = useStoreState(state => state.formations);
    const {fetchFormationById, deleteFormation} = useStoreActions(actions => actions.formations);
    const {allSessionsByFormationId} = useStoreState(state => state.formationSessions);
    const {fetchFormationSessionsByFormationId} = useStoreActions(state => state.formationSessions);

    useEffect(() => {
        if (!isFetchingOneFormation) {
            fetchFormationById(uniqueId)
                .then(() =>
                    fetchFormationSessionsByFormationId(uniqueId)
                )
    }
    }, []);



    const {backButtonComponent} = useBackButton(({mediaType: "formations"}))

    return (
        <Container fluid>
            <Row>
                <Col className="item_detail_header">
                {backButtonComponent}
                </Col>
            </Row>
        <Row  className="item_detail_page">
           { 
               (isFetchingOneFormation || !formationById  )? <Loader />
                    : <FormationDetailsComponent 
                        formation={formationById}
                        formationSessions={allSessionsByFormationId}
                        deleteFormation={deleteFormation}
                    /> 
            
            }
        </Row>
    </Container>
    )
}

export default FormationDetailPage;