import React, { useEffect, useState } from "react";
import { Form, Container,  Row } from 'react-bootstrap';

import useInfiniteLoadingLogic from "../../../hooks/useInfiniteLogic.jsx";
import InfiniteBoardComponent from "../../../components/widgets/infiniteBoardComponent/InfiniteBoardComponent"
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";

/**
 * 
 * @param {Array} videos array of all videos to be rendered
 * @param {Array} sortTypePreferenceTuple Optionnal -  state and action from store to handle sort type preference
 *  [
 *      videoBoardSortType: value from store,
 *      setVideoBoardSortType: actionCreator
 *  ]
 * @param {String} readAll  readAll slug to access the video list page
 * @param {String} readOne  readOne slug to access a video details page - replace the :uniqueId part of string with actual uniqueId
 * @param {String} create  create slug to access the video creation page - optional, need according rights
 * @param {String} update  update slug to access the video update page - replace the :uniqueId part of string with actual uniqueId  - optionnal, need according rights
 * @example
 *  <VideoBoardComponent videos={videos} sortTypePreferenceType={[string, actionCreator]} readAll={readAllSlug} create={createSlug} {...}/>
 */

 const VideoBoardComponent = (props) => {
    const { videos, readOne: readOneSlug, sortTypePreferenceTuple } = props;

    const [videoBoardSortType, setVideoBoardSortType ] = sortTypePreferenceTuple ? sortTypePreferenceTuple : [null, null];
    const [videosToDisplay, setVideosToDisplay] = useState([]);
    const [sortedVideos, setSortedVideos] = useState([]);

    const {hasMore, fetchNext: fetchData} = useInfiniteLoadingLogic(sortedVideos, videosToDisplay, setVideosToDisplay, 40);

    const sortType = [];
    videos[0]?.releaseDate !== undefined && sortType
        .push({ value: 'date', label: "Publication (plus récente d'abord)", method: (a, b) => new Date(b.releaseDate) - new Date(a.releaseDate) });
        videos[0]?.viewCount !== undefined && sortType
        .push({ value: 'view', label: 'Vues', method: (a, b) => b.viewCount - a.viewCount });
        videos[0]?.videoName !== undefined && sortType
        .push({ value: 'name', label: 'Nom', method: (a, b) => a.videoName.localeCompare(b.videoName) });

    const [sort, setSort] = useState(
        (sortType.find(type => type.value === videoBoardSortType)) ||
        (sortType[0] ?? { value: 'name', label: 'Nom', method: (a, b) => a.videoName.localeCompare(b.videoName)})
      );

      useEffect(() => {
        if (videos?.length && sort?.method) {
            setSortedVideos([...videos].sort(sort.method));
        }
    }, [sort, videos]);
    

    const sortChoice = (e) => {
        const sortSelected = sortType.filter(type => type.value === e.target.value)[0];
        setSort(sortSelected);
        // eslint-disable-next-line no-unused-expressions
        setVideoBoardSortType ? setVideoBoardSortType(sortSelected.value) : null;
    };

    const classNameSort = (index) => {
        switch(index) {
            case 0:
                return 'm360-col3-start col-4';
            case 1:
                return 'm360-col3-center col-4';
            case 2:
                return 'm360-col3-end col-4 pe-4';
            default:
                return undefined;
        }
    }

    const checkSort = sortType.map(
        (type, index) => <Form.Check inline name="sort" label={type.label} type='radio' 
            value={type.value} key={type.value} id={type.value} className={classNameSort(index)} 
            onChange={sortChoice} checked={sort.value === type.value} />
    );

    return (
        <Container fluid>
            <Row className="mb-4"><div className="d-flex justify-content-between">{checkSort}</div></Row>
            <Row>
                <InfiniteBoardComponent
                    listOfMedia={videosToDisplay}
                    mediaType={"video"}
                    readOne={readOneSlug}
                    isLink={true}
                    fetchData={fetchData}
                    hasMore={hasMore}
                    isAdmin = {AuthorizationChecker.isAdmin()}
                />
            </Row>
        </Container>
    );
}
export default VideoBoardComponent;