import React from "react";
import { Tabs, Tab, Row } from "react-bootstrap";
import PropTypes from 'prop-types';

import HelperMetiers360 from "../../../services/HelpersMetiers360.js";

import RomeSkillsTabComponent from "../romeDetail/romeDetailTabs/RomeSkillsTabComponent.jsx";
import RomeItemsTabComponent from "../romeDetail/romeDetailTabs/RomeItemsTabComponent.jsx";
import Loader from "../../../components/widgets/Loader";

import './RomeDetailViewComponent.scss';
import RomeConditionsTabComponent from "../romeDetail/romeDetailTabs/RomeConditionsTabComponent.jsx";
import AuthorizationChecker from "../../../services/AuthorizationChecker.js";
import { useStoreState } from "easy-peasy";
import { Link } from "react-router-dom";

const RomeDetailViewComponent = (props) => {
    const {rome} = props;

    const areaOfInterestsSlugs = useStoreState(state => state.actionSlugs.actionSlugsDispatcher("areasOfInterest"));
    const oneAreaOfInterestSlug = areaOfInterestsSlugs?.readOne ?? null;

    const centreInterets = rome 
        && rome?.centreInterets?.length !== 0 
        && rome?.centreInterets
            ?.map(interest => <li className="mt-2 ms-3" key={'aoi_'+interest.code}>
                {AuthorizationChecker.isAdmin() && oneAreaOfInterestSlug
                    ? <Link to={oneAreaOfInterestSlug.replace(':uniqueId', interest.code)}>{interest.label}</Link>
                    : <>{ interest.label }</>}
                </li>
            );

    return (
    <>
     { rome
        ? <>
        <Row className="mt-4">
            <h4 className="fw-bold">Caractéristiques du métier</h4>
            <RomeItemsTabComponent rome={rome} tabName="definition" />
        </Row>
        <Row className="mt-4">
            <h4 className="fw-bold">Comment y accéder ?</h4>
            <RomeItemsTabComponent rome={rome} tabName="access"/>
        </Row>
        <Row className="mt-4">
            <Tabs defaultActiveKey="areasOfInterest">
                <Tab eventKey="areasOfInterest" title="Centres d'intérêt" unmountOnExit={true}> 
                    <div className="mt-4">{centreInterets}</div>
                </Tab>
                <Tab eventKey="workingConditions" title="Contextes de travail" unmountOnExit={true}>
                    <RomeConditionsTabComponent rome = {rome} />
                </Tab>
                <Tab eventKey="skills" title="Compétences" unmountOnExit={true}>
                    <h4 className="mt-4">Compétences mobilisées principales</h4>
                    <RomeSkillsTabComponent rome={rome} skillsType='competencesMobiliseesPrincipales'/>
                    <h4 className="mt-5">Compétences mobilisées</h4>
                    <RomeSkillsTabComponent rome={rome} skillsType='competencesMobilisees'/>
                    <h4 className="mt-5">Compétences mobilisées émergentes</h4>
                    <RomeSkillsTabComponent rome={rome} skillsType='competencesMobiliseesEmergentes'/>
                </Tab>
            </Tabs>
        </Row>
        <div className="footer mt-5">
            Source : <a href="https://pole-emploi.io" target="_blank">Pôle emploi</a>
            {rome.updatedAt?.date &&<> (Mise à jour : {HelperMetiers360.getdisplayDateType(rome.updatedAt.date, 'day')})</>}
        </div>
        </>
        : <Loader />}
    </>)
  
        
}

RomeDetailViewComponent.propTypes = {
    rome: PropTypes.object.isRequired
};

export default RomeDetailViewComponent;