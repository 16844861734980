import { action, actionOn, thunk } from 'easy-peasy';
import { store } from './index.js';
import axios from '../services/axios';

const headsetsInitialState = {
    propsFetched: {},
    allHeadsets: [],
    headsetsByUniqueIds: [],
    isFetchingHeadsets: false,
    isFetchingHeadsetsByUniqueIds: false,
    isUpdateNecessary: false,
    isGradualFetchHeadsetsNecessary: true,
    isSomeUpdateNecessary: false,
    initialFetchDone: false,
    headsetById: null,
    uploadedAndAccessibleVideosByHeadset: null,
    eventsByHeadset: null,
    commentsByHeadset: null,
    isFetchingOneHeadset: false,
    isMergeAllHeadsetsPropsNecessary: true,
    isMergeClientsPropsInAllHeadsetsNecessary: true,
}

const headsets = {
    ...headsetsInitialState,

    onGlobalResetCalled: actionOn(
        (actions, storeActions) => [
            storeActions.resetStore.performReset,
        ],
        (state, target) => {
            const [performReset] = target.resolvedTargets;

            if (target.type === performReset) {
                ({  propsFetched: state.propsFetched,
                    allHeadsets: state.allHeadsets,
                    headsetsByUniqueIds: state.headsetsByUniqueIds,
                    isFetchingHeadsets: state.isFetchingHeadsets,
                    isFetchingHeadsetsByUniqueIds: state.isFetchingHeadsetsByUniqueIds,
                    isUpdateNecessary: state.isUpdateNecessary,
                    isSomeUpdateNecessary: state.isSomeUpdateNecessary,
                    initialFetchDone: state.initialFetchDone,
                    headsetById: state.headsetById,
                    uploadedAndAccessibleVideosByHeadset: state.uploadedAndAccessibleVideosByHeadset,
                    eventsByHeadset: state.eventsByHeadset,
                    commentsByHeadset: state.commentsByHeadset,
                    isFetchingOneHeadset: state.isFetchingOneHeadset,
                    isMergeAllHeadsetsPropsNecessary: state.isMergeAllHeadsetsPropsNecessary,
                    isGradualFetchHeadsetsNecessary: state.isGradualFetchHeadsetsNecessary,
                    isMergeClientsPropsInAllHeadsetsNecessary: state.isMergeClientsPropsInAllHeadsetsNecessary,
                } = headsetsInitialState)
            }
        }
    ),

    setInitialFetchDone: action((state, payload) => {
        state.initialFetchDone = payload;
    }),

    setIsFetchingHeadsets: action((state, payload) => {
        state.isFetchingHeadsets = payload;
    }),


    setIsUpdateNecessary: action((state, payload) => {
        state.isUpdateNecessary = payload;
    }),

    setIsSomeUpdateNecessary: action((state, payload) => {
        state.isSomeUpdateNecessary = payload;
    }),

    setAllHeadsets: action((state, payload) => {
        state.allHeadsets = payload;
    }),

    setIsMergeAllHeadsetsPropsNecessary: action((state, payload) => {
        state.isMergeAllHeadsetsPropsNecessary = payload;
    }),

    setIsMergeClientsPropsInAllHeadsetsNecessary: action((state, payload) => {
        state.isMergeClientsPropsInAllHeadsetsNecessary = payload;
    }),

    setPropsFetched: action((state, payload) => {
        state.propsFetched[payload] = true;
    }),
    initHeadsetsPropsFetched: action((state, payload) => {
        state.propsFetched = {};
    }),
    setIsGradualFetchHeadsetsNecessary: action((state, payload) => {
        state.isGradualFetchHeadsetsNecessary = payload;
    }),

    updateHeadsetsProps: thunk((actions, payload, helpers) => {
        const allHeadsets = helpers.getState().allHeadsets;
      
        const headsetMap = new Map(payload?.map(headset => [headset.uniqueId, headset]));
            const newAllHeadsets = allHeadsets.map(headset => {
            const additionalProps = headsetMap.get(headset.uniqueId);
            return { ...headset, ...additionalProps };
        });
    
        actions.setAllHeadsets(newAllHeadsets);
    }),
    

    updatePlaylistPropOfHeadset: thunk((actions, payload, helpers) => {
        const allHeadsets = helpers.getState().allHeadsets;
        const headsetsToUpdateSet = new Set(payload.headsetsToUpdate);
        const updatedHeadsets = allHeadsets.map(headset => {
            if (headsetsToUpdateSet.has(headset.uniqueId)) {
                return {...headset, playlistUniqueId: payload.playlistUniqueId};
            }
            return headset;
        });
    
        actions.setAllHeadsets(updatedHeadsets);
    }),
    

  
    fetchAllHeadsets: thunk((actions, payload, helpers) => {
        const { isFetchingHeadsets, isUpdateNecessary } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.headsets?.readAll?.url;

        if (!isFetchingHeadsets && isUpdateNecessary) {
            actions.setIsFetchingHeadsets(true);
            return axios.get(getUrl)
                .then(data => {
                    actions.setAllHeadsets(data);
                    actions.setIsUpdateNecessary(headsetsInitialState.isUpdateNecessary);
                    actions.setIsMergeClientsPropsInAllHeadsetsNecessary(true);
                    actions.setIsMergeAllHeadsetsPropsNecessary(true);
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => {
                    actions.setIsFetchingHeadsets(headsetsInitialState.isFetchingHeadsets);
                })
        } else {
            return Promise.resolve();
        }
    }),


    fetchHeadsetsProps: thunk((actions, payload) => {
        const prop = payload;

        if (prop?.url) {
            return axios.get(prop?.url)
                .then(data => {
                    actions.updateHeadsetsProps(data);
                    actions.setPropsFetched(prop?.name);
                    return data;
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
        }
    }),


    fetchAllPropsForAllHeadsets: thunk((actions, payload, helpers) => {
        return actions.fetchAllHeadsets()
            .then(() => {
                actions.gradualFetchHeadsetsData();
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }),


    gradualFetchHeadsetsData: thunk((actions, payload, helpers) => {
        const { apiData } = helpers.getStoreState().actionSlugs;
        const { propsFetched, isGradualFetchHeadsetsNecessary } = helpers.getState();

        if(isGradualFetchHeadsetsNecessary && apiData.ACTIONS.headsets) {
            const propsForAll = apiData.ACTIONS.headsets.getPropsForAll ;
            const gradualAccessRoutes = propsForAll 
                ? Object.keys(propsForAll).map(keyProp => {
                        return {
                            name: keyProp,
                            url: propsForAll[keyProp].url,
                        };
                    })
                : [];
            if(gradualAccessRoutes.length > 0) {
                let gradualFetchToBeDone = [];
                gradualAccessRoutes.forEach(prop => {
                    if(propsFetched[prop?.name] === undefined) {
                        gradualFetchToBeDone.push(actions.fetchHeadsetsProps(prop));
                    }
                });
                Promise.all(gradualFetchToBeDone).then(function(results) {
                    actions.setIsMergeAllHeadsetsPropsNecessary(false);
                    actions.setIsGradualFetchHeadsetsNecessary(false);
               })
            }
        }
    }),



    // ACTIONS TO HYDRATE STORE INSTEAD OF REFETCHING LIST OF HEADSETS
    updateAllHeadsets: action((state, payload) => {
        const index = state.allHeadsets.findIndex((headset) => headset.deviceId === payload.deviceId);
        if (index !== -1) {
            state.allHeadsets[index] = {
                ...state.allHeadsets[index],
                ...payload,
            };
        }
    }),

    addInAllHeadsets: action((state, payload) => {
        state.allHeadsets.push(payload);
    }),

    removeFromAllHeadsets: action((state, payload) => {
        const indexToRemove = state.allHeadsets.findIndex(headset => headset.uniqueId === payload);
        state.allHeadsets.splice(indexToRemove, 1);
    }),

    // handlingOneHeadset
    setHeadsetById: action((state, payload) => {
        state.headsetById = payload;
    }),

    setUploadedAndAccessibleVideosByHeadset: action((state, payload) => {
        state.uploadedAndAccessibleVideosByHeadset = payload;
    }),

    setEventsByHeadset: action((state, payload) => {
        state.eventsByHeadset = payload;
    }),
    setCommentsByHeadset: action((state, payload) => {
        state.commentsByHeadset = payload;
    }),
    addCommentByHeadset: action((state, payload) => {
        const newCommentsByHeadset = [...state.commentsByHeadset];
        newCommentsByHeadset.unshift(payload);
        state.commentsByHeadset = newCommentsByHeadset;
    }),
    removeCommentByHeadset: action((state, payload) => {
        const newCommentsByHeadset = [...state.commentsByHeadset];
        const idxToRemove = newCommentsByHeadset.findIndex(c => c.uniqueId === payload);
        newCommentsByHeadset.splice(idxToRemove, 1);
        state.commentsByHeadset = newCommentsByHeadset;
    }),
    setIsFetchingOneHeadset: action((state, payload) => {
        state.isFetchingOneHeadset = payload;
    }),

    fetchHeadsetById: thunk((actions, payload, helpers) => {
        const { isFetchingOneHeadset } = helpers.getState();
        const { apiData: { ACTIONS, URLs } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.headsets?.readOne?.url?.replace('uniqueId', payload);
        const getUploadedVideosUrl = ACTIONS?.headsets?.getProps?.getUploadedVideos?.url?.replace('uniqueId', payload);
        const getAccessibleVideosUrl = URLs?.getVideosInHeadset?.url;
        const getEventsUrl = ACTIONS?.headsets?.getProps?.getEvents?.url?.replace('uniqueId', payload);
        const getCommentsUrl = ACTIONS?.headsets?.getProps?.getComments?.url?.replace('uniqueId', payload);

        if (!isFetchingOneHeadset) {
            actions.setIsFetchingOneHeadset(true);
            actions.setHeadsetById(headsetsInitialState.headsetById);
            actions.setEventsByHeadset(headsetsInitialState.eventsByHeadset);
            actions.setCommentsByHeadset(headsetsInitialState.commentsByHeadset);
            actions.setUploadedAndAccessibleVideosByHeadset(headsetsInitialState.uploadedAndAccessibleVideosByHeadset);

            return new Promise((resolve, reject) => {
                axios.get(getUrl)
                    .then(headsetResponse => {
                        actions.updateAllHeadsets(headsetResponse);
                        actions.setHeadsetById(headsetResponse);

                        const formData = new FormData();
                        formData.set('device', headsetResponse.deviceId);
                        if(!headsetResponse?.subscriptionUniqueId){
                            return Promise.resolve(null);
                        }
                        return Promise.all([
                            axios.get(getUploadedVideosUrl),
                            axios.post(getAccessibleVideosUrl, formData)
                        ])
                    })
                    .then((result) => {
                        if(result){
                            const [uploadedVideos, accessibleVideos] = result;

                            // Merge uploaded and accessible videos
                            let videosToDisplay = accessibleVideos?.videos
                                ? accessibleVideos?.videos.map(acccessibleVideo => {
                                        return {
                                            uniqueId: acccessibleVideo.uniqueId,
                                            thumbnail: acccessibleVideo.links.thumbnail,
                                            name: acccessibleVideo.name,
                                            accessible: true,
                                            uploaded: false,
                                            videoSize: null,
                                            videoSizeInHeadset: null
                                        }
                                    })
                                : [];

                                uploadedVideos?.forEach((uploadedVideo) => {
                                const index = videosToDisplay.findIndex(video => video.uniqueId === uploadedVideo.uniqueId);
                                if(index > -1) {
                                    videosToDisplay[index].uploaded = true;
                                    videosToDisplay[index].videoSize = uploadedVideo.videoSize;
                                    videosToDisplay[index].videoSizeInHeadset = uploadedVideo.videoSizeInHeadset;
                                } else {
                                    videosToDisplay.push({
                                        uniqueId: uploadedVideo.uniqueId,
                                        thumbnail: uploadedVideo.links.thumbnail,
                                        name: uploadedVideo.videoName,
                                        accessible: false,
                                        uploaded: true,
                                        videoSize: uploadedVideo.videoSize,
                                        videoSizeInHeadset: uploadedVideo.videoSizeInHeadset
                                    })
                                }
                            });
                            actions.setUploadedAndAccessibleVideosByHeadset(videosToDisplay);
                        }
                        else actions.setUploadedAndAccessibleVideosByHeadset([]);

                        const eventsPromise = axios.get(getEventsUrl);
                        const commentsPromise = getCommentsUrl ? axios.get(getCommentsUrl) : Promise.resolve(undefined);
    
                        return Promise.all([eventsPromise, commentsPromise]);
                    })
                    .then(([eventsResponse, commentsResponse]) => {
                        actions.setEventsByHeadset(eventsResponse);
                        if (commentsResponse) {
                            actions.setCommentsByHeadset(commentsResponse);
                        }
                        resolve();
                    })
                    .catch(error => Promise.reject(error))
                    .finally(() => actions.setIsFetchingOneHeadset(false));
            });

        } else {
            return Promise.resolve();
        }
    }),

    
    setHeadsetsByUniqueIds: action((state, payload) => {
        state.headsetsByUniqueIds = payload;
    }),

    setIsFetchingHeadsetsByUniqueIds: action((state, payload) => {
        state.isFetchingHeadsetsByUniqueIds = payload;
    }),

    fetchHeadsetsByUniqueIds: thunk((actions, payload, helpers) => {
        const { isFetchingHeadsetsByUniqueIds } = helpers.getState();
        const { apiData: { ACTIONS } } = helpers.getStoreState().actionSlugs;

        const getUrl = ACTIONS?.headsets?.readMultiple?.url;

        if (!isFetchingHeadsetsByUniqueIds) {
            actions.setIsFetchingHeadsetsByUniqueIds(true);
            return axios.post(getUrl, payload)
                .then(data => {
                    actions.setHeadsetsByUniqueIds(data)
                    return data;
                })
                .catch(error => Promise.reject(error))
                .finally(() => actions.setIsFetchingHeadsetsByUniqueIds(false))
        } else {
            return Promise.resolve();
        }
    }),

    // Merge props from allClients to allHeadsets only if not already merged (tagOperations, parent, category, clientId)
    mergeClientsPropsInAllHeadsets: thunk((actions, payload, helpers) => {
        const { allClients } = helpers.getStoreState().clients;
        const { allHeadsets } = helpers.getStoreState().headsets;
        const { isMergeClientsPropsInAllHeadsetsNecessary } = helpers.getState();

        const { hasAccessToClientsProps } = payload;
    
        if (hasAccessToClientsProps && allClients?.length > 0 && allHeadsets?.length > 0 && isMergeClientsPropsInAllHeadsetsNecessary) {

            const clientMap = new Map(allClients.map(client => [client.uniqueId, client]));
    
            const newAllHeadsets = allHeadsets.map(headset => {
                const clientSelected = clientMap.get(headset.clientUniqueId);
                return {
                    ...headset,
                    tagOperations: clientSelected?.tagOperations ?? null,
                    categories: clientSelected?.categories ?? null,
                    parentUniqueId: clientSelected?.parentUniqueId ?? null,
                };
            });
    
            actions.setAllHeadsets(newAllHeadsets);
            actions.setIsMergeClientsPropsInAllHeadsetsNecessary(false);
        }
    }),
    

    // create one headset
    createHeadset: thunk((actions, payload, helpers) => {
        const formData = payload;
        const { updateSubscriptionByClientId,  updateClientsProps , setClientById} = helpers.getStoreActions().clients;
        const { addSubscriptionsHeadset} = helpers.getStoreActions().subscriptions;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const createHeadsettUrl = apiData.ACTIONS?.headsets?.create?.url;

        return axios.post(createHeadsettUrl, formData)
            .then((result) => {
                if (result.action == "update") {
                    actions.updateAllHeadsets(result.headsetGeneralInformations);
                    store.getActions().subscriptions.updateSubscriptionsHeadset(result.headset);
                }
                else {
                    addSubscriptionsHeadset(result.headset);
                    updateSubscriptionByClientId(result.subscriptionToUpdate);
                    updateClientsProps(result.clientToUpdate);
                    setClientById(result.clientFullToUpdate);
                    if (result.action == "create") {
                        actions.addInAllHeadsets(result.headsetGeneralInformations);
                    }
                    else {
                        actions.updateAllHeadsets(result.headsetGeneralInformations);
                        store.getActions().clients.updateSubscriptionByClientId(result.oldSubscriptionToUpdate);
                    }
                    const { headsetsByClientId } = helpers.getStoreState().clients;
                    const { addHeadsetsByClientId } = helpers.getStoreActions().clients;
                    if (headsetsByClientId) {
                        addHeadsetsByClientId(result?.headset);
                    }
                }
                return result;
            })
            .catch(error => Promise.reject(error))
    }),

    // update one headset
    updateHeadset: thunk((actions, payload, helpers) => {
        const { setIsUpdateNecessary: setShouldUpdatePlaylist } = helpers.getStoreActions().playlists;
        const { formData, uniqueId } = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const updateHeadsettUrl = apiData.ACTIONS?.headsets?.update?.url?.replace('uniqueId', uniqueId);
        const csrfToken = apiData.ACTIONS?.headsets?.update?.csrfToken;
        formData.set('headset_token', csrfToken);

        return axios.post(updateHeadsettUrl, formData)
            .then((result) => {
                actions.updateAllHeadsets(result?.headset);
                store.getActions().subscriptions.removeHeadsetFromSubscription(result?.headset);
                if (result.oldSubscriptionToUpdate) {
                    store.getActions().clients.updateSubscriptionByClientId(result?.oldSubscriptionToUpdate);
                }
                if (result.newSubscriptionToUpdate) {
                    store.getActions().clients.updateSubscriptionByClientId(result?.newSubscriptionToUpdate);
                }
                setShouldUpdatePlaylist(true);
                const { headsetById } = helpers.getStoreState().headsets;
                if (headsetById.uniqueId === uniqueId) {
                    actions.setHeadsetById(result?.headset);
                }
                const { headsetsByClientId } = helpers.getStoreState().clients;
                const { updateHeadsetsByClientId } = helpers.getStoreActions().clients;
                if (headsetsByClientId.some(headset => headset.uniqueId === uniqueId)) {
                    updateHeadsetsByClientId(result?.headset);
                }
                actions.setIsSomeUpdateNecessary(true);
                return result?.headset;
            })
            .catch(error => Promise.reject(error))
    }),


    setOutOfOrderStatus: thunk((actions, payload, helpers) => {
        const requestData = payload ?? {};
        const apiData = helpers.getStoreState().actionSlugs.apiData;
        const setOutOfOrderHeadsettUrl = apiData.ACTIONS?.headsets?.outOfOrder?.url?.replace('uniqueId', requestData?.uniqueId);
        const csrfToken = apiData.ACTIONS?.headsets?.outOfOrder?.csrfToken;
        requestData.csrfToken = csrfToken;

        return axios.post(setOutOfOrderHeadsettUrl, requestData)
            .then((result) => {
                actions.updateAllHeadsets(result?.headset);
                const { headsetById } = helpers.getStoreState().headsets;
                if (headsetById.uniqueId === requestData.uniqueId) {
                    actions.setHeadsetById(result?.headset);
                }
                const { headsetsByClientId } = helpers.getStoreState().clients;
                const { updateHeadsetsByClientId } = helpers.getStoreActions().clients;
                if (headsetsByClientId.some(headset => headset.uniqueId === requestData.uniqueId)) {
                    updateHeadsetsByClientId(result?.headset);
                }
                return result?.headset;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    createHeadsetComment: thunk((actions, payload, helpers) => {
        const {headsetId, commentContent} = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const createCommentUrl = apiData.ACTIONS?.headsetComments?.create?.url.replace('headsetUniqueId', headsetId);
        const csrfToken = apiData.ACTIONS?.headsetComments?.create?.csrfToken;
        return axios.post(createCommentUrl, { token: csrfToken, content: commentContent })
            .then(response => {
                actions.addCommentByHeadset(response);
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }),

    deleteHeadsetComment: thunk((actions, payload, helpers) => {
        const {commentId} = payload;
        const apiData = helpers.getStoreState().actionSlugs.apiData;

        const deleteCommentUrl = apiData.ACTIONS?.headsetComments?.delete?.url.replace('uniqueId', commentId);
        const csrfToken = apiData.ACTIONS?.headsetComments?.delete?.csrfToken;

        return axios.post(deleteCommentUrl, { token: csrfToken })
        .then(response => {
            actions.removeCommentByHeadset(commentId);
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        })
    }),
    
};

export default headsets;