import React, { useEffect, useState } from 'react';
import {useStoreState} from 'easy-peasy';
import MemberCardComponent from '../../components/contacts/MemberCardComponent';
import axios from '../../services/axios.js';
import './ContactPage.scss';
import { Row, Col, Card } from 'react-bootstrap';
import HelperMetiers360 from '../../services/HelpersMetiers360.js';
const ContactsPage = () => {

     const [contacts, setContacts] = useState([])
    
    //destructuration of actionSlugs - Looking for URLs in Apidata from actionSlugs
   const {apiData: {URLs}} = useStoreState(state => state.actionSlugs);

   useEffect(()=>{
            //Get url's getAllContacts from URLs and stock it in getContactURL

            const getContactURL = URLs.getAllContacts.url;

            //Axios Request, Once we got getContactURL content and put in response 
            axios.get(getContactURL).then(response =>{

                //we set contact useState with it

                setContacts(response);

                //if we get an error
            
            });
    }, []);

    return (
        <div className='contacts'>

                {contacts.map((contact) => (
                    <div className='team-board' key={'board_'.concat(HelperMetiers360.generateUniqueId())}>  
                        <h3 className="team-title">
                            {contact.teamName} 
                        </h3>                      
                            <Row xs={1} sm={2} md={3} lg={3} xl={3} xxl={4} className="g-4">
                                {
                                    contact.members.map((member) => {
                                        return (
                                            <MemberCardComponent key={'member_'.concat(HelperMetiers360.generateUniqueId())} memberProp={member} />     
                                        );
                                    })
                                }                        
                            </Row>   
                    </div>
                ))}
        </div>  
    );
};

export default ContactsPage;